import IsNullOrWhiteSpace from "./IsNullOrWhiteSpace";



function ContactInfoTollFree({tollFree}){

    const li = <><i className="fa-li fa fa-phone" aria-hidden="true"></i><a className="phone" href={"tel:" + tollFree}>{tollFree}</a><br /></>;

    return <IsNullOrWhiteSpace arg={tollFree} notNullComponent={li} />

}

export default ContactInfoTollFree;