import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const cCC_Content = [
    {
        format: bodyHelperTypes.paragraph,
        order: 10,
        content: "Click on the name of an entity below for more information."
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Commissions"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: <Links linkTypeID="255" />
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Councils"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: <Links linkTypeID="256" />
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Committees"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 70,
        content: <Links linkTypeID="257" />
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Other"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 90,
        content: <Links linkTypeID="258" />
    },
    






];

export default cCC_Content;