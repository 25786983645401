

function ContactInfoAddress({street, city, state, zip}){
    const mapHref = `http://maps.google.com/?q=${street}${city}${state}${zip}`;
    return (
        <li>
            <i className="fa-li fa fa-address-book" aria-hidden="true"></i>
            <a href={mapHref} className="phone" >
                {street} <br />
                {city}, {state} {zip} <br />
            </a>
        </li>
    );
    
}

export default ContactInfoAddress;