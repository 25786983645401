import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLFormsCM_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL Case Management Forms"
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: [
            <>
                <br />
                SCL Application
            </>
        ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
                Applications are to be submitted in the Medicaid Waiver Management Application.
                <br />
                <br />

                <Links linkTypeID="316" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Level of Care (LOC) Determination Forms to Upload in MWMA"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <><Links linkTypeID="313" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Prior Authorization of Person Centered Service Plan (PSCP) Forms to Upload in MWMA"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <><Links linkTypeID="314" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Additional Forms and Tips To Use When Applicable"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            <><Links linkTypeID="315" /></>,
            "All Medicaid (MAP) forms will continue to be found on the Department for Medicaid Services (DMS) SCL Web page under Forms in the right-hand column."
        ]
    },
];

export default sCLFormsCM_Content;