import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";




const kYCCL_Content = [

    {
        format: bodyHelperTypes.paragraph,
        order: 10,
        content: "Civil commitment laws (also called involuntary commitment laws) exist in every state in the U.S. These laws govern the detainment and treatment of individuals with mental illness, substance use disorder, or intellectual disability who pose a risk to themselves or others. Below are links to Kentucky’s laws and verified petitions that family members or other concerned individuals may complete to initiate the commitment process for individuals who are in crisis, pose a risk to themselves or others, and are unlikely to/unable to voluntarily initiate services."
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [      
            <><a href="http://apps.legislature.ky.gov/law/statutes/chapter.aspx?id=38119"><b>KRS Chapter 202A: Adult Mental Health Hospitalization</b></a><br/>
            <a style={{color:"green"}} href="https://www.kycourts.gov/Legal-Forms/Legal Forms/710.pdf"><b>Verified Petition</b></a></>,
            <><a href="http://apps.legislature.ky.gov/law/statutes/chapter.aspx?id=39435"><b>KRS Chapter 645: Children's Mental Health Hospitalization</b></a><br/>
            <a style={{color:"green"}} href="https://www.kycourts.gov/Legal-Forms/Legal Forms/710.pdf"><b>Verified Petition</b></a></>,
            <><a href="https://apps.legislature.ky.gov/law/statutes/chapter.aspx?id=38279"><b>KRS Chapter 222: Treatment for Substance Use Disorder</b></a><br/>
            <a style={{color:"green"}} href="https://www.kycourts.gov/Legal-Forms/Legal Forms/700A.pdf"><b>Verified Petition</b></a></>,
            <><a href="https://apps.legislature.ky.gov/law/statutes/chapter.aspx?id=38119"><b>KRS Chapter 202A.0811: Assisted Outpatient Treatment</b></a><br/>
            <a style={{color:"green"}} href="https://www.kycourts.gov/Legal-Forms/Legal Forms/737.pdf"><b>Verified Petition</b></a></>,
            <><a href="https://apps.legislature.ky.gov/law/statutes/chapter.aspx?id=38121"><b>KRS Chapter 202B: Admission of an Individual with an Intellectual Disability</b></a><br/>
            <a style={{color:"green"}} href="https://www.kycourts.gov/Legal-Forms/Legal Forms/710.pdf"><b>Verified Petition</b></a><br/><br/></>,

        ]
    }








];

export default kYCCL_Content;