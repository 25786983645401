import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const aSAMPPC_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "The ASAM Criteria"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The ASAM Criteria is the most widely used and comprehensive set of standards for placement, continued service, and transfer of patients with addiction and co-occurring conditions. Formerly known as the ASAM patient placement criteria, The ASAM Criteria is the result of a collaboration that began in the 1980s to define one national set of criteria for providing outcome-oriented and results-based care in the treatment of addiction. Many states across the country are using The ASAM Criteria as the foundation of their efforts to improve the addiction treatment system.",
            "Level of care recommendations and treatment plans are developed based on multidimensional patient assessments that consider the patient’s biomedical, psychological, and social needs.",
            "As of July 1, 2012, all Community Mental Health Centers were required to use the American Society of Addiction Medicine (ASAM) Patient Placement Criteria (PPC).",
            "Additionally, the Managed Care Organizations (MCOs) require its use to authorize treatment initiation, continuation and discharge.",
            "For more information about The ASAM Criteria including the evidence-based, Levels of Care, and who uses The ASAM Criteria visit About the ASAM.",
            "The 4th Edition of The ASAM Criteria is available in print and digital versions to enable more effective implementation of The ASAM Criteria® in clinical practice. "
        ]
    },
];

export default aSAMPPC_Content;