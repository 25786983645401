//Using the react library
import React from "react";
import Form from 'react-bootstrap/Form';
//Using the react library:
import { useEffect, useState } from "react";
import useApi from "../utils/UseAPI";
import Spinner from 'react-bootstrap/Spinner';
import reportDataNames from '../data/data.json'

function FacilitiesDataReports({ typeId, ...props }) {

    const { error, processing, getRequest } = useApi();
    const [allFacilities, setAllFacilities] = useState([]);
    const [mHSPCH, setMHSPCH] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "facility/report-data");
            if (status == 200) {
                // console.log(data.data);
                FormFilter(data.data);
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);

    function FormFilter(reportData) {

        const allFacilitiesFilter = [];
        const mentalHealthSpecializedPersonalCareHomesFilter = [];

        reportData.forEach((reportDoc) => {
            if (reportDoc.reportTypeId == 1) {
                allFacilitiesFilter.push(reportDoc);
            }
            else if (reportDoc.reportTypeId == 2) {
                mentalHealthSpecializedPersonalCareHomesFilter.push(reportDoc);
                console.log(reportDoc)
            }
        })
        setAllFacilities(allFacilitiesFilter);
        setMHSPCH(mentalHealthSpecializedPersonalCareHomesFilter);
    }
    const [selectedListOne, setSelectedListOne] = useState(
        <>
            <option>Select a report</option>
            {processing ? <Spinner animation="border" variant="success" /> : allFacilities.map((data, index) => {
                return (
                    <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+ data.extension.replace(".","") +"&t=" + data.reportTypeId} key={index}>{reportDataNames.facilitiesDataReportAllFacilities[index].name}</option >
                )
            })}
        </>);
    const [selectedListTwo, setSelectedListTwo] = useState(<>

        <option>Select a report</option>

        {processing ? <Spinner animation="border" variant="success" /> : mHSPCH.map((data, index) => {
            return (
                <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=" + data.reportTypeId} key={index}>{ reportDataNames.facilitiesDataReportMHSPCH[index].name}</option >
            )
        })}
    </>);

    const handleRadioChange = (value) => {
       
        if (value.target.value == "DBF") {
            setSelectedListOne(
                <>
                    <option>Select a report</option>
                    {processing ? <Spinner animation="border" variant="success" /> : allFacilities.map((data, index) => {
                        return (
                            <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameFFormatted + "&x="+data.extension.replace(".","")+"&t=" + data.reportTypeId} key={index}>{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option >
                        )
                    })}
                </>
            )
            setSelectedListTwo(
                <>
                    <option>Select a report</option>
                    {processing ? <Spinner animation="border" variant="success" /> : mHSPCH.map((data, index) => {
                        return (
                            <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameFFormatted + "&x="+data.extension.replace(".","")+"&t=" + data.reportTypeId} key={index}>{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option >
                        )
                    })}
                </>
            )
        }
        else {
            setSelectedListOne(
                <>
                    <option>Select a report</option>
                    {processing ? <Spinner animation="border" variant="success" /> : allFacilities.map((data, index) => {
                        return (
                            <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=" + data.reportTypeId} key={index}>{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option >
                        )
                    })}
                </>
            )
            setSelectedListTwo(
                <>
                    <option>Select a report</option>
                    {processing ? <Spinner animation="border" variant="success" /> : mHSPCH.map((data, index) => {
                        return (
                            <option value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=" + data.reportTypeId} key={index}>{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option >
                        )
                    })}

                </>
            )
        }
    };
    return (

        <>
            <Form >
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check   
                            inline
                            className="radioButton"
                            label="Display by Facility"
                            name="group1"
                            type={type}
                            value="DBF"
                            id={`inline-${type}-1`}
                            onChange={handleRadioChange}
                        />
                        <Form.Check
                            inline
                            label="Display by Fiscal Year"
                            name="group1"
                            type={type}
                            value="DFY"
                            id={`inline-${type}-2`}
                            onChange={handleRadioChange}
                        />

                        <br />
                        <br />
                    </div>
                ))}
                <h4>All Facilities</h4>
                <div className="col-md-4 col-md-8">
                    {/* Return List */}
                    <select className="form-select" onChange={(e) => {
                        window.open(e.target.value, '_blank')
                    }}>
                        {selectedListOne}
                    </select>
                </div>
                <br />
                <div className="col-md-4 col-md-8">
                    <h4>Mental Health Specialized Personal Care Homes</h4>
                    <select select className="form-select" onChange={(e) => {
                        window.open(e.target.value, '_blank')
                    }}>
                        {selectedListTwo}
                    </select>
                </div>
            </Form>
        </>
    );
}

export default FacilitiesDataReports;