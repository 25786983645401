import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const eCMH_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Program History and Description"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Kentucky's Early Childhood Mental Health Program (ECMHP) was created in state fiscal year 2003 as a component of a large, statewide early childhood development initiative, KIDS NOW, the majority of which is now administered by the Governor’s Office of Early Childhood (see Related Links).",
            "ECMHP is co-administered by the Children’s Behavioral Health and Recovery Services Branch within the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) and the Early Childhood Mental Health Branch within the Department for Public Health (DPH) through a Memorandum of Agreement.",
            "In turn, DBHDID contracts with the 14 Regional Community Mental Health Centers (CMHCs) for program implementation."
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Programs Goals"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "The primary goal of ECMHP is to provide:",
            <UnorderedList items={[
                "Program and child-level consultation on social, emotional and behavioral issues to programs that serve children from birth through age 5.",
                "Training on working with young children with social, emotional and behavioral needs and their families, to child-serving agencies and others.",
                "Evaluation, assessment, and therapeutic services for children from birth through age 5 and their families."
            ]} />
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Early Childhood Mental Health Specialist"


    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "Early Childhood Mental Health Specialists in each of the 14 CMHCs. The Specialists' time is devoted solely to their regional ECMHPs and building regional capacity to better meet the social, emotional, and behavioral needs of children from birth through age 5 and their families.",
            "The ECMH Specialists’ role is aimed at improving the behavioral health of children birth through age five, and their families in order to achieve improved social, emotional, and functional outcomes. "
        ]


    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Early Childhood Consultants "


    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            "The Early Childhood Consultants are located in each of the 14 CMHCs. Early Childhood Consultants offers the opportunity to work with infants, young children, and families impacted by opioid and other substance use disorders with a focus on infants and young children identified as having been exposed to substance in utero.",
            "The Early Childhood Consultants are responsible for increasing regional capacity for early childhood education and consultation regarding substance exposed infants and trauma exposed youth whose mothers are recovering from OUD to mitigate adverse childhood experiences and improve early childhood development and attachment. ",
            "To obtain more information about your local ECMHP, please use the contacts listed in the Contact Information box."
        ]


    },
   



            

           
    


];

export default eCMH_Content;