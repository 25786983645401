import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";
import kyaca from "../../assets/img/autism/kyacaLogo.jpg"
import nauwu from "../../assets/img/autism/nauwu.jpg"

const autism_Content = [

    {
        format: bodyHelperTypes.paragraph,
        order: 10,
        content: <>
            <img src={kyaca} style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block' alt="" />
        </>
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                <b>Vision</b><br />
                Autistics (individuals with autism) of all ages are engaged in self-determined lives and are valued citizens in the community.
            </>,
            <>
                <b>Mission</b><br />
                Promotes collaborative efforts and advocates to improve systems of supports for individuals with autism (autistics).

            </>,
            <>
                <b>Elevator Speech</b><br />
                The KYACA is a collaborative of autistics and allies who work to increase respect, understanding, and autonomy for ALL autistic Kentuckians, especially black, indigenous, people of color (BIPOC), through advocacy, education, and growing circles of support.
            </>,
            <>
                <b>Legislation and Council Membership</b><br />
                On April 1, 2016, Senate Bill 185 created new sections of KRS Chapter 194A to establish the Advisory Council on Autism. Council consists of governor appointed members including state agencies, autistics (consumer representatives), family members, and citizens-at-large. Full list is within the legislation at <a href="https://apps.legislature.ky.gov/law/statutes/statute.aspx?id=46882">194A.624 Advisory Council</a>.
            </>,
            <>
                <b>Kentucky Advisory Council on Autism (KYACA)</b><br />
                While the original Advisory Council legislation (<a href="https://apps.legislature.ky.gov/law/statutes/statute.aspx?id=46882">KRS 194A.624</a>) uses the term Autism Spectrum Disorder (ASD), the current Council has amended the title to <b>Kentucky Advisory Council</b> on Autism as this reflects and honors autism through a more strength-based perspective and through the <a href="https://www.optimistjenna.com/social-model-disability-autism/">social model of disability</a>.
            </>,
            <>
                <img src={nauwu} style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block' alt="" />
            </>,
            <>
                <b>Quarterly Meetings</b><br />
                For accessibility, all meetings have a virtual option, and are open to the public. Quarterly meetings are the first Thursday of the month of February, May, August, November. There is a public comment period on each agenda for non-governor appointed representatives. For more information contact <a href="mailto:talt.curry@ky.gov">Tal Curry</a>.
            </>,
            <>
                <b>Council Subcommittees</b><br />
                Lifespan subcommittees have recently addressed supporting families, children, and adults newly diagnosed with autism, addressing restraint and seclusion, addressing violence and abuse toward autistics, and increasing employment opportunities for autistic workers.  The three subcommittees are:<br /><br />
                <ul>
                    <li>Early Childhood (ages 0-5/8)</li>
                    <li>School-Age (age 5-18/21)</li>
                    <li>Adolescent & Adult (age 14 - 18/21+)</li>
                </ul>
            </>,
            <>
                In addition, these subcommittees address autism awareness, education of families and providers, increasing the availability of effective assessment and therapy, resource sharing, and other collaborative efforts. Subcommittees meet at least quarterly the month of January, April, July, and October. For more information contact <a href="mailto:talt.curry@ky.gov">Tal Curry</a>.
            </>,
            <>
                For more information on KYACA, please visit this webpage: <a href="https://www.kyaca.org/">Kentucky Advisory Council on Autism</a>.
            </>,
            <>
                <b>Office of Autism </b><br />
                Purpose is to address autism-related matters and provide support and consultation to autistics, families and community providers. Primary work of Executive Director includes: <br /><br />
                <ul>
                    <li>Offering a centralized location to coordinate statewide to enhance the quality of life and independence of autistics and to support their families and caregivers.</li>
                    <li>Improving coordination of autism resources within the system of care supporting children and adults with autism.</li>
                    <li>Providing administrative support to Council & 3 lifespan subcommittees.</li>
                    <li>Oversee Website & Council Project(s).</li>
                    <li>Assist with constituent communication.</li>
                    <li>Offer consultation to autistics, families, and community partners.  </li>
                    <li>Collaborate & serve on state councils and workgroups.</li>
                    <li>Assist in submitting KYACA <a href="https://www.kyaca.org/2023/10/05/biennial-report-of-the-kentucky-advisory-council-on-autism-released/">Biennial Report</a> to Governor.</li>
                </ul>
            </>,
            <>
                <b>Legislation</b><br/>
                On April 1, 2016, Senate Bill 185 created new sections of KRS Chapter 194A to create the Office of Autism.<br/><br/>
                <ul>
                    <li><a href="https://apps.legislature.ky.gov/law/statutes/statute.aspx?id=44950">194A.623 Office of Autism</a></li>
                </ul>
            </>,
            <>
             For more information on the Office of Autism, please visit this webpage: <a href="https://www.kyaca.org/about-us/kentucky-office-of-autism/">Kentucky Office of Autism.</a>  
            </>,
            <>
                <i>
                For more information about either KYACA or Office of Autism, contact: <br/>
                <a href="mailto:talt.curry@ky.gov">Tal Curry</a> <br/>
                Executive Director Office of Autism <br/>
                Cabinet for Health and Family Services <br/>
                Department for Behavioral Health, Developmental and Intellectual Disabilities <br/>
                (859) 447-7792
                </i>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Additional Links"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <><Links linkTypeID={800} /></>
    }





];

export default autism_Content;