// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";


function SectionTitle ({ text }) {
    return (
        
        <h4 title={text} id={text}>{text}</h4>

        
    );
};
export default SectionTitle;