import React from "react";
import "../assets/styles/pages/style.css";

function DepartmentHeader(props) {
    return (
        <>
            <div>
                <div >
                    <h1 id={props.header} title={props.header} className=" main-header-layout">{props.header}<div className="green-underline"></div></h1>
                </div>
            </div>
        </>
    );
}

export default DepartmentHeader;