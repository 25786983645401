import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import LinkNoNewPage from "../../../components/LinkNoNewPage";

const clinical_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Clinical Services Branch coordinates the quality assurance, quality improvement, and risk management responsibilities throughout the division."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Clinical Services Branch Projects"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <><LinkNoNewPage linkTypeID="721" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "National Core Indicators (NCI)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "DDID collaborates with the University of Kentucky Human Development Institute (HDI) to improve lifelong opportunities and services for individuals with disabilities, their families and the community. DDID also works with HDI to measure the quality of services provided to individuals with disabilities through participation in the NCI quality survey.",
            "The national core indicators are standard measures used across states to assess the outcomes of services provided to individuals and families. Indicators address key areas of concern including employment, rights, service planning, community inclusion, choice, and health and safety.",
            "Staff of HDI survey a sample of individuals with intellectual and developmental disabilities (IDD) each year. Those surveyed receive a variety of supports across settings and funding sources. The funding sources may vary based upon goals, but can include the Supports for Community Living (SCL) waiver, Michelle P. (MP) Waiver, intermediate care facilities (ICFs), and community mental health centers (CMHCs).",
            <>
                A stakeholder committee reviews the Kentucky core indicator results each year, makes recommendations for improvement and suggests activities to accomplish the recommendations. Websites with more information include:
                <br />
                <br />
                <Links linkTypeID="709" />
            </>,

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Supports Intensity Scale™ (SIS)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "The SIS includes complete person-centered assessments for Supports for Community Living (SCL) Waiver participants in order to assist in developing individualized person-centered service plans based on identified needs.",
            "The SIS-Adult (SIS-A) version is a standardized assessment tool designed to measure the pattern and intensity of supports required by a person aged 16 years or older with an intellectual or other developmental disability to be successful in community settings.",
            "Traditionally, a person's level of developmental disability was measured by the skills that the individual lacks. SIS-A shifts the focus from lacks to needs.",
            "The SIS evaluates practical supports that people with developmental disabilities need to lead independent lives. The SIS is administered by Division of Developmental and Intellectual Disabilities (DDID) staff with a respondent group for each person who receives supports through the SCL Waiver.",
            <>
                See Related Links for a link to the American Association on Intellectual and Developmental Disabilities (AAIDD) Supports Intensity Scale™.
                <br />
                <br />

                <Links linkTypeID="780" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Health Risk Screening Tool (HRST)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [
            "The Health Risk Screening Tool (see Related Links) is utilized with people who receive residential supports through the SCL waiver. The HRST is used to determine where an individual is likely to be vulnerable for health risks. The greatest vulnerability to health risk could be exhibited or experienced among those individuals whose services are periodic or less intense than for someone who needs daily nursing care.",
            <>
                The HRST assigns scores to rating items. The total points result in a Health Care Level with an associated Degree of Health Risk. The Health Care Levels are 1 through 6; Level 1 being the lowest risk for health concerns and Level 6 being the highest risk for poor health. It is important to understand that the HRST measures health risk, not disability. HRST is used for:

                <ul>
                    <li>Identifying health risks to reduce and prevent complications.</li>
                    <li>Increasing the monitoring of a person's health where applicable.</li>
                    <li>Identifying additional training needs of provider staff.</li>
                    <li>Identifying the need for increased monitoring of a person’s health</li>
                    <li>Assisting the team in responding to identified health risks appropriately</li>
                </ul>
                <Links linkTypeID="781" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "Risk Management"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            <>
                Components of Risk Management include:
                <ul>
                    <li>Utilizing the health risk screening tool with those who receive residential supports in SCL.</li>
                    <li>Utilizing the supports intensity scale for recognizing and mitigating risk for those in SCL.</li>
                    <li>Utilizing information gathered through NCI surveys.</li>
                    <li>Medication administration training and reporting.</li>
                    <li>Complaint reporting/follow-up process for all individuals with IDD who receive funding through the state.</li>
                    <li>Incident reporting/follow-up process for all individuals with IDD who receive funding through the state.</li>

                </ul>
                <Links linkTypeID="782" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 120,
        content: "Medicaid Waiver Management Application (MWMA)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 130,
        content: [
            "The MWMA is utilized to apply for and manage waiver activities across waivers and roles, including providers, DDID staff, Medicaid staff, and entities that Medicaid contracts with regarding the waivers.",
            <>
             DDID staff have utilized it for reviewing applications, placing individuals on the SCL and MP waiting lists, updating information regarding people on the those waiting lists, allocating waiver funding, recording information about SIS assessments, reviewing and following up on critical incidents, and some of the provider monitoring duties. Access the "Medicaid Waiver Management Application" Related Link for more information.  
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 140,
        content: "Waiver Quality Improvement Strategy and Performance Measures"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 150,
        content: [
            "In order to be approved by the Centers for Medicare & Medicaid Services (CMS), all waivers must include performance measures and a quality improvement strategy. The performance measures are gathered and trended, and strategies for improvement are developed and implemented as needed.",
            "Evidentiary reports must be submitted to CMS. The evidence is gathered and reports are drafted and submitted to the Department for Medicaid Services for submittal to CMS. The report must include a statewide improvement plan for any performance measure that falls below 86 percent."
        ]
    }
];

export default clinical_Content;