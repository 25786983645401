import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const KRSPMGContent = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"




    },
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: "The goals of the Kentucky Retail Survey Project Mini Grant are to (1) reduce retail access of tobacco/nicotine products and THC products to persons under the age of 21 and 2) increase awareness of the harms of youth tobacco/nicotine and/or THC use."




    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Application"




    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <><i>Who May Apply</i><br />
            All Kentucky substance misuse prevention coalitions or youth groups (KY-ASAP Local Boards, Drug Free Community Coalitions, Unite Groups, etc.) may apply. <u>Youth must be involved in all planning and implementation aspects of the mini grant project, but they are not required to author the grant application.</u>
        </>



    },
    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: <><i>Mini Grant Project Details</i><br />
            Awarded applications will include the following two core components:
            <UnorderedList items={["conducting Kentucky Tobacco Retail Survey Project environmental scans in retail settings", "distributing Tobacco 21 toolkits to retailers"]} />
        </>



    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: <>
            In addition to the two core strategies, awarded applications will include one or more follow-up strategies from the list below: <br />

            <UnorderedList items={["Agreements from retailers to change product placement and/or post signage to limit youth access to ALL tobacco/nicotine and THC products", "Photovoice project developed from photos taken during the scan project", "Articles in the local paper about scan project findings", "Social media posts about scan project findings", <>
                Presentation that displays scan project findings to share with community members: <br />
                <UnorderedList items={[
                    "Elected officials – fiscal courts, mayors, city councils, law enforcement, local school board officials, state and federal senators and representatives",
                    "Parents and guardians ",
                    "Youth "

                ]

                } />

            </>,
                "Campaign to promote awareness of the need for local tobacco licensing that incorporates scan project findings"

            ]} />

        </>





    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <><i>Award Amount</i><br />
                Mini grants will be awarded in amounts of up to $2,000 per grant.
            </>,
            <><i>Time Frame</i><br />
                Notice of Funding Opportunity announcements are made annually in December and are awarded in March.
            </>,
            <>
                For more information contact Steve Cambron at <a href="mailto:steve.cambron@ky.gov">steve.cambron@ky.gov</a> or Tara Rueckert at <a href="mailto:tara.rueckert@ky.gov">tara.rueckert@ky.gov</a>.
            </>
        ]




    },
];

export default KRSPMGContent;