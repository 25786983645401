import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const opiate_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Narcotic Treatment Programs and Medication-Assisted Treatment for Opioid Use Disorder"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Narcotic Treatment Programs also known as Opioid Treatment Programs use provide medications for opioid use disorder including methadone and buprenorphine (e.g., Suboxone) to treat opioid use disorder. There are currently 31 Narcotic Treatment Program in Kentucky.",
            <>
               The Substance Use Disorder Program Licensure Branch is responsible for the regulatory oversight, technical assistance, and compliance monitoring for methadone treatment. You can find the contact listing and map for each of these NTP locations <a href='https://dpt2.samhsa.gov/treatment/'><b>here</b></a>. If you are looking for a provider in your area, you can also utilize our provider directory <a href='/providerdirectory'><b>provider directory</b></a> to find more specific or specialized treatment. For more information on opioid use disorder and medications for substance use disorders, visit the <a href='https://www.samhsa.gov/medications-substance-use-disorders'><b>Substance Abuse and Mental Health Services Administration (SAMHSA).</b></a> 
            </>,
            <>
              The regulations that govern this oversight can be found in related links. 
              <br/>  
            </>
        ]
    },
];

export default opiate_Content;