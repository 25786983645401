//Using the react library
import React from "react";
import { Link } from "react-router-dom";


function Department(props) {
    return (
        <div title={props.department}><Link title={props.department} className="dropdown-item" to={props.path}>{props.department}</Link></div>
    );
}

export default Department;