// Libraries
import React from "react";
// Assets: 
// CSS
import "../assets/styles/layout/footer.css";
import logo from "../assets/img/logos/kentucky-icons/Team-Kentucky-Blue_286x150.png";

// Data
import data from "../data/data.json";

import { Link } from "react-router-dom";

function Footer() {
    return (

        <div className="generalFooterStyle">

       

            <section className="subscribeCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 subFooter">
                            <p style={{fontWeight: "bolder", fontSize: "25px"}} id="Looking for Service?" title="Looking for Service?" className="top-title-footer">Looking for Service?</p> 
                                <p title="To receive Press Releases and updates on Families, Wellness and Medical information, subscribe below.">To receive Press Releases and updates on Families, Wellness and Medical information, subscribe below.</p>
                            </div>
                            {/* <div class="col-sm-6">
                            <a href="https://public.govdelivery.com/accounts/KYCHFS/subscriber/new" class="btn btn-success" role="button" target="_blank">Subscribe</a>
                        </div> */}
                            <div className="col">
                                <Link to={"/"} className="btn btn-primary" title="Learn More" role="button" target="_blank">Learn More</Link>
                            </div>
                        </div>
                    
                </div>
            </section>


            <div className="outerContainer footerRow">
                <div className="container">
                    <div title="Commonwealth of Kentucky Cabinet for Health and Family Services" className="footer-brand">

                        Commonwealth of Kentucky

                        Cabinet for Health and Family Services
                    </div>
                    <div className="portal-FooterInsert">
                        <div className="outerContainer enterpriseFooterRow">
                            <nav className="enterprise-footer-links">
                                <ul className="list-unstyled list-inline">
                                    <li>
                                        <a title="Policies" href="https://kentucky.gov/policies/Pages/default.aspx">Policies</a>
                                    </li>
                                    <li>
                                        <a title="Security" href="https://kentucky.gov/policies/Pages/security.aspx">Security</a>
                                    </li>
                                    <li>
                                        <a title="Disclaimer" href="https://kentucky.gov/policies/Pages/disclaimer.aspx">Disclaimer</a>
                                    </li>
                                    <li>
                                        <a title="Accessibility" href="https://kentucky.gov/policies/Pages/accessibility.aspx">Accessibility</a>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <div className="outerContainer unbridledRow">
                            <img src={logo} />

                        </div>

                        <div class="outerContainer copyrightRow">

                            <p><span title="© 2024 Commonwealth of Kentucky. All rights reserved." class="copyright-text">© 2024 Commonwealth of Kentucky.</span><span class="all-rights-text"> All rights reserved.<span></span></span></p>

                            <p><span title="Kentucky.gov" class="kentuckygov-text"><a href="http://www.kentucky.gov">Kentucky.gov</a><span></span></span></p>



                        </div>

                    </div>



                </div>











            </div>
        </div>
    )
}

export default Footer;