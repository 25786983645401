// @flow 
import * as React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import WomanPlayingUno from '../assets/img/slideshow_pictures/WomanPlayingUno.jpg';
import LadiesWithTablet from '../assets/img/slideshow_pictures/extra_large_photos/Ladies_using_tablet_xl.jpg';
import girlDrawing from '../assets/img/slideshow_pictures/extra_large_photos/girlDrawing.jpg';
import GirlAndDrawing from '../assets/img/slideshow_pictures/girl_and_drawing.jpg';
import FatherAndDaughter from '../assets/img/FatherSittingWithDaughters.jpg';
function CarouselFade() {
    return (
        <div className='container slideshow-image'>
        <div className=''>
            <Carousel fade>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <div className=' ' > {/*<img
                        className='d-block w-100 height-adjust-pic' src={GirlWithFlower} /> 
                    <div className='d-block w-100 p3' > */}<img
                        className='d-block height-adjust-pic' src={girlDrawing} /> 
                        </div>
                    <Carousel.Caption>
                        
                {/* <h2 className="hero-header">Building a Stronger Community</h2> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div ><img title='Ladies with a tablet' className='d-block w-100 height-adjust-pic' src={LadiesWithTablet} /></div>
                    <Carousel.Caption>
                    
                        
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div ><img title='' className='d-block w-100 height-adjust-pic' src={WomanPlayingUno} /></div>
                    <Carousel.Caption>
                    
                        
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div ><img className='d-block w-100 height-adjust-pic' src={GirlAndDrawing} /></div>
                    <Carousel.Caption>
                  
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div ><img className='d-block w-100 height-adjust-pic' src={FatherAndDaughter} /></div>
                    <Carousel.Caption>
                  
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            </div>

        </div>
    );
}

export default CarouselFade;

