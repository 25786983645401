// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";


function UnorderedList({ items }) {
    return (
        

            <ul>
                {items.map((x, index) => <li title={x} key={index}>{x}</li>)}
            </ul>
        
    );
};
export default UnorderedList;