const isNullOrWhiteSpace = (arg) => {
    if (arg == null || arg == undefined) {
        return true;
    }
    if (typeof arg !=  "string") {
        return true;
    }
    if (arg.trim() == "") {
        return true;
    }
    else{
        return false;
    }

    
};
export {isNullOrWhiteSpace};