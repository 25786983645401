import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLFormsRisk_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL Risk Management Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Click on the name of a resource in the list below to access it. 
                <br/>
                <br/>
                <Links linkTypeID="238" />
            </>
        ]
    },
];

export default sCLFormsRisk_Content;

