import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Kentucky from "../../../assets/img/sud/RPC_Map.png"
import { Link } from "react-router-dom";

const sARPC_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Kentucky's Regional Prevention Centers (RPCs) assist communities in developing and implementing comprehensive plans to prevent alcohol, tobacco, and other drug (ATOD) use and misuse. Fourteen centers are available to serve all counties in the state.",
            "Prevention specialists, collaboration specialists, and youth empowerment specialists at each center provide education and training programs, information, and consultation services.",
            <>
                You can find your local RPC <Link to="/providerDirectory"><b>here</b></Link>.<br />

                <img className="img-fluid" src={Kentucky} alt=""></img>

            </>,

        ]
    },
];

export default sARPC_Content;