import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLForms_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL Forms"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Click on the name of a form type in the list below to access a list of links to the forms.
                <br />
                <br />
                <Links linkTypeID="410" />
            </>
        ]
    },
];

export default sCLForms_Content;

