import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import PBForms from "../../../components/PBForms";

const  pBFormsLibrary_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><PBForms /></>
        ]
    },
];

export default pBFormsLibrary_Content;