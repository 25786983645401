import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLRegulations_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL and MPW Regulations"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <><Links linkTypeID="310" /></>
        ]
    },
];

export default sCLRegulations_Content;

