//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
// Report Names
import ReportName from '../data/dataReports.json';
// React-Bootstrap Form
import Form from 'react-bootstrap/Form';

function DataReportList() {

    const { error, processing, getRequest } = useApi();
    const [allClientsAllPrograms, setAllClientsAllPrograms] = useState([]);
    const [adultClientsAndAdultClientsSMI, setAdultClientsAndAdultClientsSMI] = useState([]);
    const [childClientsAndChildClientsSED, setChildClientsAndChildClientsSED] = useState([]);
    const [crisisStabilizationUnitReport, setCrisisStabilizationUnitReport] = useState([]);
    const [deafAndHardHearing, setDeafAndHardHearing] = useState([]);
    const [mentalHealth, setMentalHealth] = useState([]);
    const [intellectualDisabilities, setIntellectualDisabilities] = useState([]);
    const [substanceAbuse, setSubstanceAbuse] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "cmhc/report-data");
            if (status == 200) {
                // console.log(data.data);
                FilterDocuments(data.data);
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);

    function FilterDocuments(formsData) {

        const allClientsAllProgramsFiltered = [];
        const adultClientsAndAdultClientsSMIFiltered = [];
        const childClientsAndChildClientsSEDFiltered = [];
        const crisisStabilizationUnitReportFiltered = [];
        const deafAndHardHearingFiltered = [];
        const mentalHealthFiltered = [];
        const intellectualDisabilitiesFiltered = [];
        const substanceAbuseFiltered = [];

        formsData.forEach((form) => {
            if (form.reportTypeId == 1 && form.webPage03 == 'I') {
                allClientsAllProgramsFiltered.push(form);
                // console.log(allClientsAllProgramsFiltered);
            }
            else if (form.reportTypeId == 2 && form.webPage03 == 'B') {
                adultClientsAndAdultClientsSMIFiltered.push(form);
            }
            else if (form.reportTypeId == 3 && form.webPage03 == 'C') {
                childClientsAndChildClientsSEDFiltered.push(form);
            }
            else if (form.reportTypeId == 4 && form.webPage03 == 'K') {
                crisisStabilizationUnitReportFiltered.push(form);
            }
            else if (form.reportTypeId == 5 && form.webPage03 == 'H') {
                deafAndHardHearingFiltered.push(form);
            }
            else if (form.reportTypeId == 6 && form.webPage03 == 'A') {
                mentalHealthFiltered.push(form);
            }
            else if (form.reportTypeId == 7 && form.webPage03 == 'D') {
                intellectualDisabilitiesFiltered.push(form);

            }
            else if (form.reportTypeId == 8 && form.webPage03 == 'E') {
                substanceAbuseFiltered.push(form);

            }
        })
        setAllClientsAllPrograms(allClientsAllProgramsFiltered);
        setAdultClientsAndAdultClientsSMI(adultClientsAndAdultClientsSMIFiltered);
        setChildClientsAndChildClientsSED(childClientsAndChildClientsSEDFiltered);
        setCrisisStabilizationUnitReport(crisisStabilizationUnitReportFiltered);
        setDeafAndHardHearing(deafAndHardHearingFiltered);
        setIntellectualDisabilities(intellectualDisabilitiesFiltered);
        setMentalHealth(mentalHealthFiltered);
        setSubstanceAbuse(substanceAbuseFiltered);
    }
    const [selectedListOne, setSelectedListOne] = useState(<></>);


    const handleRadioChange = (value) => {

        if (value.target.value == "DBR") {
            setSelectedListOne(
                <>
                    <div className="homepage-quick-cta">
                        <div className="row">
                            <div className="col-md-10 col-md-10">
                                {/* Return List */}
                                <b>All Clients Served in All Programs</b>
                                <select target="_blank" className="form-select" onChange={(e) => {
                                    // window.open(window.location, '_blank')
                                    window.location = e.target.value;

                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : allClientsAllPrograms.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        }).sort((a, b) => a.name - b.name)
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Adult Clients and Adult Clients With SMI</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : adultClientsAndAdultClientsSMI.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Child Clients and Child Clients With SED</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : childClientsAndChildClientsSED.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Crisis Stabilization Unit Reports (available by fiscal year only)</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : crisisStabilizationUnitReport.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Deaf and Hard of Hearing</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : deafAndHardHearing.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Mental Health</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : mentalHealth.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Intellectual Disabilities</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : intellectualDisabilities.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Substance Use Disorder</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : substanceAbuse.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.nameRFormatted + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            setSelectedListOne(
                <>

                    <div className="homepage-quick-cta">
                        <div className="row">
                            <div className="col-md-10 col-md-10">
                                {/* Return List */}
                                <b>All Clients Served in All Programs</b>
                                <select className="form-select" target="_blank" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : allClientsAllPrograms.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        }).sort((a, b) => a.name - b.name)
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Adult Clients and Adult Clients With SMI</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : adultClientsAndAdultClientsSMI.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Child Clients and Child Clients With SED</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : childClientsAndChildClientsSED.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Crisis Stabilization Unit Reports (available by fiscal year only)</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : crisisStabilizationUnitReport.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Deaf and Hard of Hearing</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : deafAndHardHearing.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Mental Health</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : mentalHealth.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Intellectual Disabilities</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : intellectualDisabilities.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                                <br />
                                <br />
                                <b>Substance Use Disorder</b>
                                <select className="form-select" onChange={(e) => {
                                    window.location = e.target.value;
                                }}>
                                    <option>Select a report</option>
                                    {
                                        processing ? <Spinner animation="border" variant="success" /> : substanceAbuse.map((data, index) => {
                                            return (
                                                <option target="_blank" value={"https://dbhdid.ky.gov/DataReports/Reports.aspx?r=" + data.name + "&x="+data.extension.replace(".","")+"&t=1"} key={index} className="form-select">{data.extension.replace(".","") == "rdl" ? data.reportDescription +"*" : data.reportDescription}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                </>
            )

        }
    };

    return (
        <>
            <Form>
                {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="Display by Region"
                            name="group1"
                            type={type}
                            value="DBR"
                            id={`inline-${type}-1`}
                            onChange={handleRadioChange}
                        />
                        <Form.Check
                            inline
                            label="Display by Fiscal Year"
                            name="group1"
                            type={type}
                            value="DFY"
                            id={`inline-${type}-2`}
                            onChange={handleRadioChange}
                        />

                        <br />
                        <br />
                    </div>
                ))}
                {selectedListOne}
            </Form>
        </>
    );
}

export default DataReportList;