import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const faithBased_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Faith-based communities and organizations have an essential role to play in building resilience and facilitating recovery for those living with challenges associated with mental health, substance use, and/or co-occurring disorders.",
            "With accurate information and effective resources and partnerships these organizations can address the most pressing needs of their communities.",
            "The Division can support faith-based and community partners by:",
            <>
                Help to:
                <br />
                <br />

                <ul>
                    <li>Identify local resources and funding opportunities that may support substance use prevention, intervention, treatment and recovery.</li>
                    <li>Providing training and technical assistance on various behavioral health topics, such as suicide prevention, evidence-based substance use treatment, recovery supports, the role of faith-based organizations, and more. </li>
                    <li>Linking to community-based partners to support service delivery and connection to resources. </li>

                </ul>
            </>
        ]
    },
];

export default faithBased_Content;