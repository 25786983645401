import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const kEDC_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"
    },
    {

        format: bodyHelperTypes.paragraph,
        order: 20,
        content: "In 2020, KRS 210.051 established the Kentucky Eating Disorder Council (KEDC) within the Cabinet for Health and Family Services. See KEDC legislation KRS 210.051  in under Related Links."

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Goals of the Council"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <><UnorderedList items={[
            "Oversee the development and implementation of eating disorder awareness, education and prevention programs.",
            "Identify strategies for improving access to adequate diagnosis and treatment services.",
            "Assist the Cabinet for Health and Family Services in identifying eating disorder research projects.",
            "Work with the Cabinet for Health and Family Services and other appropriate entities to routinely examine existing surveillance systems, data collection systems, and administrative databases to determine the best strategies for implementing evidence-based eating disorder measures that provide data for program and policy planning purposes.",
            "As reasonably as possible, collaborate and coordinate on data research projects with the Cabinet for Health and Family Services and other appropriate entities.",
            "Make recommendations regarding legislative and regulatory changes as appropriate."
        ]} /></>
    },
    {

        format: bodyHelperTypes.title,
        order: 50,
        content: "Membership"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: "Membership on the Kentucky Eating Disorder Council is by appointment of the Governor’s Office, and is comprised of representatives from state government agencies, individuals with lived experience, researchers and boards overseeing disciplines designated in state statute.  Council meetings are open to the public but by Council members. Standing committees are open to the public and have no restrictions on participation."

    },
    {

        format: bodyHelperTypes.title,
        order: 70,
        content: "Meetings"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content: "Meetings are via Zoom and open to the public. Currently, they are held on the third Tuesday of even numbered months from 2 to 3:30p.m. eastern/1 to 2:30 p.m. central."

    },
    {

        format: bodyHelperTypes.title,
        order: 90,
        content: "Resources"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [

            "For Individuals/Parents/Caregivers",

            <>

                <ul>
                    <li><a href="https://www.confidentbody.net/about.html"><b>Confident Body, Confident Child</b></a> is an Australian evidence-based prevention program for parents of 2 – 6-year-olds that helps prevent eating disorders by providing resources and strategies to promote positive body image, healthy eating, and physical activity.</li>

                    <br />

                    <li><a href="https://thedinnertableproject.org/"><b>The Dinner Table Project</b></a> Eating dinner together, especially without electronics as a distraction, is one way adults can engage young people to support mental health, prevent substance use and eating disorders, and promote resilience in their everyday lives.</li>

                    <br />

                    <li><a href="https://www.feast-ed.org/ "><b>Families Empowered And Supporting Treatment (FEAST)</b></a> is an international non-profit organization for parents and caregivers of loved ones affected by eating disorders.</li>

                    <br />

                    <li><a href="https://anad.org/"><b>National Association of Anorexia Nervosa and Associated Disorders (ANAD)</b></a> free peer support services to anyone an eating disorder, regardless of age, race, gender identity, sexual orientation, or background.</li>

                    <br />
                </ul>


            </>

        ]




    },

    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "For Professionals"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [

            <UnorderedList items={[
                <><b><a href="https://www.aedweb.org/home">Academy for Eating Disorders</a></b> helps physicians, psychiatrists, psychologists, nutritionists, academic researchers, students, and experts, through lived experience, connect and collaborate with one another.

                    <br />
                    <br />

                </>,
                <><b><a href="https://www.iaedp.com/certification-overview/">International Association of Eating Disorder Professionals (IAEDP)</a></b> Interested in learning more or obtaining your Certified Eating Disorder Specialist (CEDS)
                    <br />
                    <br />
                </>,
                <><b><a href="https://www.theprojectheal.org/insurance-resource-hub">Project Heal Insurance Resource Hub</a></b> is an interactive tool to assist professionals and caregivers in finding providers who take a particular insurance by state </>



            ]} />
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 120,
        content: "For Everyone"
    },

    {
        format: bodyHelperTypes.paragraphs,
        order: 130,
        content: [

            <>

                <ul>
                    <li><a href="https://www.theprojectheal.org/free-eating-disorder-support-groups"><b>Project Heal Directory of free support groups by type</b></a></li> <br />
                    <li><a href="https://www.nationaleatingdisorders.org/"><b>National Eating Disorders Association (NEDA)</b></a> works to advance research, community building, and awareness to support those who may be affected by an eating disorder.</li> <br />

                    <li><a href="https://www.nceedus.org/ "><b>National Center for Excellence for Eating Disorders (NCEEDS)</b></a> mission is to advance the education and training of healthcare providers and to promote public awareness of eating disorders and eating disorder treatment. NCEEDs holds resources for Parents/Caregivers/Individuals, Professionals, and Students.</li>
                </ul>
            </>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 140,
        content: "Find Eating Disorder Treatment"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 150,
        content: <>

            <b>

                <ul>
                    <li><a href="https://anad.org/get-help/treatment-directory/">https://anad.org/get-help/treatment-directory/</a></li><br />

                    <li><a href="
            https://findedhelp.com/">https://findedhelp.com/</a></li><br />

                    <li><a href="https://www.theprojectheal.org/insurance-resource-hub">https://www.theprojectheal.org/insurance-resource-hub</a></li><br />

                    <li><a href="https://iaedp.site-ym.com/search/custom.asp?id=4255">Find a Certified Eating Disorder Specialist (CEDS)</a></li>
                </ul>

            </b>


        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 160,
        content: "Screening Resources"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 170,
        content: <>

            <b>

                <ul>
                    <li><a href="https://www.nceedus.org/sbirt-for-eating-disorders/">SBIRT - ED</a></li> <br />


                    <li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1070794/">SCOFF</a></li> <br />

                    <li><a href="https://www.maofp.org/resources/Documents/Disordered-Eating-Poster.pdf">DESA 6 (for athletes) </a></li>
                </ul>

            </b>
        </>
    },

    {
        format: bodyHelperTypes.title,
        order: 180,
        content: "Annual Reports"
        
    },

    {
        format: bodyHelperTypes.paragraph,
        order: 190,
        content: <>

            <Links linkTypeID="799" />
        </>
    }






];

export default kEDC_Content;