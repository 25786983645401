import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLFormsCertification_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL Certification Process Forms"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Click on the name of a document in the list below to access a copy of the document.
                <br />
                <br />
                <Links linkTypeID="235" />
            </>
        ]
    },
];

export default sCLFormsCertification_Content;