import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";

const sCL_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Supports for Community Living (SCL) Waiver Branch of the Division of Developmental and Intellectual Disabilities (DDID) provides staff support for and oversight of the Supports for Community Living waiver program. The SCL waiver program is a home- and community-based program funded by the Kentucky Department for Medicaid Services and administered by DDID.",
            "The SCL waiver program was developed for Kentucky citizens as an alternative to institutionalization. The focus of SCL is to allow a person to remain in or return to the community, and to build and live a good life. Eligibility for enrollment in the SCL waiver program requires that a person with an intellectual disability or related conditions meets the level of care requirements for an Intermediate Care Facility for persons with Intellectual Disabilities (ICF/IID), and Medicaid eligibility requirements.",
            "Application to the SCL waiting list requires completion of an application and submission of required documentation. Application can be done in two ways:",
            <>
                <ol>
                    <li>By helping someone create an account in <a href='https://kynect.ky.gov/benefits/s/?language=en_US'>kynect benefits</a> and helping the person complete the </li>

                    <li>
                        For CMHCs and case management providers, by completing and submitting the application in the Medicaid Waiver Management Application (MWMA) using their logins. Utilize the <i>Integrated Waiver Application-Initiation and Management Reference Guide</i> in the TRIS training portal for instructions. Supports for Community Living program offers the services listed below. These are based on the person's goals, choices and priorities as identified through a person-centered service plan. Click on a service in the list below to view a description of the service or access the SCL Services page in the Related Links box on the right side of this page.
                    </li>
                    Supports for Community Living program offers the services listed below. These are based on the person's goals, choices and priorities as identified through a person-centered service plan. Click on a service in the list below to view a description of the service or access the SCL Services page in the Related Links box on the right side of this page.
                </ol>
            </>,
            <>
                <Link className="quick-links-body" to={"/ddid/scl-services"}>Link to SCL Services</Link>
            </>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "SCL Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "SCL providers include regional comprehensive care centers and public and private agencies across the state. These providers are reviewed and certified by the Department for Behavioral Health, Developmental and Intellectual Disabilities initially and at least every two years thereafter, in accordance with the standards and requirements set forth by the Kentucky Department for Medicaid Services.",
            "SCL providers are continuously monitored to maintain quality of supports. All SCL providers have agreed to provide services according to the best practice accepted by their professional organization and to provide services in compliance with federal and state statutes regardless of age, color, creed, disability, ethnicity, gender, marital status, national origin, race, religion, or sexual orientation."


        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "How to Obtain Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                The first step is to complete an application in benefind (see the "benefind" link in Related Links) after creating an account OR ask a provider to help you with the application (see the "Provider Directory" link in Related Links). There is a waiting list for services and supports. An application will allow your name to be included on the list if you meet the criteria to receive SCL services.
            </>
        ]
    }
];

export default sCL_Content;