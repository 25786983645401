//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
import SanitizeHref from "../utils/SanitizeHref";

function Links({ linkTypeID, ...props }) {

    const [links, setLinks] = useState([]);
    const { error, processing, getRequest } = useApi();

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/" + linkTypeID);
            if (status == 200) {
                // console.log(data.data)
                setLinks(data.data.sort((a, b) => a.sort - b.sort));
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);
    return (
        <>
            <div className="">
                <ul className="no-bullet">
                    {/* Return Links */}
                    {
                        processing ? <Spinner animation="border" variant="success" /> : links.map((data, index) => {
                            return (
                                <li><a key={index} title={data.text} target="_blank" className="quick-links-body" href={SanitizeHref(data.url)}>{data.text}</a></li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    );
}

export default Links;