import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";

const kRHN_Content = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Recovery housing, also known as sober living, is a type of supportive housing that is designed to provide a safe and stable living environment for people who are in recovery from addiction. Recovery housing can be an important step for people who are transitioning from inpatient treatment programs or who are looking for a supportive living environment while they continue their recovery journey.",
            "Recovery housing is typically based on the social model of recovery, which emphasizes peer support and community involvement as important components of recovery. In a recovery housing setting, residents are encouraged to support each other through their recovery journeys, and to take an active role in the management of the house. This can include participating in house meetings, helping to plan social activities, and contributing to the upkeep of the house.",
            <>
                For more information about different kinds of recovery housing, visit the resources below:
                <br />
                <br />
                <ol>
                    <li><b><a href='https://narronline.org/'>National Alliance for Recovery Residences (NARR)</a>:</b> NARR is a non-profit organization that provides a national standard for recovery housing, as well as resources and support for recovery housing providers.</li>
                    <li><b><Link to={"/sud/oxfordhouse"}>Oxford House</Link>:</b> Oxford House is a network of self-run recovery houses that are operated by residents themselves, with support from a central organization. Oxford Houses can be found in many communities across the United States.</li>
                    <li><b><a href="https://www.chfs.ky.gov/agencies/dbhdid/Pages/krhn.aspx">Kentucky Recovery Housing Network (KRHN)</a>:</b> The Kentucky Recovery Housing Network (KRHN) is the state affiliate of the National Alliance for Recovery Residences (NARR), and aims to support and enhance the quality of recovery housing in Kentucky. The KRHN operates a certification program for recovery housing, which sets standards for safe, supportive, and high-quality recovery housing in the state. KRHN provides training, technical assistance, and networking opportunities for recovery housing providers, and works to promote the adoption of best practices in recovery housing. The KRHN also serves as a resource for individuals in recovery who are seeking safe and supportive housing options. By working to improve the quality of recovery housing in Kentucky, the KRHN is helping to create a strong foundation for long-term recovery and improved quality of life for individuals in recovery.</li>
                </ol>
            </>
        ]
    },
];

export default kRHN_Content;