import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DPRForms from "../../../components/DPRForms";

const dPR_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><DPRForms /></>
        ]
    },
];

export default dPR_Content;