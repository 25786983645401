import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const tCMAdult_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Targeted Case Management Services for Adults with Serious Mental Illness"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Through state general revenue and Medicaid funding, targeted case management services are available to adults with serious mental illness (SMI) in all of Kentucky's 120 counties.
            </>,
            <>
                Targeted case management is defined as services that are furnished to assist adults with (SMI) in gaining access to needed medical, social, educational, or other services.
            </>,
            "Guidance for targeted case management services for these populations can be found in 907 KAR 15:060, 907 KAR 15:065, 907 KAR 15:050, and 907 KAR 15:055 (see Related Links).",
            "Guidance for targeted case management eligibility and training requirements can be found at 908 KAR 2:260 and through “Related Links” on this page.",
            <>
                Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) provides "wraparound" funds to Community Mental Health Centers (CMHCs) across the state to purchase necessary items and services that are not covered by available resources but are needed to adequately serve adults with SMI in the community. These funds are used for the nonrecurring cost of basic goods and services that are necessary for ensuring successful living in the community for adults with serious mental illness.
            </>
        ]
    },
];

export default tCMAdult_Content;