import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const eBPCYF_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Early Childhood Mental Health Evidence-based Practices"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                <b>Attachment and Bio-behavioral Catch-up (ABC)</b> is a parent/child treatment approach designed to help caregivers provide nurturing care and engage in synchronous interactions with their infants. ABC helps caregivers re-interpret children's behavioral signals so that they can provide nurturance through parent coaching sessions. It is a home-visiting parenting program that helps parents nurture and respond sensitively to their infants and toddlers in order to foster their development and for strong and healthy attachments. For more information, please visit <a href='https://www.abcintervention.org/ '>https://www.abcintervention.org/</a>.<br />
                <b>Population of focus:</b> ABC was developed primarily for use with low-income African-American, Hispanic, and non-Hispanic White families who have experienced neglect, physical abuse, domestic violence, and placement instability. Children age birth – 24 months; both males and females; in low-income families and who have experienced neglect, abuse, domestic violence, placement instability.
            </>,
            <>
                <b>Child-Parent Psychotherapy (CPP)</b>  focuses on attachment, self-regulation, competency, and trauma experience integration. The goal of the model is to support the child, family, and system’s ability to engage in the present moment. For more information, please visit <a href='www.childparentpsychotherapy.com'>HOME – Child-Parent Psychotherapy</a>.<br />
                <b>Population of focus: </b>Age 0-6; both males and females; for youth who have experienced a wide range of traumas and parents with chronic trauma.z
            </>,
            <>
                <b>Parent- Child Interaction Therapy (PCIT) </b>focuses on decreasing externalizing child behaviors problems, increasing child social skills and cooperation, and improving the parent-children attachment relationship. <a href='http://www.pcit.org/'>http://www.pcit.org/</a><br />
                <b>Population of focus: </b>Age 2-7.
            </>,
            <>
                <b>Trauma- Focused Cognitive Behavioral Therapy (TF-CBT) </b>focuses on addressing distorted beliefs and attributions related to abuse and trauma, providing a supportive environment for children to talk about traumatic experiences, and helping parents cope with their distress and learn effective ways to support their children.  For more information visit <a href='https://tfcbt.org/ '>https://tfcbt.org/</a>.<br />
                <b>Population of focus: </b>Age 3-18.
            </>,
            <>
                <b>Nurturing Parenting Programs (CPP)</b> focuses on attachment, self-regulation, competency, and trauma experience integration. The goal of the model is to support the child, family, and system’s ability to engage in the present moment. For more information, please visit <a style={{ overflowWrap: 'break-word' }} href='www.childparentpsychotherapy.com'>HOME – Child-Parent Psychotherapy</a>.<br />
                <b>Population of focus: </b>Age 0-17.
            </>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Trauma-informed, Evidence-based and Best Practices for Children, Youth, and Adults"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                <b>Child-Parent Psychotherapy (CPP)</b> focuses on attachment, self-regulation, competency, and trauma experience integration. The goal of the model is to support the child, family, and system’s ability to engage in the present moment. For more information, please visit <a style={{ overflowWrap: 'break-word' }} href='www.childparentpsychotherapy.com'>HOME – Child-Parent Psychotherapy</a><br />
                <b>Population of focus: </b> Age 0-6; both males and females; for youth who have experienced a wide range of traumas and parents with chronic trauma
            </>,
            <>
                <b>Dialectical Behavior Therapy (DBT) </b> focuses on providing therapeutic skills in four key areas; mindfulness, emotional regulation, and interpersonal effectiveness.  For more information, please visit <a style={{ overflowWrap: 'break-word' }} href='https://behavioraltech.org/resources/faqs/dialectical-behavior-therapy-dbt/ '>https://behavioraltech.org/resources/faqs/dialectical-behavior-therapy-dbt/ </a>
                <br /> <b>Population of focus: </b> Adolescents and Adults
            </>,
            <>
                <b>Eye Movement Desensitization and Reprocessing (EMDR) </b> focuses on spontaneous associations of traumatic images, thoughts, emotions, and sensations; and dual stimulations using bilateral eye moments, tones, and taps. For more information, please visit <a style={{ overflowWrap: 'break-word' }} href='https://www.apa.org/ptsd-guideline/treatments/eye-movement-reprocessing'>Eye Movement Desensitization and Reprocessing (EMDR) Therapy (apa.org)</a><br />
                <b>Population of focus: </b>Adults
            </>,
            <>
                <b>Kniffley Racial Trauma Therapy (KRTT) </b>is an evidence-based, race-based trauma therapy model. It addresses three areas: physiological, psychological, and relational impact of race-related stress and trauma; evidence-based assessment tools for racial identity development, quantifying discriminatory experiences, and determining the physical and emotional impact of racially stressful events; and the application of culturally relevant therapeutic techniques rooted in racial identity exploration/development, racial trauma processing, and skill development. Training is offered via a virtual, self-paced program. For more information, please visit: <a style={{ overflowWrap: 'break-word' }} href=' https://drstevenkniffleyjr.com/racial-trauma-therapy-training/'>https://drstevenkniffleyjr.com/racial-trauma-therapy-training/</a> <br />
                <b>Population of Focus: </b>Black, Indigenous, and People of Color (BIPOC) age 13 and up who have experienced race-related stress and trauma. It has been found to reduce race-related stress and trauma and promote racial identity exploration/development
            </>,
            <>
                <b>Kniffley Youth Racial Therapy (KYRTT) </b>is a promising trauma therapy model for children. It addresses three areas: physiological, psychological, and relational impact of race-related stress and trauma; evidence-based assessment tools for racial identity development, quantifying discriminatory experiences, and determining the physical and emotional impact of racially stressful events; and the application of culturally relevant therapeutic techniques rooted in racial identity exploration/development, racial trauma processing, and skill development. Training is offered via a virtual, self-paced program. For more information, please visit: <a style={{ overflowWrap: 'break-word' }} href="https://kniffleyyouthracialtraumatherapy.talentlms.com/catalog/info/id:127 ">https://kniffleyyouthracialtraumatherapy.talentlms.com/catalog/info/id:127</a><br />
            </>,
            <>
                <b>Nurturing Parenting Programs</b> focuses on family -centered trauma initiatives designed to build nurturing parenting skills as an alternative to abusive and neglecting parenting and child-rearing practices. The long term goals are to prevent recidivism in families receiving social services, lower the rate of multi-parent teenage pregnancies, reduce the rate of juvenile delinquency and alcohol abuse, and stop the intergenerational cycle of child abuse by teaching positive parenting behaviors. NPP has several models created to support subpopulations of families (i.e., military families; prenatal families; young parents; families in substance use treatment and recovery, and others). For more information visit <a style={{ overflowWrap: 'break-word' }} href='https://www.nurturingparenting.com/about.html '>https://www.nurturingparenting.com/about.html </a>
                <b>Population of Focus: </b>Parents and their children
            </>,
            <>
                <b>Parent - Child Interaction Therapy (PCIT)</b> focuses on decreasing externalizing child behaviors problems, increasing child social skills and cooperation, and improving the parent-children attachment relationship.  <a href='http://www.pcit.org/  '>http://www.pcit.org/</a><br />
                <b>Population of focus: </b>Children age 2-7
            </>,
            <>
                <b>Seeking Safety</b> is a counseling model to help attain safety from trauma and/ substance abuse. For more information, please visit <a href='https://www.treatment-innovations.org/ss-description.html'>https://www.treatment-innovations.org/ss-description.html</a><br />
                <b>Population of focus: </b>Adolescents and adults who have trauma
            </>,
            <>
                <b>Trauma-Focused Cognitive Behavioral Therapy (TF-CBT) </b>focuses on addressing distorted beliefs and attributions related to abuse and trauma, providing a supportive environment for children to talk about traumatic experiences, and helping parents cope with their distress and learn effective ways to support their children.  For more information visit <a href='https://tfcbt.org/'>https://tfcbt.org/</a><br />
                <b>Population of focus: </b>Children and youth 3-18
            </>,
            <>
                <b>Trauma Recovery and Empowerment Model (TREM) </b> is a group- based intervention that addresses PTSD and other closely related mental disorders. For more information, please visit <a href='https://www.improvingmipractices.org/resources/encyclopedia/trauma-recovery-and-empowerment-model-trem'>https://www.improvingmipractices.org/resources/encyclopedia/trauma-recovery-and-empowerment-model-trem</a><br />
                <b>Population of focus: </b>Adult women who have a history of sexual, physical, and/or emotional abuse and can have severe mental health problems and frequent co-occurring substance abuse issues
            </>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Evidence-based / informed Care Coordination Practices for Children and Youth"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            <>
                <b>FOCUS </b>is an evidence informed intermediate care coordination model that was designed to support decreased system involvement while working to build connections and supports for families through community-based resources. For more information, please visit: <a href='https://theinstitute.umaryland.edu/our-work/national/focus/'>https://theinstitute.umaryland.edu/our-work/national/focus/</a><br />
                <b>Population of focus:</b> FOCUS is intended for youth and their families with lesser or intermediate complexity involved with child welfare, juvenile justice, or behavioral health agencies who are at risk of deeper or multi-system involvement, and whose challenges exceed the resources of a single organization.
            </>,
            <>
                <b>High Fidelity Wraparound (HFW) </b>is an Evidence-informed Intensive Care Coordination model. It is an ecologically based process and approach to care planning that builds on the collective action of a committed group of family, friends, community, professional, and cross-system supports mobilizing resources and talents from a variety of sources resulting in the creation of a plan of care that is the best fit between the family vision and story, team mission, strengths, needs, and strategies. For more information, please visit: <a href='https://www.nwic.org/'>https://www.nwic.org/</a><br />
                <b>Population of focus</b>children youth who meet criteria for having a serious emotional disability and who have complex emotional, behavioral, or mental health needs, and their families. More effective for culturally diverse youth compared to traditional services. HFW has been shown to improve placement stability; decrease out of home placements; and improve emotional and behavioral functioning
            </>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Evidence-based/informed Clinical Practices/Approaches for Children and Youth"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            <>
                <b>Functional Family Therapy (FFT) </b> is an evidence-based, strength-focused family counseling approach. It is short term and is provided in home and clinic settings, and can also be provided in school; juvenile justice; mental health; and child welfare facilities. For more information please visit: <a href='https://www.fftllc.com/'>https://www.fftllc.com/</a><br />
                <b>Population of focus: </b> youth age 11-18 who have been referred by school, child welfare, or juvenile justice for emotional or behavioral problems, substance use, or delinquency.
            </>,
            <>
                <b>Managing and Adapting Practice (MAP) </b>is a system designed to improve the quality, efficiency, and outcomes of children’s mental health services by providing access to most current information about effective practice techniques and providing easy to use measurement resources and clinical protocols. It provides an online database for clinicians that suggests evidence-based treatment programs as well as detailed recommendations about discrete components of evidence-based treatments that are effective with youth’s characteristics. The MAP system also includes an evaluation framework that tracks outcomes and practice. For more information please visit: <a href='https://welcome.practicewise.com/product/managing-and-adapting-practice-map/'>https://welcome.practicewise.com/product/managing-and-adapting-practice-map/ </a><br />
                <b>Population of focus: </b>children and youth receiving mental health treatment.
            </>,
            <>
                <b>Multi-dimensional Family Therapy (MDFT) </b> is an evidence-based, family focused treatment intervention for adolescent substance use, delinquency, and other behavioral and emotional problems. Treatment addresses needs is the following four areas: the adolescent, parent, family, and community. Therapy sessions are held alone with the youth, alone with the parents, and with youth and parents together. Therapy focuses on facilitating behavioral and interactional change. The final stage of this approach is focused on solidifying behavioral and relational changes so that treatment gains are sustained over time. For more information, please visit: <a href='https://www.mdft.org/'>https://www.mdft.org/</a> <br />
                <b>Population of Focus</b> Youth age 11 to 18 with or at risk of substance use; delinquency/conduct disorder; school and other behavioral problems, and both internalizing and externalizing symptoms.
            </>,
            <>
                <b>Multi-systemic Therapy (MST)</b> is an evidenced based intensive treatment process that focuses on diagnosed behavioral health concerns and environmental systems (family, school, peer groups, culture, neighborhood and community) that contribute to, or influence an individual’s involvement, or potential involvement in the juvenile justice system. MST uses family strengths to promote positive coping activities, works with the caregivers to reinforce positive behaviors, and reduce negative behavior, and helps the family increase accountability and problem solving. Treatment is used at the onset of behaviors that could result in (or have resulted in) criminal involvement by treating the individual within the environment that has formed the basis of the problem behavior. For more information, please visit: <a href='https://www.mstservices.com/ '>https://www.mstservices.com/ </a><br />
                <b>Population of focus: </b>Youth 12-17 who are at risk of out-of-home placement, or who are returning home from an out of home placement, however youth of other ages can be receive the service if medically necessary.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Evidence-based and Best Practices Parent/Family and Youth Support Practices"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [
            <>
                <b>Attachment and Bio-behavioral Catch-up (ABC)</b> is a parent/child treatment approach designed to help caregivers provide nurturing care and engage in synchronous interactions with their infants. ABC helps caregivers re-interpret children's behavioral signals so that they can provide nurturance through parent coaching sessions. It is a home-visiting parenting program that helps parents nurture and respond sensitively to their infants and toddlers in order to foster their development and for strong and healthy attachments. For more information, please visit <a href='https://www.abcintervention.org/'>https://www.abcintervention.org/</a> <br />
                <b>Population of Focus:</b> ABC was developed primarily for use with low-income African-American, Hispanic, and non-Hispanic White families who have experienced neglect, physical abuse, domestic violence, and placement instability. Children age birth – 24 months; both males and females; in low-income families and who have experienced neglect, abuse, domestic violence, placement instability.
            </>,
            <>
                <b>Family and Youth Cafés </b> are a method of facilitating meaningful, reflective conversations that promote leadership and collaboration. These gatherings, derived from the World Café model, bring community members together for a series of structured conversations that directly or indirectly address protective factors. Through participation in cafés, parents/caregivers or youth build leadership skills and relationships as well as the protective factors that help to strengthen their families. Family and Youth Cafés are supported through Strengthening Families and Youth Thrive. For more information, please visit  <a href='https://cssp.org/wp-content/uploads/2018/08/CAFE-OVERVIEW-2015.pdf'>https://cssp.org/wp-content/uploads/2018/08/CAFE-OVERVIEW-2015.pdf</a><br />
                <b>Population of focus: </b>Family Cafés, parents and caregivers; Youth Cafés, adolescents.
            </>,
            <>
                <b>Nurturing Parenting Programs </b>focuses on family -centered trauma initiatives designed to build nurturing parenting skills as an alternative to abusive and neglecting parenting and child-rearing practices. The long term goals are to prevent recidivism in families receiving social services, lower the rate of multi-parent teenage pregnancies, reduce the rate of juvenile delinquency and alcohol abuse, and stop the intergenerational cycle of child abuse by teaching positive parenting behaviors. NPP has several models created to support subpopulations of families (i.e., military families; prenatal families; young parents; families in substance use treatment and recovery, and others).
                For more information visit <a href='https://www.nurturingparenting.com/about.html'>https://www.nurturingparenting.com/about.html</a><br />
                <b>Population of focus: </b>Parents and their children
            </>,
            <>
                <b>Peer Support</b> encompasses a range of activities and interactions between people who share similar experiences of being diagnosed with mental health conditions, substance use disorders, or both. This mutuality between a peer support specialist and person in or seeking recovery promotes connection and inspires hope. Peer support services promote:<br />
                <ul>
                    <li>Engagement</li>
                    <li>Socialization</li>
                    <li>Wellness</li>
                    <li>Self-advocacy; and</li>
                    <li>Preservation & enhancement of community living skills</li>
                </ul>
                <b>Peer Support Specialists </b> are individuals who have lived experience of recovery from a mental health, substance use, or co-occurring MH/SU disorder and who have completed Peer Support certification training. They provide non-clinical, strengths-based support.
                Two types of Peer Support Specialists are available to work with children, youth, and families.
            </>,
            <>
                <b>Youth Peer Support Specialist (YPSS): </b>a person who is between 18 and 36 years old, received services for a behavioral health disorder prior to age 21, and has completed YPSS certification training. A YPSS works directly with youth up to age 26 to support them in navigating the system of care.<br />
                <b>Family Peer Support Specialist (FPSS): </b>a parent/caregiver with permanent legal custody of a child/youth who received services for a behavioral health condition prior to age 21 and who experienced navigating the receipt of these services and supports on behalf of their child/youth. A FPSS provides support to the parent/caregiver of the youth receiving services, and the family as whole, in navigating the system of care. <br />
                For more information, please visit: <br />

                <a href='https://www.samhsa.gov/brss-tacs/recovery-support-tools/peers'>https://www.samhsa.gov/brss-tacs/recovery-support-tools/peers</a><br />
                <a href='https://www.samhsa.gov/sites/default/files/programs_campaigns/brss_tacs/peer-support-2017.pdf '>https://www.samhsa.gov/sites/default/files/programs_campaigns/brss_tacs/peer-support-2017.pdf </a><br />
                <a href='https://youthmovenational.org/youth-peer-support/'>https://youthmovenational.org/youth-peer-support/ </a><br />
                <a href='https://www.fredla.org/parent-to-parent-support-resources/ '>https://www.fredla.org/parent-to-parent-support-resources/ </a>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Evidence-based and Best Practices for Youth That Address Substance Use and/or Co-occurring Mental Health and Substance Use"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            <>
                <b>Adolescent Community Reinforcement Approach (A-CRA) </b> is a developmentally appropriate behavioral treatment and seeks to increase the family, social, and educational/vocational reinforces to support recovery.  More information can be found here <a href='https://www.chestnut.org/ebtx/treatments-and-research/treatments/a-cra/.'>https://www.chestnut.org/ebtx/treatments-and-research/treatments/a-cra/.</a> <br />
                <b>Population of focus: </b>This intervention has been implemented with youth and young adults 12 – 24 in outpatient, intensive outpatient, and residential treatment settings.
            </>,
            <>
                <b>Cannabis Youth Treatment (CYT)</b> is the umbrella term of five volumes of manuals focusing on different treatment modalities including cognitive behavioral therapy, motivational enhancement therapy, multidimensional family, etc to engage young people and their families to cease cannabis use and can be found here <a href='https://www.chestnut.org/store/9/Cannabis-Youth-Treatment-CYT-Manuals/store/.'>https://www.chestnut.org/store/9/Cannabis-Youth-Treatment-CYT-Manuals/store/. </a><br />
                <b>Population of focus:</b> This intervention has been implemented with youth and young adults 12 – 24 in outpatient, intensive outpatient, and residential treatment settings
            </>,
            <>
                <b>CRAFFT 2.1+N </b>N is a health screening tool designed to identify substance use (including nicotine), substance-related risk and substance use disorder.  It is free to use and requires no training to administer, all versions here <a href='https://crafft.org/.'>https://crafft.org/.</a><br />
                <b>Population of focus: </b>Youth 12 – 21 in all settings
            </>,
            <>
                <b>Global Appraisal of Individual Need (GAIN) </b>is a series of measures designed to measure how recent, how often and consumption of substance use, physical health, risk/protective involvement, mental health, environment and vocational situation.  There are several versions of the GAIN, including the Initial (GAIN-I), Short Screener (GAIN-SS), Quick (GAIN-Q3), and can be found here  <a href='https://gaincc.org/instruments/. '>https://gaincc.org/instruments/. </a><br />
                <b>Population of focus: </b>11+ and can be used in outpatient, intensive outpatient, probation and employee/student assistance settings at intake and follow for both adolescents and adults; for adolescents specifically, it can be used in short term residential, therapeutic community and residential aftercare programs for adolescents.  In adults it can also be used in drug court program, methadone, short-term residential, long-term residential, Treatment Alternatives to Street Crimes (TASC) criminal justice residential program, pregnant/postpartum women’s program, dual diagnosis, and homeless subgroups for adults.
            </>,
            <>
                <b>Matrix Model </b> Model is an integrative treatment model meant to address Stimulant Use Disorder through a combination of evidence-based therapies.  People who participate in Matrix therapy participate in an intensive outpatient treatment program for 16 weeks, where they are provided different evidence-based therapies in a highly structured environment.  Modalities include cognitive behavioral therapy, contingency management, 12 Step facilitation, motivational interviewing and family therapy.  More information can be found here <a href='https://www.hazelden.org/web/public/matrix.page.'>https://www.hazelden.org/web/public/matrix.page.</a>  <br />
                <b>Population of focus: </b>Individuals with a Stimulant Use Disorder including methamphetamine and cocaine and their family members.  There are additional modifications for teens and young adults as well as for those in the criminal justice system.
            </>,
            <>
                <b>Seven Challenges</b> is designed to motivate a decision and commitment to change to support success in implementing the desired changes in youth and young adults with co-occurring mental and health and substance use, trauma and family issues. <a href='www.sevenchallenges.com.'>www.sevenchallenges.com.</a><br />
                <b>Population of focus: </b> Adolescents and young adults 13 – 25 and is effective within racially, ethnically and culturally diverse group of youth, both male and female including outpatient, intensive outpatient, residential, day treatment and home-based. It is also used in public and private schools, drug courts, juvenile justice settings and wilderness/experiential programs, and can be used in both individual and group settings.  The Seven Challenges can also be used with adults in individual and group settings.
            </>,
            <>
                <b>SMART Recovery </b> stands for Self-Management and Recovery Training and works as recovery peer support through self-empowerment, and working on underlying issues related to substance use.  For more information, go to <a href='https://www.smartrecovery.org/.'>https://www.smartrecovery.org/.</a><br />
                <b>Population of focus: </b> Local chapters are open to individuals, typically under 30, and in recovery from substance use disorders, though older adults are welcome.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Evidence-based and Best Practices for Transition Age Youth and Young Adults"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 140,
        content: [
            <>
                <b>Applied Suicide Intervention Skills (ASIST) </b>is a 2-day training program that teaches participants how to assist those at risk for suicide. Although many healthcare professionals use ASIST, anyone 16 years or older can use the approach, regardless of professional background: <a href='https://www.livingworks.net/asist'>https://www.livingworks.net/asist</a>
            </>,
            <>
                <b>Assessing and Managing Suicide Risk (AMSR)</b> Training teaches clinicians and other healthcare professionals who conduct suicide risk assessments how to determine the level of suicide risk for an individual: <a href='https://solutions.edc.org/solutions/zero-suicide-institute/amsr/amsr-services/amsr-training'>https://solutions.edc.org/solutions/zero-suicide-institute/amsr/amsr-services/amsr-training</a>
            </>,
            <>
                <b>Double Trouble in Recovery (DTR) </b>is a mutual-aid, self-help program for adults who have been diagnosed with both a mental illness and a substance use disorder. In a mutual-aid program, people help each other address a common problem, usually in a group led by consumer facilitators rather than by professional treatment or service providers. DTR is adapted from the 12 Steps of Alcoholics Anonymous; however, DTR groups are structured with the intent of creating an environment in which people with an active addiction and psychiatric diagnosis can identify with other members and explore their dual recovery needs: <a href='http://dtrky.org/ '>http://dtrky.org/ </a>
            </>,
            <>
                <b>Pathways Transition Training Modules (Pathways Research and Training Center) </b>is a set of 10 online training modules to provide additional education to providers who work with youth and young adults focusing on specific youth/young adult issues and solutions to engaging youth/young adults more effectively <a href='https://www.pathwaysrtc.pdx.edu/pathways-transition-training-modules '>https://www.pathwaysrtc.pdx.edu/pathways-transition-training-modules </a>
            </>,
            <>
                <b>Screening, Brief Intervention, and Referral to Treatment (SBIRT) </b>is a comprehensive, integrated, public health approach to the delivery of early intervention and treatment services for people with substance use disorders, and those at-risk for developing them: <a href='https://sbirt.webs.com/webinars '>https://sbirt.webs.com/webinars </a>
            </>,
            <>
                <b>Teen Mental Health First Aid (TMHFA) </b> teaches teens in grades 10-12, or ages 15-18, how to identify, understand and respond to signs of mental health and substance use challenges among their friends and peers: <a href='https://www.mentalhealthfirstaid.org/population-focused-modules/teens/ '>https://www.mentalhealthfirstaid.org/population-focused-modules/teens/ </a>
            </>,
            <>
                <b>Youth Mental Health First Aid (YMHFA) </b>is designed to teach parents, family members, caregivers, teachers, school staff, peers, neighbors, health and human services workers, and other caring citizens how to help an adolescent (age 12-18) who is experiencing a mental health or addictions challenge or is in crisis. Youth Mental Health First Aid is primarily designed for adults who regularly interact with young people: <a href='https://www.mentalhealthfirstaid.org/population-focused-modules/youth/'>https://www.mentalhealthfirstaid.org/population-focused-modules/youth/</a>
            </>
            
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 150,
        content: "Additional Resources for Evidence-based Practices for Children, Youth, and Families:"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 160,
        content: [
            <>
                <a href='https://youth.gov/evidence-innovation/selecting-programs '>https://youth.gov/evidence-innovation/selecting-programs </a><br />
                <a href='https://www.blueprintsprograms.org/program-search/'>https://www.blueprintsprograms.org/program-search/</a><br />
                <a href='https://archive.cdc.gov/www_cdc_gov/healthyyouth/adolescenthealth/registries.htm'>https://archive.cdc.gov/www_cdc_gov/healthyyouth/adolescenthealth/registries.htm </a><br />
                <a href='https://cssp.org/our-work/project/strengthening-families/ '>https://cssp.org/our-work/project/strengthening-families/ </a><br />
                <a href='https://www.zerotothree.org/ '>https://www.zerotothree.org/ </a><br />
                <br />

                Additional trauma-based informed can be found here:<br />
                <a href='https://www.nctsn.org/treatments-and-practices '>https://www.nctsn.org/treatments-and-practices </a><br />
                <br />
                Additional practices for children and families (including but not limited to those who have involvement with the child welfare system) can be found here: <br />
                <a href='https://preventionservices.acf.hhs.gov/program'>https://preventionservices.acf.hhs.gov/program</a><br />
                <a href='https://www.childwelfare.gov/topics/management/practice-improvement/evidence/registries-resources/registries/child-youth-families/ '>https://www.childwelfare.gov/topics/management/practice-improvement/evidence/registries-resources/registries/child-youth-families/ </a><br />
                <a href='https://www.aecf.org/work/evidence-based-practice '>https://www.aecf.org/work/evidence-based-practice </a><br />
                <br />
                Additional practices for children and youth, including but not limited to those with justice involvement:<br />
                <a href='https://jjie.org/jjie-hub/evidence-based-practices/ '>https://jjie.org/jjie-hub/evidence-based-practices/ </a><br />
                <a href='https://ojjdp.ojp.gov/model-programs-guide/home '>https://ojjdp.ojp.gov/model-programs-guide/home</a>
            </>
        ]
    }
];

export default eBPCYF_Content;