import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";



const pPP_Content = [
    {
        format: bodyHelperTypes.title,
        order: 1,
        content: "About Us"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 10,
        content: "The Mental Health Promotion, Prevention and Preparedness Branch works closely with Community Mental Health Centers (CMHCs), Regional Prevention Centers (RPCs) as well as local and state partners."
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "What We Do"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
            <br/>
            <Link className="quick-links-body" to="/mh/mhp">Mental Health Promotion</Link></>,
            "The goal for the Mental Health Promotion program is to build resilience and life skills and promote connectedness to support mental health wellness of all Kentuckians.",
            <><Link className="quick-links-body" to="https://www.chfs.ky.gov/agencies/dbhdid/Pages/Problem-Gambling-Program.aspx">Problem Gambling</Link></>,
            "The problem gambling program educates Kentuckians on safe gambling practices, the risks of developing a gambling problem, risk factors and warning signs of gambling problems, and available services to reduce the consequences of problem gambling.",
            <><Link className="quick-links-body" to="/mh/sp">Suicide Prevention</Link></>,
            "Kentucky’s coordinated and integrated suicide prevention, intervention, treatment and postvention services utilize best practices from local, state, national, and international subject matter experts. Throughout the state, Kentuckians work together to promote access to suicide prevention resources in communities, following diversity, equity, and inclusion principles. ",
            <><Link className="quick-links-body" to="https://988lifeline.org/">988 Suicide and Crisis Hotline</Link></>,
            <>988 serves as a universal entry point into mental health services for anyone, anywhere in the United States. 988 offers options for accessing trained crisis counselors 24/7 by calling or texting 988 or by chat at <a href="https://988lifeline.org/chat/">https://988lifeline.org/chat/</a>. People can call, chat, or text if they are experiencing a crisis or are concerned about a loved one.</>,
            <><Link className="quick-links-body" to="/crisis">Crisis Intervention</Link></>,
            "Kentucky Crisis Prevention and Response System",
            "Kentucky’s community mental health centers provide a continuum of crisis services for individuals experiencing a mental health or substance use crisis. Services are provided in various settings and via telehealth. They are available 24 hours a day for all individuals, regardless of payer source or ability to pay. Crisis services prevent future issues, stabilize individuals in distress, connect them with the least restrictive, most appropriate acute treatment services available when needed, and coordinate ongoing services and supports in the community.",
            <><Link className="quick-links-body" to="/crisis">Disaster Preparedness and Response </Link></>,
            "The Disaster Preparedness and Response program helps communities prepare for and respond to behavioral health needs during a disaster through planning, infrastructure development, and resilience development of all residents. DBHDID administers the statewide disaster behavioral health plan and coordinates with each of the 14 CMHCs to review and strengthen existing disaster response plans. DBHDID also administers the Kentucky Community Crisis Response Team (KCCRT), a group of highly trained volunteers who provide support, mainly to first responders, after critical incidents (line of duty deaths, multi-casualty incidents, use of deadly force, the suicide of a first responder, events involving children, prolonged incidents, terrorism, and any other overwhelming event.)",
            <Link className="quick-links-body" to="https://kccrt.ky.gov/Pages/index.aspx">The Kentucky Community Crisis Response Team (KCCRT)</Link>,
            "The KCCRT equips first responders, disaster survivors, and communities in kentucky to cope with critical incidents and traumatic events while building resilience. Our statewide team of volunteers, including first responders, chaplains, mental health professionals, and trained community members, deploy upon request to provide immediate peer support. We are dedicated to fostering recovery and resilience, ensuring that all kentuckians are safe, strong, and well-supported in the face of adversity."
        ],

    },








];

export default pPP_Content;