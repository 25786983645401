import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const hFW_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "What is Wraparound?"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The National Wraparound Initiative (see Related Links) tells us that in recent years, Wraparound has been most commonly conceived of as an intensive, individualized care planning and management process.",
            "Wraparound is not a treatment per se. It is a process to achieve positive outcomes for children and youth who are experiencing behavioral health concerns. The process provides structured, creative, and individualized team planning. Compared to traditional treatment planning, it results in plans that are more effective for and relevant to the child/youth and family."
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Wraparound Teams"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "Wraparound teams are unique to each child/youth and family, consist of people who are closest to the family, and should include both service providers and natural supports. The child/youth and family are integral members of the Wraparound team and should take the lead in selecting other team members.",
            "High Fidelity Wraparound (HFW) plans include traditional services provided by multiple child-serving agencies (e.g., child welfare, behavioral health, juvenile justice, special education), as well as informal and natural supports that are available or developed in the community (e.g., county extension office, youth sports league, mentoring program, extended family, faith community)."
           
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "How Wraparound Works"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: "Wraparound operationalizes the system of care approach (see Related Links) at the individual child/youth and family level. Research demonstrates that Wraparound implemented with high fidelity to the model leads to the best outcomes for families with children and youth who have behavioral health (mental health, substance use, or co-occurring mental health and substance use) challenges."

    },
    {
        format: bodyHelperTypes.title,
        order: 61,
        content: "A Brief History"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            "Wraparound was developed in the 1980s as a process to serve children/youth who were at high risk for being placed outside of their homes and communities as a result of their complex behavioral health needs. The team-based approach has been successful in keeping children/youth at home, in their communities, and out of residential facilities.",
            "In the late 1990s a group of national Wraparound experts and researchers created clearly defined values, elements, and expectations for HFW to support consistent implementation across programs and states. This also made it easier to study the Wraparound process and outcomes for children/youth and families."
        ]

    },
    // {
    //     format: bodyHelperTypes.title,
    //     order: 80,
    //     content: "Ten Principles of the Wraparound Process"

    // },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "One outcome of this work was the identification of “Ten Principles of the Wraparound Process” (see Related Links). National leaders agree that for Wraparound to be implemented with high fidelity, the process must adhere to the following principles:",
            <>
                <ol>
                    <li>Family voice and choice</li>
                    <li>Team based</li>
                    <li>Natural supports</li>
                    <li>Collaboration</li>
                    <li>Community based</li>
                    <li>Culturally competent</li>
                    <li>Individualized</li>
                    <li>Strengths based</li>
                    <li>Unconditional</li>
                    <li>Outcome based</li>
                </ol>
            </>,
            // "Research since then has shown that Wraparound implemented with high fidelity to the model leads to the best outcomes for families with children and youth who have behavioral health challenges",
            "In January 2016, the Community Mental Health Centers (CMHCs) began providing HFW through the Kentucky IMPACT program (see Related Links).",
            "Department for Behavioral Health, Developmental and Intellectual Disabilities staff work with the National Wraparound Implementation Center to support HFW under Kentucky IMPACT within the CMHCs. HFW Facilitators and HFW Supervisors participate in a statewide HFW Learning Collaborative that includes ongoing technical assistance, coaching, and fidelity monitoring.",
            "Trained HFW Facilitators work with fewer children/youth than traditional Targeted Case Managers as the families partnering in HFW have more complex needs and require more coordination among formal and informal services and supports. This allows the HFW Facilitators to spend more time with each family, supporting them in meeting their goals and becoming connected with community resources to help them thrive.",
            "Initial eligibility is determined via a multi-step process. Ongoing assessment of child/youth and family functioning across a variety of life domains assists in determining the effectiveness of HFW in reducing behavioral health concerns and increasing connections within the community.",
            // "Information about High Fidelity Wraparound, including more details about the ten principles, can be found in the Resource Guide to Wraparound (see Related Links)."
        ]

    },
    
 



            

           
    


];

export default hFW_Content;