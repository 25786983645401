import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import PESLogo from "../../../assets/img/sud/PESLogo.png"
import Picture from "../../../layouts/bodyLayouts/helpers/Picture";

const ResourcesContent = [
    
    // {
    //     format: bodyHelperTypes.image,
    //     order: 20,
    //     content: PESLogo

        

    // },

    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Parent Resources:"

    },
    {
        format: bodyHelperTypes.links,
        order: 20,
        content: "783"

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Opioid:"

    },
    {
        format: bodyHelperTypes.links,
        order: 40,
        content: "784"

    },
     
                ];

 export default ResourcesContent;