import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const actPrinciplesContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content:[

            <>Assertive Community Treatment services adhere to certain essential standards and the following basic principles.</>,

            <UnorderedList items={[
                <><b>Assertive approach. </b>ACT team members are pro-active with clients, assisting them in participating in and continuing treatment, living independently, and recovering from disability.</>,
                <><b>Attention to health care needs. </b>The ACT team provides health education and access to and coordination of health care services.</>,
                <><b>Community integration. </b>ACT staff help clients to become less socially isolated and more integrated into the community by encouraging participation in community activities and membership in organizations of their choice.</>,
                <><b>Emphasis on vocational expectations. </b>Emphasis on vocational expectations. The team encourages all clients to participate in community employment and provides many vocational rehabilitation services directly.</>,
                <><b>Family support and education. </b>With the active involvement of the client, ACT staff work to include the client's natural support systems (family and significant others) in treatment, educating them and including them as part of the ACT services. It is often necessary to help improve family relationships in order to reduce conflicts and increase client autonomy.</>,
                <><b>Highly individualized services. </b>Treatment plans, developed with the client, are based on individual strengths and needs, hopes and desires. The plans are modified as needed through an ongoing assessment and goal-setting process.</>,
                <><b>Long-term services. </b>ACT services are intended to be long-term due to the severe impairments often associated with serious mental illness. The process of recovery often takes many years.</>,
                <><b>Primary provider of service. </b>The multidisciplinary make-up of each team (psychiatrist, nurses, social workers, rehabilitation, etc.) and the small client-to-staff ratio help the team provide most services with minimal referrals to other mental health programs or providers. The ACT team members share offices, and their roles are interchangeable when providing services, to ensure that services are not disrupted due to staff absence or turnover.</>,
                <><b>Psycho-educational services. </b>Staff work with clients and their family members to become collaborative partners in the treatment process. Clients are taught about mental illness and the skills needed to better manage their illnesses and their lives.</>,
                <><b>Services are provided out of office. </b>Services are provided within community settings, such as a person's own home and neighborhood, local restaurants, parks and nearby stores.</>,
                <><b>Co-occurring services. </b>The team coordinates and provides services for co-occurring disorders. </>
            ]} />

        ] ,
    }
   










];

export default actPrinciplesContent;