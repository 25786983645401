import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const employment_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                <b>Individual Placement and Support (IPs) </b>is a model of supported employment for people with serious mental illness (SMI), such as schizophrenia spectrum disorder, bipolar, depression as well as substance use disorder (SUD) or co-occurring conditions. IPS supported employment helps those living with behavioral health conditions work at regular jobs of their choosing. Although variations of supported employment exist, IPS refers to the evidence-based practice of supported employment.
            </>,
            <>
                <b>Characteristics of IPS Supported Employment: </b>
                <ul>
                    <li>It is an evidence-based practice. </li>
                    <li>Practitioners focus on each person’s strengths.</li>
                    <li>Work promotes recovery and wellness.</li>
                    <li>Practitioners work in collaboration with state vocational rehabilitation counselors. </li>
                    <li>IPS uses a multidisciplinary team approach. </li>
                    <li>Services are individualized and last as long as the person needs and wants them. </li>
                    <li>The IPS approach changes the way mental health services are delivered. </li>
                </ul>
            </>,
            <>
                <b>The 8 Practices and Principles of IPS: </b>

                <ul>
                    <li>Focus on Competitive Integrated Employment</li>
                    <li>Eligibility is based on consumer choice</li>
                    <li>Integration of rehabilitation and mental health services</li>
                    <li>Attention to worker preferences</li>
                    <li>Personalized benefits counseling</li>
                    <li>Rapid job search</li>
                    <li>Systematic job search</li>
                    <li>Time-unlimited individualized support</li>
                </ul>
            </>,
            <>
                Employment is an essential part of recovery for individuals with SMI and/or substance use disorder. In Kentucky, only about 10 percent of adults with SMI receiving services from Community Mental Health Centers were employed in fiscal year 2009. This employment rate has held steady for many years.
            </>,
            <>
                The goal of the Division of Mental Health (DMH) is to develop high-fidelity supported employment services, to increase access to these services for adults with SMI and /or SUD throughout the commonwealth, and to improve the overall employment rate for individuals who want to work.
            </>,
            <>
                DMH along with the Office of Vocational Rehabilitation (OVR) was selected to become a part of the IPS International Learning Collaborative in 2010. A small funding grant was secured and, technical assistance for the implementation of IPS in Kentucky was provided by the IPS Employment Center, formerly  the Johnson & Johnson–Dartmouth Community Mental Health Program.  Each IPS program has individualized IPS trainers to implement IPS and assist them in achieving good fidelity.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Individual Placement and Support Supported Employment (IPS)"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "Using a combination of federal block grant funds, state general funds, and collaborative agreements with the Office of Vocational Rehabilitation, the Department coordinates the evidenced-based Individual Placement and Support Supported Employment (IPS) for consumers in all state regions.",
            "IPS Supported Employment programs play a vital role in offering vocational opportunities to individuals who have been unable to secure jobs due to psychiatric conditions and other factors. The program staff are dedicated to developing jobs that are tailored to the consumer's specific needs, desires, and work availability in the region, thereby maximizing their chances of successful employment.",
            "Once the consumer obtains employment, the IPS Program continues to support the them on the job based on their individualized needs. Consumers may work full or part-time, at minimum wage or above."
        ]
    }

];

export default employment_Content;