import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLQASubmit_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Submit an SCL Question"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                On this page you can submit questions about SCL. Your questions will be reviewed and answered by people who work with the SCL program.
                The answers to your questions will be provided on the "SCL Questions and Answers" page (see Related Links).
            </>,
            <>
                First, select the Question Area (topic) by clicking on it in the list below. If your question does not fit into one of the question areas on the list,
                select "Other" and fill in the "Other" textbox with your topic area. <b> Hover over a question area in the list to see its full text.</b>
            </>,
            <>
                Finally, complete the rest of the form and click the "Submit Question" button when you are ready to submit your question.
            </>,
            <>
                <b>Question Area</b>
                <p>Work in Progress</p>
            </>
        ]
    },
];

export default sCLQASubmit_Content;

