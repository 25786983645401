import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import LinkNoNewPage from "../../../components/LinkNoNewPage";
import DDIDCountyList from "../../../components/DDIDCountyList";

const accessAndReferral_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Are you looking for services for someone with a Developmental or Intellectual Disability (D/ID)? Have you reached out to your Community Mental Health Center (CMHC) about Access and Referral services?",
            <><b>Access and Referral (A&R)</b> is a short-term service designed to identify D/ID related support needs and connect to related services. May include gathering and review of records to identify eligibility for services by establishing the presence of Intellectual or other qualifying Developmental Disability, for determination of financial eligibility for services, and application for waiver services. Those individuals who do not meet criteria for D/ID services shall be referred to other services. This service shall not be provided concurrent with case management services. Individuals who do qualify may also be eligible for other state general funds services such as the ones listed below. </>,

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Which county do you reside in?"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: <><DDIDCountyList /></>
    }
    
];

export default accessAndReferral_Content;