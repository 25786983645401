import { jsPDF } from 'jspdf';

const GeneratePDF = ({ results, className }) => {
    const generatePDF = () => {
        const doc = new jsPDF('landscape');
        let y = 10;
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const midPoint = pageWidth / 2;
        const lineHeight = 5;
        const padding = 10;

        doc.setFontSize(10);
        doc.text('Provider Records', 10, y);
        y += 10;

        results.forEach((provider, index) => {
            // Calculate total lines for the provider's section
            const physicalAddress = provider.addresses.find(address => address.addressType === 'Physical');
            const mailingAddress = provider.addresses.find(address => address.addressType === 'Mailing');
            const physicalAddressLines = physicalAddress ? [
                physicalAddress.address1,
                physicalAddress.address2,
                physicalAddress.address3,
                physicalAddress.address4,
                `${physicalAddress.city ? physicalAddress.city + ',' : ''} ${physicalAddress.state ? physicalAddress.state : ''} ${physicalAddress.zip ? physicalAddress.zip : ''}`
            ].filter(line => line && line.trim()).length : 0;
            const mailingAddressLines = mailingAddress ? [
                mailingAddress.address1,
                mailingAddress.address2,
                mailingAddress.address3,
                mailingAddress.address4,
                `${mailingAddress.city ? mailingAddress.city + ',' : ''} ${mailingAddress.state ? mailingAddress.state : ''} ${mailingAddress.zip ? mailingAddress.zip : ''}`
            ].filter(line => line && line.trim()).length : 0;
            const contactInfoLines = [
                provider.phone && `Contact Number: ${provider.phone}`,
                provider.altPhone && `Alt Phone: ${provider.altPhone}`,
                provider.crisisLine && `Crisis Number: ${provider.crisisLine}`,
                provider.tollFreePhone && `Toll-Free Number: ${provider.tollFreePhone}`,
                provider.fax && `Fax: ${provider.fax}`,
                provider.altFax && `Alt Fax: ${provider.altFax}`,
                provider.email && `Email: ${provider.email}`,
                provider.email2 && `Alt Email: ${provider.email2}`,
                provider.web && `Website: ${provider.web}`,
                provider.contact1 && `Contact Person: ${provider.contact1}`,
                provider.contact2 && `Additional Contact: ${provider.contact2}`,
                provider.contact3 && `Additional Contact: ${provider.contact3}`,
                provider.contact4 && `Additional Contact: ${provider.contact4}`
            ].filter(line => line).length;

            const servicesList = provider.providerServices.map(service => service.serviceDesc);
            const totalLines = physicalAddressLines + mailingAddressLines + contactInfoLines + servicesList.length + 8; // 8 for the fixed lines

            const isLargeEntry = servicesList.length > 14;
            if (isLargeEntry) {
                doc.addPage();
                y = 10;
            } else if (y + totalLines * lineHeight > pageHeight - 20) {
                doc.addPage();
                y = 10;
            }

            // Draw borders
            const sectionHeight = isLargeEntry ? pageHeight - 20 : 80;
            doc.line(padding, y, pageWidth - padding, y); // Top horizontal line
            doc.line(padding, y, padding, y + sectionHeight); // Left vertical line
            doc.line(midPoint, y, midPoint, y + sectionHeight); // Middle vertical line
            doc.line(pageWidth - padding, y, pageWidth - padding, y + sectionHeight); // Right vertical line
            doc.line(padding, y + sectionHeight, pageWidth - padding, y + sectionHeight); // Bottom horizontal line

            let contentY = y + 5;

            // Provider Description and Physical Address
            doc.setFont(undefined, 'bold');
            doc.text(provider.providerDesc, padding + 5, contentY);
            doc.setFont(undefined, 'normal');
            contentY += lineHeight;

            if (physicalAddress) {
                const addressLines = [
                    physicalAddress.address1,
                    physicalAddress.address2,
                    physicalAddress.address3,
                    physicalAddress.address4,
                    `${physicalAddress.city ? physicalAddress.city + ',' : ''} ${physicalAddress.state ? physicalAddress.state : ''} ${physicalAddress.zip ? physicalAddress.zip : ''}`
                ].filter(line => line && line.trim());

                addressLines.forEach((line, idx) => {
                    doc.text(line, padding + 5, contentY);
                    contentY += lineHeight;
                });
            }

            // Mailing Address
            if (mailingAddress) {
                doc.setFont(undefined, 'bold');
                doc.text('Mailing Address:', padding + 5, contentY);
                doc.setFont(undefined, 'normal');
                contentY += lineHeight;

                const mailingAddressLines = [
                    mailingAddress.address1,
                    mailingAddress.address2,
                    mailingAddress.address3,
                    mailingAddress.address4,
                    `${mailingAddress.city ? mailingAddress.city + ',' : ''} ${mailingAddress.state ? mailingAddress.state : ''} ${mailingAddress.zip ? mailingAddress.zip : ''}`
                ].filter(line => line && line.trim());

                mailingAddressLines.forEach((line, idx) => {
                    doc.text(line, padding + 5, contentY);
                    contentY += lineHeight;
                });
            }

            // Contact Information
            doc.setFont(undefined, 'bold');
            doc.text('Contact Information:', padding + 5, contentY);
            doc.setFont(undefined, 'normal');
            contentY += lineHeight;

            const contactInfoLinesText = [
                provider.phone && `Contact Number: ${provider.phone}`,
                provider.altPhone && `Alt Phone: ${provider.altPhone}`,
                provider.crisisLine && `Crisis Number: ${provider.crisisLine}`,
                provider.tollFreePhone && `Toll-Free Number: ${provider.tollFreePhone}`,
                provider.fax && `Fax: ${provider.fax}`,
                provider.altFax && `Alt Fax: ${provider.altFax}`,
                provider.email && `Email: ${provider.email}`,
                provider.email2 && `Alt Email: ${provider.email2}`,
                provider.web && `Website: ${provider.web}`,
                provider.contact1 && `Contact Person: ${provider.contact1}`,
                provider.contact2 && `Additional Contact: ${provider.contact2}`,
                provider.contact3 && `Additional Contact: ${provider.contact3}`,
                provider.contact4 && `Additional Contact: ${provider.contact4}`
            ].filter(line => line);

            contactInfoLinesText.forEach((line, idx) => {
                doc.text(line, padding + 5, contentY);
                contentY += lineHeight;
            });

            // Services
            let servicesY = y + 5;
            doc.setFont(undefined, 'bold');
            doc.text('Services Available:', midPoint + 5, servicesY);
            doc.setFont(undefined, 'normal');
            servicesY += lineHeight;

            servicesList.forEach((service, idx) => {
                doc.text(service, midPoint + 5, servicesY);
                servicesY += lineHeight;
            });

            y += isLargeEntry ? pageHeight - y : 90; // Increment y for the next entry, ensuring large entries take up the whole page

            // Ensure there are 2 records per page
            if (!isLargeEntry && (index + 1) % 2 === 0) {
                doc.addPage();
                y = 10;
            }
        });

        doc.save('provider-records.pdf');
    };

    return (
        <button className={className} onClick={generatePDF}>Printer-friendly Version</button>
    );
};

export default GeneratePDF;
