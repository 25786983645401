import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const tCMChild_Content = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Through state general revenue and Medicaid funding, Targeted Case Management (TCM) services are available to children/youth with a serious emotional disability in all 120 Kentucky counties. Within Kentucky’s community mental health centers (CMHCs), these services are offered under the auspices of Kentucky IMPACT (see Related Links). Targeted Case Management is also offered by other providers across the state.",
            "Within Kentucky IMPACT, TCM services for children/youth are referred to as Service Coordination, and Targeted Case Managers are referred to as Service Coordinators. A primary goal of Kentucky IMPACT is to reduce the utilization of inpatient mental health hospital days and other out-of-home placements. Due to the intensity of services, each Service Coordinator is limited to serving 25 children/youth at a time. Service Coordination under the Kentucky IMPACT model is based on the Wraparound process, a strengths-based, youth- and family-driven and individualized plan of care for children/youth with serious emotional disability.",
            "In addition to traditional Targeted Case Management (Service Coordination), Kentucky IMPACT also provides High Fidelity Wraparound (HFW)(see Related Links). HFW is intensive care coordination and is provided by HFW Facilitators who are limited to serving ten families at a time.",
            "Kentucky IMPACT can access limited flexible funds to purchase necessary goods and/or services for children/youth that are served by the program and for which no other resources are available to access the goods and/or services.",
            "All Kentucky IMPACT Service Coordinators and HFW Facilitators and Supervisors must attend and successfully complete the twelve-hour core components of the Targeted Case Management curriculum, as well as the six-hour specialized curriculum for children with a serious emotional disability (SED), as required by 908 KAR 2:260 (see Related Links).",
            "HFW Facilitators and their supervisors must participate in additional training and ongoing coaching.",
            "For more information about Targeted Case Management services for children/youth, and about HFW and Kentucky IMPACT, please visit the relevant sections of this website through Related Links, or use the information in the Contact Information box above."
        ]
    },
    






];

export default tCMChild_Content;