import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const adolescentContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "According to the World Health Organization, adolescence is the phase of life between childhood and adulthood, from ages 10 to 19. It is a unique stage of human development and an important time for laying the foundations of good health.  Adolescents experience rapid physical, cognitive and psychosocial growth. This affects how they feel, think, make decisions, and interact with the world around them. ",
            "The Division of Mental Health is committed to addressing the systemic needs of youth and young adults aged 16 – 25 with behavioral health and/or substance use issues by working to ensure they have access to services that are more inclusive, person-centered, and relative to their age and experiences. ",
            "In light of the unique mental health and substance use needs of young people ranging from 10 – 25, adolescence is a tumultuous time as youth attempt to define themselves and become more independent. Most youth progress through the various milestones with little to no difficulty; however, for some, adolescence is the period during which behavioral and emotional difficulties emerge or are exacerbated.",
            "In fact, a recent study estimated that 8 percent of adolescents have a serious emotional disturbance, and 10 percent report having used an illicit drug in the past month.",
            "The University of Kentucky Center on Drug and Alcohol Research states that the neurodevelopment of the brain renders the adolescent brain more vulnerable to addiction than the adult brain, therefore making the effects of substance use more damaging to adolescents' brains than to adults' brains in many ways, and in some cases may have long-lasting effects. Early and effective treatment for substance abuse among adolescents is a high-priority public health problem."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Resources for Providers"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: <>

            <ul className="no-bullet">

                <li> <a className="quick-links-body" href="https://store.samhsa.gov/product/Identifying-Mental-Health-and-Substance-Use-Problems-of-Children-and-Adolescents-A-Guide-for-Child-Serving-Organizations/SMA12-4700">
                    SAMHSA: "Identifying Mental Health and Substance Use Problems of Children and Adolescents: A Guide for Child-Serving Organizations"
                </a>
                </li>

                <li>

                    <a className="quick-links-body" href="https://store.samhsa.gov/product/TIP-31-Screening-and-Assessing-Adolescents-for-Substance-Use-Disorders/SMA12-4079">

                        SAMHSA: "Screening and Assessing Adolescents for Substance Use Disorders"

                    </a>

                </li>

                <li>
                    <a className="quick-links-body" href="chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://nasadad.org/wp-content/uploads/2014/09/State-Adolescent-Substance-Use-Disorder-Treatment-and-Recovery-Practice-Guide-9-24-14.pdf">
                        The National Association of State Alcohol and Drug Abuse Directors, Inc: "State Adolescent Substance Use Disorder Treatment and Recovery Practice Guide"
                    </a>

                </li>




            </ul>





        </>






    }


];

export default adolescentContent;