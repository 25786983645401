import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const sSPW_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            "Kentucky’s Prevention and Promotion Branch is committed to building a strong and competent prevention workforce that can effectively address the complex and interconnected issues of substance use/misuse prevention. We believe that prevention programs must be designed with these characteristics in mind and that the success of program implementation relies on a competent, well-trained, ethical, and professional workforce.",
            "Prevention specialists are at the forefront of this effort, working closely with communities across Kentucky to deliver prevention services. We are proud to support their professional development through the Certified Prevention Specialist (CPS) credential, which ensures that prevention specialists have a thorough understanding of the latest evidence-based practices.",
            "To become a Certified Prevention Specialist through the Kentucky Board of Prevention Professionals (a member of the International Certification and Reciprocity Consortium), applicants must demonstrate their competency through experience, education, and supervision and pass a rigorous examination. In Kentucky, individuals who are hired to deliver prevention services and receive state or federal funding must complete the CPS credential requirements within three years of their hire date.",
            <>
                To support Kentucky prevention specialists in their professional development, here are a few examples of trainings/resources we share with the workforce:
                <ul>
                    <li>Kentucky PreventionU (online training system)</li>
                    <li>KSUPS – Kentucky Substance Use Prevention Skills training</li>
                    <li>CPS Study Guides <br />
                        <a href='https://www.internationalcredentialing.org/examprep'>Exam Prep</a><br />
                        <a href='https://www.riprc.org/resources/rhode-island-prevention-specialist-certification-study-guide-for-the-certification-exam/'>Study Guide</a>
                    </li>
                    <li><a href='https://www.chfs.ky.gov/agencies/dbhdid/Pages/KYSchoolofAlcoholandOtherDrugs.aspx'>Kentucky School of Alcohol and Other Drug Studies </a></li>
                    <li><a href='https://pttcnetwork.org/'>Prevention Technology Transfer Center Network</a></li>
                </ul>,
                <>
                    For more information about Kentucky’s credentialing process please visit this site: <a href='https://kyprevention.org/'>https://kyprevention.org/</a>
                    
                </>

            </>
        ]
    },
];

export default sSPW_Content;