import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const sOCA_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Presented by the Department for Behavioral Health, Developmental and Intellectual Disabilities"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>The Department for Behavioral Health, Developmental and Intellectual Disabilities has convened an Annual System of Care (SOC) Academy since 2014. In 2020, the SOC Academy moved from an in-person to a virtual event to reach a broader audience and provide expanded access to valuable content. <i>The SOC Academy is celebrating its 10th anniversary with the theme our System of Care: Past, Present & Future.</i> We are pleased to again offer this learning opportunity virtually. The department is partnering with Mental Health America of Kentucky to host the 10th anniversary event.  </>,
            <>Each year, SOC Academy serves a diverse audience that includes advocates clinicians, case managers, prevention specialists, peer support specialists, educators, faith-based leaders, psychologist/psychiatrists, child care providers, Family Resource and Youth Services Center staff, , juvenile justice staff, family and juvenile services staff, community-based services staff, child welfare staff, public health staff, families and youth with lived experience, interested community members, and more.</>,


            <>To learn more about System of Care core values and guiding principles, see the <b>Evolution of the SOC Approach 2021</b> in Related Links.</>,

            <>Continuing education units will be avaiable. American Sign Language (ASL Interpreters) and Communcation Access Realtime Translation (CART) will be available upon request.</>,

            <>Find 2022-2023 recorded sessions by clicking “SOC Learning Series Recordings ” under Related Links. The Department is unable to offer certificates for viewing the recordings.</>,

            <>To request more information, click on the "Contact Us" link in the Contact Information box.
            <br/>
            </>

        ]
    },
    






];

export default sOCA_Content;