import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";

const hIPPAALawContent = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><UnorderedList items={[
                <><a href="https://www.govinfo.gov/content/pkg/BILLS-107hr3323enr/pdf/BILLS-107hr3323enr.pdf">Administrative Simplification Compliance Act (ASCA), Public Law 107-105.</a>ASCA extended the original implementation date of the Transaction and Code Sets Rule. </>,
                <>
                    <a href="https://www.govinfo.gov/content/pkg/BILLS-104hr3103enr/pdf/BILLS-104hr3103enr.pdf">Health Insurance Portability and Accountability Act of 1996, Public Law 104-191. </a>HIPAA Law amends the Internal Revenue Code of 1986 to improve portability and continuity of health insurance coverage in the group and individual markets, to combat waste, fraud and abuse in health insurance and health care delivery, to promote the use of medical savings accounts, to improve access to long-term care services and coverage, to simplify the administration of health insurance, administrative simplification, and for other purposes. <br/>
                    <br/>
                    Title II provides for administrative simplification that requires the development of standards for the electronic exchange of health care information.

                </>,
                <><a href="https://dbhdid.ky.gov/kdbhdid/hipaa-law.aspx#:~:text=HIPAA%20Title%20II%2C%20Subtitle%20F%3A%20Administrative%20Simplification.">HIPAA Title II, Subtitle F: Administrative Simplification.</a>
                The purpose of this subtitle is to enhance health care insurance and delivery systems by making them more efficient, simpler, and less costly. <br/>
                <br/>
                Administrative Simplification requires that health plans and health care clearinghouses use certain standard transaction formats and code sets for the electronic transmission of health information. Health care providers that transmit any health information in electronic form in connection with a transaction covered in the rules are also required to use the standard transactions and codes sets for the electronic transmission of health information. <br/>
                <br/>

                Administrative Simplification establishes standards for the protection and security of individually identifiable health information, and provides penalties for its wrongful disclosure. Administrative simplification also requires rules to protect the privacy of personal health information and the establishment of security requirements to protect that information, and the development of standard identifiers.
                </>,
               
            ]} /></>
        ]
    },
    
    


];

export default hIPPAALawContent;