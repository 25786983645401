import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const women_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Pregnant individuals that are dependent on substances face tremendous stigma from their family, social networks, and society. According to the National Institute on Drug Abuse, more than 19.5 million women in the U.S. have used illicit substances in the last year and 8.4 million women have misused prescription substances during the past year.",
            "Substance exposed infants and children have also been shown to have significantly higher rates of early mental health and behavioral problems, as well as higher rates of adverse birth outcomes, and require increased health care utilization after discharge.",
            "Opioid-dependent pregnant individuals often do not receive the treatment that they need and require, resulting in potential risks of harm to the baby.",
            "Across Kentucky, a variety of services exist and are provided to women, however they are  often not well coordinated, which may lead to ineffective care delivery. Our goal is to support implementation of coordinated services that can intervene at multiple stages: pre-pregnancy, prenatal, birth, neonatal, and  during childhood development."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Service Initiatives"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "As a collaborative effort, Kentucky is working across many agencies and departments to collect data annually with relation to people of child-bearing age, substance use during pregnancy, substance-effected births and substance-exposed births. ",
            <>The Division is working with the Department for Public Health (DPH) and Department for Community-Based Services (DCBS) to implement an initiative around <a href='https://dbhdidtest.ky.gov/documents/dbh/siac/handouts/20160928.pdf'>plans of safe care</a> for babies identified as being substance-effected or potentially substance-exposed. A critical part of addressing concerns around substance-effected and/or substance-exposed infants assures that the child is able to live and thrive in a safe, stable, and nurturing environment during the early years of development and beyond.  This requires services and support in the community for the family, mother, infant and developing child.</>,
            "In addition, the Kentucky Justice and Public Safety Cabinet, Office of Drug Control Policy (ODCP), in collaboration with the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID), supports annual competitive funding opportunities that aim to decrease incidence of Neonatal Abstinence Syndrome (NAS), advance the delivery of treatment and recovery supports for pregnant and parenting people with opioid use disorder (OUD) and expand support for children and families impacted by substance use disorder (SUD).",
            "Through consistent early identification, appropriate referrals, and utilization of available services including case management, peer and family support services, therapeutic, medical, and other follow-up services, it is expected that both short and long term outcomes for families will improve.",
            "See related links below for additional resources."
        ]

    }
];

export default women_Content;