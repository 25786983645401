import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";


const BHPACAboutContent = [

    // {
    //     format: bodyHelperTypes.image,
    //     order: 20,
    //     content: PESLogo



    // },
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "What is the Council?"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "The council serves as an advisory body to the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID), which is the state mental health authority and single state agency for substance use services.",
            "Federal law (Public Law 102-321) requires that every state receiving mental health block grant funds engage in a planning process with input from stakeholders, including consumers of mental health services, family members, other state agencies and providers. States are encouraged to integrate these councils to include individuals with substance use disorders and co-occurring mental health/substance use disorders. Kentucky has had an integrated council since 2011.",
            "In recent years, Kentucky has received more than $33 million annually in federal mental health and substance use prevention and treatment block grant funds:",
            <><b>1. To Serve: </b></>,
            <>
                <UnorderedList items={[
                    "Adults with serious mental illness.",
                    "Children with serious emotional disturbances and their families.",
                    "Individuals with early serious mental illness/first episode psychosis.",
                    "Individuals in need of behavioral health crisis services.",
                    "Individuals who have, or are at risk of having, substance use disorders."
                ]} /></>,
            <><b>2. To address the need for services among special populations, such as:</b></>,
            <>
                <UnorderedList items={[
                    "Women who are pregnant and have a substance use disorder.",
                    "Parents with substance use disorders who have dependent children.",
                    "Individuals who inject drugs.",
                    "Military personnel and their families",
                    "Persons with substance use disorders who have, or are at risk of contracting, communicable diseases, such as HIV/AIDS/TB."
                ]} /></>,
                <>The council has several standing committees, including:</>,
                <><UnorderedList items={[
                    <><b>Executive Committee: </b>This committee determines council meeting agendas and activities and conducts any council business that requires attention outside of meetings.</>,
                    <><b>Bylaws Committee: </b>This committee reviews the bylaws, monitors them for needed changes, and offers recommendations to the full council for approval or amendment.</>,
                    <><b>Membership Committee: </b>This committee is charged with ensuring that the council follows both public laws and council bylaws regarding of membership, recruiting and orienting new council members, making recommendations for council officers, and making recommendations to the Bylaws Committee regarding membership and nomination of officers.</>,
                    <><b>Finance and Data Committee: </b>This committee is responsible for reviewing block grant allocations and recommending funding issues to the council.</>,
                    
                ]} /></>,
                "The council also creates ad hoc committees at its discretion. There are no ad hoc committees at present."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Who Serves on the Council?"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: "State employee and provider members shall not exceed 49 percent of the total number of members of the Planning Council. Other representatives may include public and private entities concerned with the need and planning for, and the operation, funding and use of, behavioral health services and supports. The ratio of parents of children with serious emotional disturbances (SED) to other members of the council must be sufficient to provide adequate representation of such children in the deliberations of the council."

    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "What are the Council’s duties?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            "The council shall do all of the following:",
            <><UnorderedList items={[
                "Report directly to the Commissioner of DBHDID.",
                "Assist DBHDID in designing a comprehensive, recovery-oriented system of care.",
                "Advise DBHDID on the use of Substance Use Prevention, Treatment and Recovery Services – Block Grant (SUPTRS-BG) funds and Mental Health Block Grant (MHBG) funds and on the quality of statewide, recovery-oriented behavioral health services.",
                "Review the biennial combined SUPTRS-BG and MHBG Application and annual SUPTRS-BG and MHBG Reports pursuant to Public Law 102-321, Section 1915 (a) and submit recommendations to BHDID, prior to the September 1 and December 1 due dates, respectively.",
                "Advocate for with lived experience in mental health/substance use disorders, children and youth with behavioral health challenges, and family members.",
                "Monitor, review and evaluate, no less than once a year, the allocation and quality of statewide, recovery-oriented behavioral health services."
            ]} /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Does the council participate in other behavioral health planning efforts?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            "Yes, the council attempts to stay abreast of national  and state-level activities, legislation, and local efforts of interest to individuals and families affected by mental health and substance use disorders. Consumer and family support networks are represented on the council and often bring information and recommendations to the group.",
           
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "Where can I find more information?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            "Please see “Related Links” on the right side of this page for additional information. ",
            
        ]
    },

];

export default BHPACAboutContent;