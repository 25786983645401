import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const housing_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Supportive Housing"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The department embraces the Permanent Supportive Housing approach of the Substance Abuse and Mental Health Services Administration (SAMHSA), defined as voluntary, flexible supports to help people with serious mental illness to choose, get, and keep housing that is decent, safe, affordable, and integrated into the community.",
            "Central to the approach is a belief that people with serious mental illness should have the right to live in a home of their own, without any special rules or service requirements.",
            <>
                <ol>
                    <li><b>Choice of housing.</b> Providing access to scattered-site housing offers the best opportunity to meet tenants’ expressed choice of location, and for housing that is convenient to transportation, mental health services, family, shopping, and other essentials.</li>
                    <li><b>Functional separation of housing and services.</b> Staff who provide support services such as case management, helping people find housing, and advocating for tenants, should not perform property management functions such as reviewing rental applications, collecting rent, and making eviction and renewal decisions. Separating housing provision from service provision helps to ensure that tenants’ rights under local and state landlord-tenant laws are respected.</li>
                    <li><b>Decent, safe, and affordable housing.</b> Helping people with psychiatric disabilities to live in the community requires that decent housing be made affordable. For housing to be considered affordable, tenants must pay no more than 30 percent of their income toward rent plus basic utilities. Research indicates that access to housing subsidies improves housing quality, encourages engagement in mental health services, and leads to shorter hospital stays.</li>
                    <li><b>Housing integration.</b> An integrated setting is one that allows people to interact with others who do not have disabilities. A desirable level of integration may be achieved by helping people find scattered-site homes on the rental market or by developing mixed-use buildings, in which most of the units are not reserved for people with psychiatric disabilities.</li>
                    <li><b>Rights of Tenancy.</b> Housing is permanent in the sense that people have a lease, and as long as they meet the basic obligations of tenancy, such as paying rent, they are able to stay in their home. They do not need to move out of their home if their service needs change.</li>
                    <li><b>Access to housing. </b>Part of having the same housing rights as everyone else is the ability to qualify for and keep housing regardless of whether services are accepted. Although Supportive Housing is designed for people who need services to live independently, and needed services are made available to tenants, acceptance of these services, including mental health treatments and medications, is not a requirement of receiving or maintaining housing.</li>
                    <li><b>Flexible, voluntary, and recovery-focused services.</b> Supportive Housing is distinguished from residential treatment programs and congregate housing by the flexibility of the services offered and the freedom of tenants to choose the services that they need. Services and supports to foster success in desired housing should be accompanied by a thorough evaluation of what the individual needs and what they are willing to accept.</li>
                </ol>
            </>,
            "With flexible supports, people with serious mental illness can live in housing of their choice, just like any other member of the community. These programs typically use a case management model but may utilize Assertive Community Treatment for service provision. Programs may also have on-site staff in permanent housing settings owned and managed by local CMHCs. All tenants have access to an array of services that help them keep their housing and live with maximum independence in community integrated housing with the goal of increasing community tenure."
        ]
    },
];

export default housing_Content;