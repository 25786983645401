// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";


function Paragraph({ text, title }) {
    return (
        <div title={title}>
            <p>
                {text}
            </p>
        </div>
    );
};
export default Paragraph;