// Purpose of this component is to grab grab "parent" and "children" related links
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
import SanitizeHref from "../utils/SanitizeHref";
// import IsNullOrWhiteSpace from "./IsNullOrWhiteSpace";

function RelatedLink({ pageID, ...props }) {
    // The API will be stored in related links
    const [relatedLinks, setRelatedLinks] = useState([]);
    const { error, processing, getRequest } = useApi();
    const apiObject = useApi();
   
    const isNull = "";

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "page/" + pageID + "/related-links");
            if (status == 200) {
                setRelatedLinks(data.data.sort((a, b) => a.sort - b.sort));
                if(data == null){
                    isNull = "";
                }
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, [pageID]);
    return (
        <>
            <p style={{"fontSize": "22px","margin-top":"0px"}}><b title="Related Links">Related Links</b></p>
                <div className="">
                    <div className="col">
                        <ul className="no-bullet">
                            {/* Return Related Links */}
                            {processing ? <Spinner animation="border" variant="success" /> : relatedLinks.map((data, index) => {
                                return (
                                    <li><a target="_blank" key={index} title={data.name} className="quick-links-body" href={SanitizeHref(data.url)}>{data.name}</a>
                                        {/* Grabs Child links */}
                                        {data.children.map((data, index) => {
                                            return (
                                                <li><a target="_blank" title={data.name} key={index} className="quick-links-body indented-list" href={SanitizeHref(data.url)}> - {data.name}</a></li>
                                            )
                                        }
                                        )}
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </div>
        </>
    );
}

export default RelatedLink;