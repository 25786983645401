//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
import SanitizeHref from "../utils/SanitizeHref";

function LinkNoNewPage({ linkTypeID, ...props }) {

    const [links, setLinks] = useState([]);
    const { error, processing, getRequest } = useApi();

    useEffect(() => {

        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/" + linkTypeID);
            if (status == 200) {
                setLinks(data.data)
                links.sort(((a, b) => a - b))
            }
            else {
                console.log(error)
            }
        };
        fetchLinks();
    }, []);
    return (
        <>
            <div className="">
                <ul className="no-bullet">
                    {/* Return Links */}
                    {
                        processing ? <Spinner animation="border" variant="success" /> : links.map((data, index) => {
                            return (
                                <li><a key={index} className="quick-links-body" href={SanitizeHref(data.url)}>{data.text}</a></li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    );
}

export default LinkNoNewPage;