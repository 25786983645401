import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const commission_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                The Governor's signing of House Bill 144 on April 14, 2000, was the result of multiple efforts throughout the state to bring positive changes to Kentucky's system of services for persons with developmental, intellectual and other related disabilities.
                One of the major efforts began in September 1999, when the Commonwealth of Kentucky was invited to participate in what is now The President's Committee for People with Intellectual Disabilities (PCPID).
            </>,
            <>
                Under the leadership of the Governor and the Secretary of the Cabinet for Health Services, a contingent of state representatives – including legislators, state agency heads, advocates, providers, and family members representing individuals with intellectual and developmental disabilities – went to Washington to begin the planning effort. As a result, a planning committee was developed which was in operation from September 1999 until June 2000, when the commission was formed.
            </>,
            <>
                The PCPID planning committee, along with advocacy groups and strong legislative leadership, provided the impetus for the development of House Bill 144, which created what is now the Kentucky Commission on Services and Supports for Individuals with Intellectual or Other Developmental Disabilities. It also created KRS 210.575,
                which established the membership of the commission, and KRS 210.577, which defines the commission's meetings, purposes and goals.
            </>,
            <>
                The commission is to serve in an advisory capacity to the Governor and the General Assembly concerning the needs of persons with an intellectual disability and other developmental disabilities.
            </>
        ]
    },
];

export default commission_Content;