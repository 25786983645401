//Using the react library
import React from "react";
//Using the react library:
import { useState } from "react";

import service from '../data/CSBData.json'

function CSBServiceDropdown() {
    
    const [selected, setSelected] = useState(null);

    return (
        <>
            <div title="CSB Services" className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    // console.log(e.target.value);
                    // console.log(contentCounties);
                    const c = service.service?.find((x) => x.id == e.target.value)
                    setSelected(c)
                    // console.log(selected)
                }}>
                    <option>Select a Service</option>
                    
                    {
                        service.service.map((data, index) => {
                            return (
                                <option key={index} value={data.id} className="form-select"
                                >{data.title}</option>
                            )
                        })
                    }
                </select>

            </div>
            {selected ?
                <>        
                    <p><b>{selected.title}. </b>{selected.description}</p>
                    <p>{selected.descriptionTwo}</p>
                   
                </>
                : null}
        </>

    );
}

export default CSBServiceDropdown;