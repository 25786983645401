import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import PESLogo from "../../../assets/img/sud/PESLogo.png"
import Picture from "../../../layouts/bodyLayouts/helpers/Picture";

const PESContent = [
    
    // {
    //     format: bodyHelperTypes.image,
    //     order: 20,
    //     content: PESLogo

        

    // },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
        <><b>The Prevention Enhancement Specialists (PES)</b> through the Prevention Enhancement System provide technical assistance and training to Regional Prevention Centers, their partner prevention coalitions, and other stakeholders on best practices in alcohol, nicotine, cannabis, stimulant, and other drug use/misuse prevention, suicide prevention, and mental health promotion. A key focus of the PES is to empower the communities of the Commonwealth to decrease the harmful use of substances and related consequences, improve mental health, and increase engagement of community partners.  </>

        
    ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: ["The PES help  provide support on:", <UnorderedList items={["Community collaboration","Capacity building","Data collection","Evidence-based education program selection","Current research and trends","Policy and procedure review","Resources","Prevention presentations","Other needs identified by communities"]} />,"PES services are free of charge to prevention providers in Kentucky."]

        

    },{
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: [
            
            "The system currently includes five prevention specialists, each with a particular area of expertise: alcohol, stimulants, cannabis, nicotine, and mental health promotion/suicide prevention."
        ]

        

    },
        
                ];

 export default PESContent;