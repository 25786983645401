import React from 'react';
import bodyHelperTypes from '../../../utils/BodyHelperTypes';
import UnorderedList from '../../../layouts/bodyLayouts/helpers/UnorderedList';


const aFM_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Description"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Division of Administration and Financial Management (AFM) helps to ensure that the programs and services of the department are managed in accordance with appropriate statutes and regulations through the provision of centralized administrative, financial and grant management support, as well as budget preparation, execution and analysis.",
            "This division's staff oversees preparation and management of approximately 200 contracts; works with the Cabinet for Health and Family Services staff in all purchasing, payment, contract monitoring, budgeting and accounting arenas for the department; and provides support and technical assistance to all department staff.",
            "AFM consists of the two branches described below."

        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Administrative Services Branch"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "The Administrative Services staff assists with various areas. The group handles all purchasing-related activities within the department. They are responsible for the following:",
            <><UnorderedList items={[
                "Procuring both goods and services (professional and non-professional).",
                "Coordinating the contracting process for the department",
                "Coordinating inventory-related activities.",
                "Coordinating records retention and disposal.",
                "Coordinating comprehensive medical liability insurance programs.",
                "Monitoring procurement card ('Pro-card') activities.",
                "Coordinating Plan and Budget activities."
            ]} /></>
        ]

    },

    {

        format: bodyHelperTypes.title,
        order: 50,
        content: "Financial Management Branch"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "The Financial Management Branch staff members are liaisons to each of the program areas in the department. They are responsible for the following:",
            <><UnorderedList items={[
                "Preparing biennial/annual budgets.",
                "Monitoring expenditures and revenues monthly.",
                "Making payments to major contractors.",
                "Reviewing federal and state statutes and regulations and proposed legislation to determine financial impact to the department.",
                "Reconciling annual financial statements.",
                "Coordinating and reviewing federal grant applications.",
                "Preparing and assisting with facility Medicare and Medicaid cost reports.",
                "Providing technical assistance to facilities, including investigating and monitoring MCO/Medicaid, Medicare, and third-party billing and reimbursement issues."
            ]} /></>
        ]

    },


];

export default aFM_Content;