import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const procedures_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Click on a link below to view the document.",

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Facility Risk Management Protocol"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <><Links linkTypeID="146" /></>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Related Facility Risk Management Forms and Information"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            <><Links linkTypeID="145" /></>

        ]
    },
];

export default procedures_Content;