import IsNullOrWhiteSpace from "./IsNullOrWhiteSpace";


function ContactInfoPhone({phone}){

    const li = <><i className="fa-li fa fa-phone " aria-hidden="true"></i><a className="phone" href={"tel:" + phone}>{phone}</a><br /></>;

    return <IsNullOrWhiteSpace arg={phone} notNullComponent={li} />

}

export default ContactInfoPhone;