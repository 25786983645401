import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";


const hOTLINES_Content = [
    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><b>National Domestic Violence Hotline</b><br />
            (800) 799-7233<br/>
            If you are unable to speak safely, visit <a href="https://www.thehotline.org/">thehotline.org</a> or text START to 88788.

            </>,
            <><b>National Sexual Assault Hotline</b><br />
            (800) 656-HOPE (4673)<br/>
            Routes to a local provider 24/7.<br/>
            You can also access 24/7 help online by visiting <a href="https://hotline.rainn.org/">hotline.rainn.org</a>
            </>,
            <><b>988 Suicide and Crisis Lifeline
            Dial 988</b><br />
            <a href="https://988lifeline.org/">988lifeline.org</a>
            </>,
        ]
    },
];

export default hOTLINES_Content;