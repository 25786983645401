import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import Links from "../../../components/Links";


const adultMH_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "About Us"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                The Adult Mental Health and Recovery Services Branch is responsible for overseeing and supporting the 14 regional Community Mental Health Centers (CMHCs) in the delivery of behavioral health services to adults with serious mental illness<b>(SMI).</b>,
                "Staff provide training and technical assistance to the CMHCS to enhance their capacity to deliver an array of evidence-based practices that focus on the promotion, treatment, and recovery services and supports for adults with SMI.
            </>,
            <>
                <ul className="no-bullet">
                    <li>
                        <Link className="quick-links-body" to={"/mh/act"}>
                            <div>Assertive Community Treatment</div>
                        </Link>
                        <Link className="quick-links-body" to={"/mh/employment"}>
                            <div>Individual Placement Support Supported Employment</div>
                        </Link>
                        <Link className="quick-links-body" to={"/sud/ebpi-recovery"}>
                            <div>Peer Support</div>
                        </Link>
                        <Link className="quick-links-body" to={"/mh/housing"}>
                            <div>Supportive Housing</div>
                        </Link>

                    </li>
                </ul>

            </>,
            <>
                Program Administrators are directly involved with the implementation of the Third Amended Settlement Agreement (TASA), through which adults with serious mental illness are assisted in transitioning to the community from Personal Care Homes and diverted from institutionalization. They also serve as liaisons for the state psychiatric hospitals to ensure quality of services and continuity of care.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Program Areas"

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <>
            The Adult Mental Health and Recovery Services Branch staff is responsible for the following program areas:
            <br />
            <br />

            <Links linkTypeID="223" />
        </>
    }
];

export default adultMH_Content;