import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import Links from "../../../components/Links";

const sUDHome_Content = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Division of Substance (DSUD) supports implementation of programs and services for Kentuckians impacted by substance use and/or co-occurring substance use and mental health disorders. Partnerships with community-based organizations, service providers, people with lived experience, universities, and state agencies support the delivery of prevention, harm reduction, treatment, and recovery support services. Through state funding and federal grants, these services are publicly available to persons across the Commonwealth.",
            <>In addition, the Division of Substance Use Disorder aims to expand access to <Link to='/sud/bestpractice'>evidence-based services</Link> that are person-centered, trauma-informed, accessible, and of quality for all Kentuckians.</>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Division of Substance Use Disorder Branches"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content:[
            <>
                Each branch provides guidance and technical assistance on the implementation of evidence-based practices, manages state-operated programs, and coordinates efforts to build a recovery-oriented system of care across the lifespan.
                <br />
                <br />

                <Links linkTypeID={"776"} />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Find Help"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content:
            <>
                <ul className="no-bullet">
                    <li><a className='quick-links-body' href='https://findhelpnowky.org/ky '>Find Help Now</a></li>
                    <li><a className='quick-links-body' href='/providerdirectory '>Treatment Locator Tool </a></li>
                    <li><a className='quick-links-body' href='https://www.findrecoveryhousingnowky.org/'>Find Recovery Housing</a></li>
                    <li><a className='quick-links-body' href='https://988.ky.gov/'>988 Suicide and Crisis Line </a></li>
                    <li><a className='quick-links-body' href='https://dpt2.samhsa.gov/treatment/directory.aspx'>Methadone Treatment Directory</a></li>
                    <li><a className='quick-links-body' href='https://nida.nih.gov/publications/drugfacts/naloxone'>Naloxone Website </a></li>
                </ul>

            </>
    }
];

export default sUDHome_Content;