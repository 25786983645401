import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const hRCBIC_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><Links linkTypeID="768" /></>
        ]
    },
];

export default hRCBIC_Content;