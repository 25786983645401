import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import SanitizeHref from "../../../utils/SanitizeHref";
import DocumentsDropdown from "../../../components/DocumentsDropdown";
import FacilitiesDataReports from "../../../components/FacilitiesDataReports";

const dataReportsFacility_Content = [

    // {
    //     format: bodyHelperTypes.title,
    //     order: 10,
    //     content: "Facility Data Reports"
    // },
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>Select a report from one of the dropdown lists.</>,
            <>
                <b>Note: </b>
                These reports open in a separate tab, so you may have to disable your popup blocker or allow popups from this site.
            </>,
            <>
                <FacilitiesDataReports />
            </>
        ]
    },
];

export default dataReportsFacility_Content;