import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const kIPContent = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Purpose",

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: "To anonymously assess adolescent use of alcohol, tobacco, and other drugs (ATOD), as well as a number of additional factors related to adolescent social and emotional well-being, including school safety, mental health, bullying, relationship violence, school performance and community involvement. The survey provides information about student self-reported use of substances, student perceptions about substance use, and perceived accessibility of substances in the community.",

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "History",

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "Since 1999, the KIP Survey has been administered through the Substance Use Prevention and Promotion Branch in the Cabinet for Health and Family Services, through agreements with individual school districts across the state. Originally, the KIP survey was used as part of a federal initiative the Kentucky Incentives for Prevention program (thus, the name “KIP Survey.”) The survey was conducted biannually in the fall in even-numbered years from 2004-2018. Due to the Covid-19 pandemic, the survey administration planned for 2020 was rescheduled for fall 2021. In 2021, more than 93,000 students representing 127 school districts completed the KIP Survey.",
            "Effective with the 2021 administration, the KIP Survey has transitioned to a completely web-based administration process within a classroom setting. All KIP survey data are transferred over a secure connection and stored in a protected database to ensure confidentiality throughout the administration and analysis process. The web-based KIP Survey can successfully be administered on desktop computers, laptops, tablets, and smartphones.",
            "Due to new procedural requirements, the next administration of the KIP Survey has been delayed until fall 2024. "
        ],

    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Content",

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: "The core items on the present KIP survey were originally chosen by the federal Center for Substance Abuse Prevention (CSAP), based on extensive research on risk and resilience factors associated with youth substance use. Basing the scale on the federal model enables comparisons to other states and to the nation, while at the same time making within-state comparisons. For each administration since 2006, questions addressing additional illicit substances, mental health, vaping, impacts of the COVID-19 pandemic, and several other critical items related to adolescent behavioral health have been added to the survey.",

    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Results",

    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content: "Each participating school district receives the following:",

    },
    {
        format: bodyHelperTypes.orderedList,
        order: 90,
        content: [
            "A district-level report including comparisons with the region, the rest of the state and the US (when available)",
            "A district-level trend report, showing within-district trend data for applicable questions from the current administration back to 2012",
            <>
                
                    A report synthesizing required measures to be submitted by all Drug- Free Communities (DFC) grant recipients
                

                <UnorderedList items={["District-level results are reported only to the school district and are not released in a public report.", "The fact that the KIP Survey has been administered within Kentucky since 1999 enables districts to monitor trends and analyze changes over time."]} />

            </>


        ],

    },






];

export default kIPContent;