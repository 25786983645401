import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const sIAC_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The State Interagency Council for Services and Supports to Children and Transition-age Youth (SIAC) is a group of leaders representing 12 state agencies from across 5 Cabinets that serve children and transition-age youth and their families , a youth, a parent of a child or transition-age youth with a behavioral health need, a member of a nonprofit family organization, and a representative from the Subcommittee for Equity & Justice for All Youth (SEJAY).",
            
            <>SIAC oversees coordinated policy development, comprehensive planning, and collaborative budgeting for services and supports to children and transition-age youth with or at risk of developing behavioral health needs and their families. SIAC operates in accordance with the system of care approach (see Related Links) and encourages community partners to do the same. SIAC conducts monthly meetings that are open to the public. SIAC conducts monthly meetings that are open to the public. These monthly meetings support collaboration among child- and family-serving agencies and system of care partners. SIAC serves as the governing body for the state system of care and directs Regional Interagency Councils (RIACs) (see Related Links) to operate as the regional locus of accountability for the system of care. RIACs provide a structure for coordination, planning, and collaboration of services and supports at the local level to children, adolescents, and transition-age youth and their families, to help them function better at home, in school, in the community, and throughout life. </>
           
            
        ]
    },

    // {
    //     format:bodyHelperTypes.title,
    //     order: 20,
    //     content: '"Justice For All: Moving From Protest to Policy" Panel Discussion'

    // },

    // {
    //     format: bodyHelperTypes.paragraph,
    //     order: 30,
    //     content: <><Links linkTypeID={794} /></>
    // }
    






];

export default sIAC_Content;