import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const adultMHAging_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Kentucky Mental Health and Aging Coalition (KMHAC) was assembled in October 1999, with the assistance of a federal grant, to support the development of mental health services for the aging population. The KMHAC was modeled after the National Coalition on Mental Health and Aging.",
            "The Coalition comprises older adults, caregivers, local public and private organizations, state universities, and the Cabinet for Health and Family Services.  The Coalition has four main purposes:",
            <>
                FUNDING <br />

                Provide funding to local coalitions for events and/or activities promoting healthy mental health and aging community practices. <br />
                <br />
                Advocacy <br />
                Educate the aging population and their caregivers on their rights; empowering them to make informed decisions. <br />
                <br />
                EDUCATION <br />
                Support local trainings on mental health and related topics tailored towards the aging population. <br />
                <br />
                TECHNICAL ASSISTANCE <br />

                Assist local coalitions in developing sustainable plans that meet the needs of their aging populations within their communities.
            </>,
            <>
                <b>Local Coalitions and Resources:</b>
                <div class="row">
                    <div className="col">

                        <ul>
                            <li>Northern Kentucky: 859-283-1885</li>
                            <li>Buffalo Trace: 606-564-6894</li>
                            <li>FIVCO: 606-929-1366</li>
                            <li>Big Sandy: 606-886-2374</li>
                            <li>Gateway: 606-780-0090</li>
                            <li>Kentucky River: 606-666-7591</li>
                            <li>Cumberland Valley: 606-877-5763</li>
                            <li>Purchase: 270-247-7171</li>
                        </ul>

                    </div>
                    <div className="col">


                        <ul>
                            <li>Bluegrass: 859-269-8021</li>
                            <li>Lake Cumberland: 270-866-4200</li>
                            <li>Barren River: 270-781-2381</li>
                            <li>Lincoln Trail: 270-769-2393</li>
                            <li>KIPDA: 502-266-5571</li>
                            <li>Green River: 270-926-4433</li>
                            <li>Pennyrile: 270-886-9484</li>
                        </ul>

                    </div>
                </div>
            </>

        ]
    },
];

export default adultMHAging_Content;