import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";






const immersionContent = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"
    },

    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "An innovative, four-day training event, Operation Immersion is designed to expand the knowledge base and skills of professionals and providers who support Kentucky’s Service Members, Veterans and their Families (SMVF).",
            "This event is presented by the Kentucky Cabinet for Health and Family Services (CHFS), the Kentucky Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID), and the Kentucky National Guard. With classroom workshops taught by field experts and first-hand military experiences taught by military leaders, this unique training experience is unlike any other.",
            "This unique training event gives participants the opportunity to experience firsthand what life is like on a military facility. Operation Immersion combines the best of both worlds with classroom and field training sessions led by experts to keep you motivated and engaged",
            "Thank you in advance for your commitment to serving Kentucky’s heroes.",
            <><i>"I can honestly say that this was the single most valuable learning experience that I have ever encountered. The amount of learning and growth achieved during the four days is difficult to fully measure, as it is vast and deep."</i> <br /><br />
                –Susan M. Scully-Hill, Ph.D
                <br /><br />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Event Learning Objectives"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>Operation Immersion is designed to:</>,
            <><UnorderedList items={[
                "Equip professionals and service providers with knowledge and skills that increase their capacity to provide quality care and support the SMVF population.",
                "Promote integrated care and support systems by connecting behavioral health providers and professionals with military and veterans' resources.",
                "Provide all participants with a clearer understanding of the particular underlying causal factors (multiple deployments, Post Traumatic Stress, Traumatic Brain Injury and suicidal ideation) which put the SMVF population at risk for substance misuse, suicide and behavioral health disorders."
            ]} /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Event Information"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            'A typical day begins with a "PT" workout modified for civilians. (Don’t worry, you can do it!) This will be followed by classroom sessions or military awareness training from experts in their field. During this training, you will have the unique opportunity to experience some of the following: team leadership events and combat missions training (including paintball).',
            "Some of the training sessions will occur outdoors with experiences compatible to a moderate hike in the woods. Be prepared to experience dirt, grass, insects, wildlife and the elements.",
            <><b>Note:</b>Regarding physical training participation, staff and trainers will encourage you to do your personal best. It is not about what others can do, but what you can do. It at any point you feel unable to complete a task, please feel free to step aside and encourage others. </>,
            <><i>"It was challenging. It was emotional. I was tired. And yet, it did not even begin to approximate what our soldiers and military personnel experience. As the second day unfolded, I was enthralled and motivated to learn and grow as much as I possibly could…By the end of the program, I felt deeply connected to the other participants and the staff and did not want the experience to end."</i> <br /><br />
                –2017 Participant

            </>,
            <><b>Priority Registration will be given to first-time attendees and those that serve Kentucky’s Service Members, Veterans and their Families.</b></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Target Audience"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content: <>The target audience includes, but is not limited to, community mental health and substance use disorder treatment agencies, social workers, professional counselors, prevention professionals, nurses, psychiatrists, physicians, psychologists, clergy/pastoral, judicial/corrections, college students, Department of Veterans Affairs staff, and anyone else that serves the SMVF population. <br /><br />
            <b>Space is limited to 100 participants.</b>
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Cost Information"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 100,
        content: <>The cost for this all-inclusive event is typically <b>$100 per participant</b> (a $1,375 value). This fee includes: <br /><br />
            <UnorderedList items={[
                "30.5 CEUs (approval pending). See below for more information.",
                "Classroom and field sessions.",
                "On-site lodging and meals.",
                "Use of equipment and facilities.",
                "Participant T-shirt.",
                "An experience that will enhance your skills for working with the SMVF population.",
                "A great networking opportunity to build your resources."
            ]} />
        </>
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Contact Hours and Continuing Education Units"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            "Normally, a total of approximately 30 Contact Hours/Continuing Education Units (CEUs) have been requested from the following boards: Alcohol and Drug Counselors, Long-Term Care Administrators, Nursing, Pastoral Counselors, Professional Art Therapists, Psychology, Social Work, Licensed Professional Counselors, and Licensed Marriage and Family Therapists.",
            "Information will be available for submitting Continuing Education information to your respective entities for other specialties and those not preapproved. "
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Training Topics"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 140,
        content: [
            "You will learn from expert trainers covering the following topics:",
            <><UnorderedList items={[
                "Military Cultural Competency Training As It Relates to Behavioral Health",
                "Therapeutic Risk Management of the Suicidal Patient (TRMSP)",
                "Field Leadership Reaction Training",
                "Adverse Childhood Experiences (ACES) and Their Impact on Current Service Members",
                "Role of Behavioral Health in the Military",
                "Introduction to Urban Combat and Improvised Explosive Devices",
                "Combat Missions Training Experience With Paintball",
                "Military Connected Youth: An At-Risk Population for Opioid Abuse and Suicide",
                "Insomnia and Suicide Risk",
                "Combat Stress and the Impact of Deployment Cycles",
                "Suicide Prevention: Lived Experience",
                "Military Chaplaincy",
                "An Introduction to Conceptualizing Moral Injury",
                "Soldier Experiences in Today's National Guard",
            ]} /></>,
            <><i>"OI is not just beneficial to us and our soldiers, but also to any veterans who receive services from providers who go through our program."</i><br /><br />

                –Brigadier General Charles Jones (Ret.), Kentucky National Guard

            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 150,
        content: "Eligiblity Requirements"
    },
    {

        format: bodyHelperTypes.paragraphs,
        order: 160,
        content: [
            "All individuals must be at least 18 years old. Other eligibility requirements are listed below.",
            <><b>By registering for Operation Immersion, you are agreeing to the following terms:</b></>,
            <><UnorderedList items={[
                "You are currently, or plan to be in the near future (within 12 months), serving the SMVF population in Kentucky.",
                "You will participate in a pre- and post-event evaluation process, including six-month and twelve-month follow-up surveys.",
                "Your photograph, electronic video image, or likeness may be utilized for future publicity and training opportunities by CHFS, DBHDID and Kentucky National Guard.",
                "Your professional contact information, including name, title, organization, email, and contact number will be shared with Operation Immersion participants, staff, presenters and the Kentucky National Guard."
            ]} /></>
        ]

    },

    {
        format: bodyHelperTypes.title,
        order: 170,
        content: "Registration Information"
    },
    {

        format: bodyHelperTypes.paragraphs,
        order: 180,
        content: [
            <><b>Once registration reaches capacity, a selection process and waiting list will be established in order to fill any open slots that may become available.</b></>,

            <><b>Please note (regarding background check): Per military regulations, we must collect confidential personal information in order for you to gain security clearance and access onto the military installation.</b> This information is for Operation Immersion vetting purposes only. Your information will remain confidential and will only be shared with the registration team and the military security vetting office. Operation Immersion leadership will contact you directly if additional security clarification is needed regarding your access to the installation. Please DO NOT contact the Kentucky National Guard or the training site directly.</>,
            <><b>If you have general questions, questions regarding registration, or you did not receive registration confirmation within 72 hours, please use the contact in the Contact Information box, whose name is an email link.</b><br/><br/></>
           
        ]

    }



];

export default immersionContent;