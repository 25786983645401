import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import ContinuumOfCare from "../../../assets/img/sud/ContinuumOfCare.png"
import { Link } from "react-router-dom";
import Links from "../../../components/Links";

const TrustContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Substance Use Prevention & Promotion Branch of the Division of Substance Use Disorder, along with its partners, the Tobacco Prevention and Cessation Program of the Department for Public Health and the Department of Alcoholic Beverage Control, have developed a Tobacco Retailer Education Program called Tobacco Retailer Underage Sales Training (TRUST).",
            "Unlike other tobacco retailer training programs, TRUST is offered free of charge in an online training format. TRUST includes information specific to Kentucky, as well as the Food and Drug Administration (FDA) federal tobacco laws. According to FDA guidance documents, having store employees trained through a program such as TRUST that follows FDA training guidelines could reduce fines to store owners if they were found in violation of federal tobacco laws.",
            'For copies of the TRUST brochure, click the "TRUST Brochure" link or the contact shown in in the Contact Information box to request by email'
        ]

    },

   


  
];

export default TrustContent;