import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLFormsIncident_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Waiver Incident Process"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                All incidents are to be submitted in the Medicaid Waiver Management Application (MWMA).
                <br />
                <br />
                <Links linkTypeID="237" />
            </>,
            <>
                The Department for Community Based Services (DCBS) is to be notified of all suspected abuse neglect and exploitation.

                <br />
                <br />
                <Links linkTypeID="729" />
            </>
        ]
    },
];

export default sCLFormsIncident_Content;

