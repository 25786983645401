import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const pSS_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Becoming an Approved Peer Support Specialist Training Provider"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Becoming an approved Peer Support Specialist (PSS) training provider authorizes entities to teach the required training that allows individuals to be certified Peer Support Specialists in Kentucky.",
            "The following curricula are available for approval by the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID), as established in the Kentucky Administrative Regulations:",
            <>
                <li>Adult Peer Support Specialist (APSS)</li>
                <li>Family Peer Support Specialist (FPSS)</li>
                <li>Youth Peer Support Specialist (YPSS)</li>
                <li>Kentucky Family Leadership Academy (KFLA)</li>
            </>,
            <>
                The Kentucky Administrative Regulations (KAR) specifically speak to the elements of a "training curriculum" and the training requirements (testing of the trainee and evaluation of the trainers), and provide the curriculum applicant with an understanding of the requirements for peer support specialists—both eligibility and training. These regulations can be found in the Related Links box on the right side of the page.
            </>,
            "DBHDID requires the use of specific documents to ensure a complete submission of all necessary materials. This will allow DBHDID staff to review the curricula in their entirety, and to make an approval decision or request supplementary materials in an efficient manner, within the period specified in the regulations. Curriculum submission requirements are listed below."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Submission Requirements for Adult Peer Support Specialist 30-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                <ol>
                    <li>Review regulation 908 KAR 2:220 in Related Links.</li>
                    <li>Complete the APSS Single Curriculum Submission Summary  <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-adult.pdf">PDF</a> <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-adult.docx">Word</a>. 
                        This document contains identifying information about the applicant who is applying for curriculum approval.</li>
                    <li>Complete the APSS 30-Hour Curriculum Rubric <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-adult.pdf">PDF</a> <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-adult.docx">Word</a>. 
                        This rubric identifies the required APSS core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's
                        yellow section is incomplete; the submitter's information will be returned and resubmission requested.</li>
                    <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive.
                        These documents must be clearly labeled with entities/submission name.</li>
                    <li>Include an examination and an answer key in your curriculum submission</li>
                    <li>Include an evaluation form of the training.</li>
                </ol>
            </>
        ]
    },
    // {
    //     format: bodyHelperTypes.title,
    //     order: 50,
    //     content: "Submission Requirements for Family Peer Support Specialist 30-Hour Curriculum"
    // },
    // {
    //     format: bodyHelperTypes.paragraphs,
    //     order: 60,
    //     content: [
    //         <>
    //             <ol>
    //                 <li>Review regulation 908 KAR 2:220 in Related Links.</li>
    //                 <li>Complete the APSS Single Curriculum Submission Summary <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-family.pdf">PDF</a> <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-family.docx">Word. </a>
    //                     This document contains identifying information about the applicant who is applying for curriculum approval.</li>
    //                 <li>Complete the APSS 30-Hour Curriculum Rubric  <a href="">PDF</a> <a href="">Word</a>
    //                     This rubric identifies the required APSS core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's
    //                     yellow section is incomplete; the submitter's information will be returned and resubmission requested.</li>
    //                 <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive.
    //                     These documents must be clearly labeled with entities/submission name.</li>
    //                 <li>Include an examination and an answer key in your curriculum submission</li>
    //                 <li>Include an evaluation form of the training.</li>
    //             </ol>
    //         </>
    //     ]
    // },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Submission Requirements for Family Peer Support Specialist 30-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            <ol>
                <li>Review regulation 908 KAR 2:230 in Related Links.</li>
                <li>Complete the FPSS Single Curriculum Submission Summary  <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-family.pdf">PDF</a> <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-family.docx">Word</a>. 
                    This document contains identifying information about the applicant who is applying for curriculum approval.</li>
                <li>Complete the FPSS 30-Hour Curriculum Rubric  <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-adult.pdf">PDF</a> <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-adult.docx">Word</a>. 
                    This rubric identifies the required FPSS core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned and resubmission requested.</li>
                <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                <li>Include an examination and an answer key in your curriculum submission</li>
                <li>Include an evaluation form of the training.</li>
            </ol>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Submission Requirements for Youth Peer Support Specialist 30-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [
            <>
                <ol>
                    <li>Review regulation 908 KAR 2:240 in Related Links.</li>
                    <li>Complete the YPSS Single Curriculum Submission Summary  <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-youth.pdf">PDF</a>  <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-adult.docx">Word</a>.
                        This document contains identifying information about the applicant who is applying for curriculum approval.</li>
                    <li>Complete the YPSS 30-Hour Curriculum Rubric  <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-youth.pdf">PDF</a>  <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-youth.docx">Word</a>. 
                        This rubric identifies the required YPSS core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned and resubmission requested.</li>
                    <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                    <li>Include an examination and an answer key in your curriculum submission.</li>
                    <li>Include an evaluation form of the training.</li>
                </ol>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Submission Requirements for Kentucky Family Leadership Academy Peer Support Specialist 15-Hour Curriculum"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            <>
                <ol>
                    <li>Review regulations 908 KAR 2:230 and 908 KAR 2:240 in Related Links.</li>
                    <li>Complete the KFLA Single Curriculum Submission Summary  <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-kfla.pdf">PDF</a> <a href="https://dbhdid.ky.gov/documents/dbh/pss/summary-kfla.docx">Word</a>. 
                        This document contains identifying information about the applicant who is applying for curriculum approval.</li>
                    <li>Complete the KFLA 15-Hour Curriculum Rubric  <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-kfla.pdf">PDF</a>  <a href="https://dbhdid.ky.gov/documents/dbh/pss/rubric-kfla.docx">Word</a>.
                        This rubric identifies the required KFLA core components and expectations for curriculum development. Please note that the curriculum cannot be reviewed if the rubric's yellow section is incomplete; the submitter's information will be returned and resubmission requested.</li>
                    <li>Submit an electronic version of the curriculum, saved as a Word, PowerPoint and/or PDF file using a USB flash drive. These documents must be clearly labeled with entities/submission name.</li>
                    <li>Include an examination and an answer key in your curriculum submission</li>
                    <li>Include an evaluation form of the training.</li>
                </ol>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Sample Curriculum Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            "Below are some sample resources to assist with curriculum development.",
            <>
                <ol>
                    <li><a href="https://dbhdid.ky.gov/documents/dbh/csa/sample.pdf?t=08143807152024">Sample curriculum</a></li>
                    <li><a href="https://dbhdid.ky.gov/documents/dbh/tcm/sample-eval.pdf?t=08143807152024">Sample evaluation of training</a></li>
                    <li><a href="https://dbhdid.ky.gov/documents/dbh/tcm/sample-exam2.pdf?t=08143807152024">Sample trainee test questions</a></li>
                </ol>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Where to Submit Complete Curriculum and Supporting Documentation"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 140,
        content: [
            "Submit this information to:",
            <>
                Department for Behavioral Health, Developmental and Intellectual Disabilities <br />
                Division of Program Integrity <br />
                Program Support Branch <br />
                275 E. Main Street 4CD <br />
                Frankfort, KY 40621 <br />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 150,
        content: "Curriculum Review and Trainer Requirements"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 160,
        content: [
            <>
                After a curriculum is received, it will be reviewed within 20 business days.
                Communication on the status of the submitted curriculum will be sent to the contact person listed on the "Single Curriculum Submission Summary."
            </>,

            <>
                ll training staff listed on the Single Curriculum Submission Summary must hold a current Peer Support Specialist certification in the state of Kentucky and be up-to-date on CE requirements in the DBHDID TRIS database. Any trainers found to be noncompliant will have 30 calendar days from curriculum receipt to rectify any certification issues.
                After 30 days, DBHDID will consider the curriculum incomplete and a denial letter will be issued.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 170,
        content: "Approved Curriculum Guidelines"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 180,
        content: [
            "Once the curriculum is approved, the provider will establish and maintain a TRIS account by entering trainee information and uploading documentation directly into the system. Correspondence concerning the submission of this information will be sent via mail or email.",
            "After the curriculum is approved, when modifications occur, please notify the department using the “Contact Us” link in the Contact Information box at the top right of the page."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 190,
        content: "Questions About the Process?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 200,
        content: [
            <>
                Please click on "Contact Us" in the Contact Information box to submit questions about the process.
            </>,
            
        ]
    }
];

export default pSS_Content;