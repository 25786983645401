import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const dUITraining_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "DUI Training"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                If you wish to attend training, you will need to complete and submit the DUI Certification Training Dates form,
                and the appropriate application, both located in the "DUI Training and Individual Certification Forms" section on the DUI Forms page (see Related Links).
            </>,
            <>
                In order for your application to be considered, all required documents must arrive by the stated deadline.
                Click the Calendar of Events link in the Related Links box to view a list of training dates.
            </>,
            <>
                No applications will be accepted from any agency with payments 60 days or more past due. This is pursuant to the DUI regulation that states that no certification or recertification
                can be issued to any participant until the fee for the training has been paid. See the certification form for payment and mailing information.
            </>
        ]
    },
];

export default dUITraining_Content;