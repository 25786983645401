import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DPRForms from "../../../components/DPRForms";
import PBFormsInfo from "../../../components/PBFormsInfo";

const planAndBudget_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><PBFormsInfo /></>
        ]
    },
];

export default planAndBudget_Content;