import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLTraining_Content = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Below is a list of current Supports for Community Living (SCL) training resources. More information about SCL and Division of Division of Developmental and Intellectual Disabilities training is available through the following links.",
            <><Links linkTypeID="102" /></>
        ]
    },
];

export default sCLTraining_Content;