import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const kYMomsMATR_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Our Mission"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "KY-Moms MATR (Maternal Assistance Towards Recovery) is a behavioral health prevention and case management program focused on risks and effects of substance use/misuse while providing education, information, resources, support and hope to pregnant and postpartum individuals and their families and to the professionals who serve them.",
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Our Vision"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "We are dedicated to connecting pregnant and postpartum individuals and their families in Kentucky with the resources and tools they need to recognize and overcome risk factors and symptoms of prenatal and postpartum substance use/misuse.",
            "We are dedicated to connecting and educating professionals in Kentucky with the resources and tools they need to recognize, learn, and treat risk factors and symptoms of prenatal and postpartum substance use/misuse, along with providing access to available treatment and recovery service options.",
            "We aim to increase overall awareness of and reduce the stigma surrounding prenatal and postpartum substance use/misuse allowing parents to feel heard, acknowledged and supported, and they will engage with informed resources to help lessen the burden, so they no longer feel alone or afraid.",
            "Through prevention education and case management services, we will provide information, resources, and supports to pregnant and postpartum individuals and their families from all backgrounds who might otherwise be unable to access the behavioral health services, recovery supports and medical care they need.",
            "Working in partnerships with community partners, organizations, and agencies, we aim to strengthen the support network in our communities to ensure pregnant and postpartum women are screened, referred and engaged in treatment and recovery supports throughout pregnancy and up to 60 days postpartum."

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Our Service"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "KY-Moms MATR services offer unique and tailored substance use prevention and case management services specifically to meet the needs of pregnant and postpartum individuals. KY-Moms MATR Prevention Specialists and Case Managers are specifically trained in specialized topics and needs of individuals experiencing pregnancy, postpartum and lactation periods.",
            "KY-Moms MATR prevention services educate pregnant and postpartum individuals and their families of potential risks related to substance use during pregnancy and postpartum periods. There are three (3) intensities of substance use prevention services: Universal Prevention Education, Selective Prevention Education, and Indicated Prevention Education. Through a brief screening, a KY-Moms MATR Prevention Specialist can assist the individual in identifying which level of prevention best fits their needs.",
            "A primary focus of the case management service is to engage high-risk pregnant women in needed services, such as mental health and/or substance abuse treatment, domestic violence services, and smoking cessation programs.",
            "KY-Moms MATR-case managers assist the client in identifying and addressing their immediate needs (for example, housing, child care, financial assistance, employment or GED); encourage prenatal care and the client’s involvement in programs such as HANDS and WIC; and encourage the client to practice harm reduction strategies.",
            <>
                KY-Moms MATR prevention specialists and case managers are available to meet with
                pregnant and postpartum persons individually to resources and support that to reduce stress and risk factors during their pregnancy, postpartum and lactation periods. These resources and support may include the following: <br />

                <ul>
                    <li>Financial resources.</li>
                    <li>Housing resources.</li>
                    <li>Nutritional information.</li>
                    <li>Transportation to medical appointments.</li>
                    <li>Assistance to stop using substances.</li>
                    <li>Assistance for smoking cessation</li>
                    <li>Assistance to reduce additional symptoms of substance use risk factors and disorders.</li>
                </ul>
            </>,
            "KY-Moms MATR services are currently offered in all 14 regions and all counties across the state. Each region offers services specific to pregnant and postpartum individuals of in their communities. Please view the Services Map in Related Links for regional contact information, and visit the links below for specific services offered in each region.",
            <><Links linkTypeID="416" /></>

        ]
    },
];

export default kYMomsMATR_Content;