import React, { useState, useEffect } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';

function ProviderSearch({ providerName, setProviderName, onSearch, resetError }) {
    const [errorMessage, setErrorMessage] = useState(""); // To display error messages
    const [loading, setLoading] = useState(false); // To manage loading state

    // Effect to reset error message when resetError changes
    useEffect(() => {
        setErrorMessage("");
    }, [resetError]);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // If no provider name entered, set an error message
        if (!providerName.trim()) {
            setErrorMessage("Enter a provider first, last, or full name.");
            return;
        } else {
            setErrorMessage(""); // Clear error message if there's any
        }
        
        setLoading(true); // Set loading to true before the API call
    
        try {
            //const response = await axios.post('https://localhost:7245/providers/search', { ProviderDesc: providerName });
            //const response = await axios.post('https://providerdirectory.dbhdidtest.ky.gov/api/providers/search', { ProviderDesc: providerName });
            // const response = await axios.post('https://dbhdidtest.ky.gov/provdirapi/providers/search', { ProviderDesc: providerName });
            const response = await axios.post('https://dbhdid.ky.gov/provdirapi/providers/search', { ProviderDesc: providerName });
            const results = response.data;

            //console.log('Search Results:', results); // Debug log
        
            if (Array.isArray(results) && results.length === 0) {
                setErrorMessage("No providers found."); // Set error message if no providers are found
            } else if (Array.isArray(results)) {
                setErrorMessage(""); // Clear the error message if providers are found
                onSearch(results);  // Send the results to the ProviderDirectory.js parent component.
            } else {
                setErrorMessage("Unexpected response format."); // Handle unexpected response format
            }
        } catch (error) {
            //console.error('Error searching providers:', error);
        
            // Check if the error response indicates no providers were found
            if (error.response && error.response.status === 404) {
                setErrorMessage("No providers found.");
            } else {
                setErrorMessage("An error occurred while searching for providers.");
            }
        } finally {
            setLoading(false); // Set loading to false after the API call
        }    
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className="tooltip-custom">
            Enter the provider's first, last, or full name to search.
        </Tooltip>
    );

    return (
        <div className="provider-search-section">
            <hr />
            <br />
            <br />
            <form onSubmit={handleSubmit} className="provider-search-form">
            <OverlayTrigger
                placement="auto"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <label htmlFor="providerName" className="provider-search-label">
                 <strong>Search by Provider Name Only:<span className="info-icon">i</span></strong>
                </label>
            </OverlayTrigger>
                <input
                type="text"
                className="providersearch"
                id="providerName"
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
                placeholder="Enter provider first, last, or full name..."
            />
            <button type="submit" className="btn btn-primary btn-custom provider-search-button" disabled={loading}>
                {loading ? "Searching..." : "Search"}
            </button>
            {errorMessage && <span className="provider-search-error">{errorMessage}</span>}
            </form>
        </div>
    );
}

export default ProviderSearch;
