import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const zero_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                Zero Tolerance (ZT) is a court-ordered substance use service for persons under 21 who are convicted of operating a motor vehicle with a blood alcohol level of .02 to .08. The program includes evidence-based education designed to help individuals manage their substance use.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Program Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "The Zero Tolerance program provides multifaceted prevention and intervention targeting specific needs related to alcohol and other substance use and related consequences.",
            <>
                There are two primary target populations for this program.
                <ul>
                    <li>Youth under 21 charged with a zero-tolerance offense - driving with a blood-alcohol content of .02-.08. </li>
                    <li>2.	Juveniles who are at risk of becoming involved or are already involved with the juvenile justice system and youth who are identified as using or are at increased risk for using substances. </li>
                </ul>
            </>,
            "In accordance with KRS 189A.010 (1), persons under the age of 21 who are operating a motor vehicle with a blood alcohol level of .02 to .08 or under the influence of other substances, are required to receive an assessment/screening and either education or mental health and/or substance use treatment. These youth are referred by the adult traffic court to a licensed ZT provider for assessment/screening. Based on the results of the screening, youth are placed in an education program or are referred for additional assessment which may lead to treatment services."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Outcomes"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                The outcomes of the ZT program are:
                <ul>
                    <li>diverting youth from involvement with the juvenile justice system, </li>
                    <li>reducing the recidivism rate of first- and second-time juvenile offenders, </li>
                    <li>reducing the number of youth who experience future alcohol and other drug related problems.</li>
                </ul>
            </>,
            "Screenings, prevention and intervention strategies, education, and referrals to community resources are key tools to achieving these outcomes. Program components contribute to reducing youth substance use, increasing communication between parent(s)/guardians and youth, and encouraging youth to make positive peer and lifestyle choices."
        ]
    }
];

export default zero_Content;