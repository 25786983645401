import React from "react";
// component import: 
import TableOfContent from "../../components/TableOfContent";



function LayoutOne({ pageID }) {
    return (
        <>
            <TableOfContent pageID={pageID} />
        </>
    );
}

export default LayoutOne;