import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const pDRates_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Per Diem Rates"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Below are links to per diem rate sheets for the specified facilities. Click on a facility name to view its per diem rate sheet.
                <br/><br/>

                <Links linkTypeID="632" />

            </>


        ]
    },
];

export default pDRates_Content;