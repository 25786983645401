//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
import SanitizeHref from "../utils/SanitizeHref";

function DPRForms() {

    const { error, processing, getRequest } = useApi();
    const [general, setGeneral] = useState([]);
    const [dDID, setDDID] = useState([]);
    const [bH, setBH] = useState([]);
    const [bHObjective, setBHObjective] = useState([]);
    const [sA, setSA] = useState([]);
    const [sAObjective, setSAObjective] = useState([]);


    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "document/dpr");
            if (status == 200) {
                // console.log(data.data.documents);
                FormFilter(data.data.documents);
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);

    function FormFilter(formsData) {

        const generalFilter = [];
        const dDIDFilter = [];
        const bHFilter = [];
        const sAFilter = [];
        const sAObjectiveFilter = [];
        const bHObjectiveFilter = [];
        formsData.forEach((form) => {
            if (form.categoryId == 1 && form.division == 'DID') {
                dDIDFilter.push(form);
            }
            else if (form.categoryId == 8 && form.division == 'BH') {
                bHFilter.push(form);
            }
            else if (form.categoryId == 1 && form.division == 'SA') {
                sAFilter.push(form);
            }
            else if (form.categoryId == 10 && form.division == 'SA') {
                sAObjectiveFilter.push(form);
            }
            else if (form.categoryId == 9 && form.division == null) {
                generalFilter.push(form);
            }
            else if (form.categoryId == 2 && form.division == 'BH') {
                bHObjectiveFilter.push(form);
                // console.log(bHObjectiveFilter);

            }
        })
        setGeneral(generalFilter);
        setDDID(dDIDFilter);
        setBH(bHFilter);
        setSA(sAFilter);
        setBHObjective(bHObjectiveFilter);
        setSAObjective(sAObjectiveFilter);
    }
    const BuildHref = (url) => {

        let href = "";

        const instructions = "Department Periodic Reports Processing Instructions.pdf";
        const noPrefix = url == "pi" || url == "pb-forms" || url == "dataguide" || url == "dpr-forms";

        if(noPrefix ){
            href = url;
        }else if(url == instructions){

            href = `documents/dpr/documents/${url}`;

        }else{

            href = `documents/pb/documents/${url}`;
        }
        return SanitizeHref(href);
    }

    return (
        <>
            <div className="">
                <br />
                <h2 id="General Information">General Information</h2>
                <br />
                <div className="row">
                    <div className="col-md-6 col-md-3">
                        <ul className="no-bullet">
                            {processing ? <Spinner animation="border" variant="success" /> : general.map((data, index) => {
                                const sanitizeURL = BuildHref(data.url); 
                                return (
                                
                                    <li className="quick-links-body">
                                        <a title={data.name} key={index} href={sanitizeURL}>{data.name}</a>
                                    </li>
                                )
                            })}
                        </ul>
                        <br />
                        <h2>Instructions and Objectives</h2>
                        <br />
                        <i>Developmental and Intellectual Disabilities</i>

                        <ul className="no-bullet">
                            <b>Instructions</b><br />
                            {processing ? <Spinner animation="border" variant="success" /> : dDID.map((data, index) => {
                                return (
                                    <li className="quick-links-body">
                                        <a key={index} href={SanitizeHref("/documents/cmhc/dpr/documents/" + data.url)}>{data.name}</a>
                                    </li>
                                )
                            })}
                        </ul>
                        <br />
                        <i>Mental Health</i>
                        <ul className="no-bullet">
                            <b>Instructions</b><br />

                            {processing ? <Spinner animation="border" variant="success" /> : bH.map((data, index) => {
                                return (
                                    <li className="quick-links-body">
                                        <a key={index} href={data.url == "113H-iHOPE Project Report Form.pdf" ? SanitizeHref("documents/cmhc/dpr/documents/" + data.url) : SanitizeHref("documents/cmhc/dpr/documents/" + data.url)}>{data.name}</a>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className="no-bullet">
                            <br />
                            <b>Objectives</b><br />
                            {processing ? <Spinner animation="border" variant="success" /> : bHObjective.map((data, index) => {
                                return (
                                    <li className="quick-links-body">
                                        <a key={index} href={SanitizeHref("/documents/cmhc/dpr/documents/" + data.url)}>{data.name}</a>
                                    </li>
                                )
                            })}
                        </ul>
                        <br />
                        <i>Substance Abuse</i>
                        <ul className="no-bullet">
                            <b>Instructions</b><br />
                            {processing ? <Spinner animation="border" variant="success" /> : sA.map((data, index) => {
                                return (
                                    <li className="quick-links-body">
                                        <a key={index} href={SanitizeHref("/documents/cmhc/dpr/documents/" + data.url)}>{data.name}</a>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className="no-bullet">
                            <br />
                            <b>Objectives</b><br />
                            {processing ? <Spinner animation="border" variant="success" /> : sAObjective.map((data, index) => {
                                return (
                                    <li className="quick-links-body">
                                        <a key={index} href={SanitizeHref("/documents/cmhc/dpr/documents/" + data.url)}>{data.name}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DPRForms;