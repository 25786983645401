import React, { useState, useEffect } from 'react';
import useApi from '../utils/UseAPI';
// import dataDates from "../data/data.json";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';


const WIDScrollDown = () => {

    // const [selected, setSelected] = useState(null);
    const [content, setContent] = useState([]);
    const { processing, error, getRequest } = useApi();
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/792");
            if (status == 200) {
                setContent(data.data.sort((a, b) => a.sort - b.sort));
                // console.log(content)
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);
    return (
        <>
            <div title='Select a date' className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    // console.log(e.target.value);
                    const c = content?.find((x) => x.url === e.target.value)
                    // window.location = e.target.value;
                    setSelected(c)
                    // console.log(selected)
                }}>
                    <option>Select a date</option>
                    {
                        processing ? <Spinner animation="border" variant="success" /> : content.map((data, index) => {
                            return (
                                <option key={index} value={data.url} className="form-select"
                                >{data.text}</option>
                            )
                        })
                    }
                </select>

            </div>
            {selected ? <>
                <br />

                <a href={selected.url}>Recording</a><br/><br/>

                <b><u>General Information</u></b><br/><br/>

                <b>Understanding Workforce Needs</b> <br/><br/>

                <b>Increasing the Supply of Professionals</b> <br/> <br/>

                <b>Expanding the Reach of Existing Professionals</b> <br/> <br/>


                <b>Addressing the Distribution of Professionals</b> <br/> <br/>

                <b>Retaining High Quality Professionals</b> <br/> <br/>


                <b>Redesign the BHDID Workforce for the Future</b> <br/> <br/>

                
                <br/>
            </> : null}
        </>
    )
};

export default WIDScrollDown;
