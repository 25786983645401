import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const kYImpact_Content = [
    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Kentucky IMPACT (Interagency Mobilization for Progress in Adolescent and Child Treatment) was created as a statewide service coordination model in 1990. It was based on the work of a grant-funded pilot program (Bluegrass IMPACT) to support states in operationalization of the system of care approach (see Related Links).",
            "IMPACT was established as a coordinated, interagency approach to service delivery for children/youth with serious emotional disabilities (SED) and their families. The model provided services not traditionally available, such as mentoring; respite; school-based services; case management for children/youth; and intensive in-home therapy, as well as flexible funding for informal supports such as community activities, family support, and therapeutic after-school and summer activities. IMPACT also established the State Interagency Council (SIAC) for Services and Supports to Children and Transition-age Youth to as the governing body for Kentucky’s system of care, and Regional Interagency Councils (RIACs) to administer regional management and implementation (see Related Links for SIAC and RIACs).",
            "The overall goal of Kentucky IMPACT is to prevent children/youth with SED from being placed outside of their homes to behavioral health treatment, and to provide support and assistance to those who are transitioning home from such residential placements. Kentucky IMPACT has embraced the Wraparound process since its inception. In fact, Kentucky IMPACT was one of the first statewide Wraparound initiatives in the country.",
            "Historically, Kentucky IMPACT supports the delivery of Targeted Case Management (TCM) for children/youth (see Related Links), in accordance with the values of Wraparound. As such, TCM has been the key mechanism by which children/youth and families have had access to Wraparound. Since the inception of Kentucky IMPACT, science related to Wraparound implementation has progressed dramatically. As a result, DBHDID and Kentucky Partnership for Families and Children (KPFC) staff are working with the National Wraparound Implementation Center (see Related Links) to support High Fidelity Wraparound (HFW) (see Related Links) under Kentucky IMPACT within the fourteen regional Community Mental Health Centers (CMHCs). HFW Facilitators and HFW Supervisors participate in a statewide HFW Learning Collaborative that includes ongoing technical assistance, coaching, and fidelity monitoring to support effective implementation of HFW."
        ]

    },
    
 



            

           
    


];

export default kYImpact_Content;