import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const housingResources_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: <h1 id='Housing'>Housing</h1>
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Community Mental Health Centers"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "Community Mental Health Centers (CMHCs) use a variety of strategies to develop housing options for individuals with serious mental illness.",
            "Some focus on actual housing development by employing regional housing developers; others focus on housing access by administering their own set-aside programs for rental assistance or through collaborative arrangements with local public housing agencies; while others operate more intensive supportive housing programs.",
            "Contact your local CMHC for more information on its housing opportunities.",
            <> Use the "Community Mental Health Centers" link in the left navigation bar for more information.</>
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Olmstead Housing Initiative"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            "The Olmstead Housing Initiative (OHI) is a partnership between the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID), New Beginnings Bluegrass (NBB), Community Mental Health Centers (CMHCs), Wellspring, and Kentucky Housing Corporation for Housing Quality Standards inspections (HQS). It was created in response to the 1999 Supreme Court decision Olmstead v. L.C., 527 U.S. 581, which was an interpretation of Title II of the Americans with Disabilities Act (ADA).",
            "OHI was created to meet the housing needs of persons with serious mental illness (SMI) who are transitioning from long-term stays in an institution, or who are at risk of institutionalization. Efforts have been made to move individuals living independently from institutions into affordable housing in their chosen communities.",
            "Institutions for this population includes, psychiatric hospitals, personal care homes, and nursing homes. OHI can pay for moving expenses, basic furnishings, and security and utility deposits provide an ongoing rental subsidy, and cover other housing-related expenses that are a barrier to transitioning to the community.",
            " Referrals to OHI can be made by CMHCs or other DBHDID contractors listed above."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Other Department-Contracted Housing Organizations"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <>
                <ul>
                    <li><b><a href="http://www.newbeginningsbg.org/">New Beginnings Bluegrass</a>. </b>Provides supported housing services in Lexington/Fayette County to individuals with serious mental illness who are transitioning from an institutional setting to integrated community housing, or who are at risk for institutionalization. New Beginnings links clients to services and activities of their choosing, accessing subsidies, locating suitable housing, negotiating leases, acquiring household items, moving the individual into the residence, and teaching basic life skills and other skills that strengthen recovery and promote community integration.</li>
                    {/* <li><b><a href="http://www.newbeginningsbg.org/">New Beginnings Bluegrass</a></b></li> */}
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: <h1 id='Homelessness'>Homelessness</h1>
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Projects for Assistance in Transitioning from Homelessness Program"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [
            "The Stewart B. McKinney Homeless Assistance Amendments Act of 1990 created the Projects for Assistance in Transition from Homelessness (PATH) program. The PATH program is a federal formula grant distributed annually to all U.S. states and territories.",
            " The PATH program supports the delivery of services and resources to individuals who have serious mental illnesses, may have a co-occurring substance use disorder, and are homeless or at imminent risk of homelessness.",
            "PATH funds provide a menu of allowable services, including street outreach, case management, and services not supported by mainstream mental health programs.",
            "Programs receiving PATH funds target individuals who are experiencing homelessness with a serious mental illness as a priority population and maximize serving the most vulnerable adults who are literally and chronically homeless.",
            "The most recent state application for PATH funds is linked on the right side of this page under Related Links."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "SSI/SSDI Outreach, Access, and Recovery (SOAR) Program"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            "The SOAR program is designed to increase access to Social Security Administration (SSA) disability benefits such as Supplemental Security Income and Social Security Disability Insurance (SSI/SSDI), for eligible adults who are experiencing or are at risk of homelessness and have a mental illness, medical impairment, or a co-occurring disorder.",
            "For people who are homeless or who are returning to the community from institutions (jails, prisons, or hospitals), access to these programs can be extremely challenging. Approval on initial applications for people who are homeless and who have no one to assist them is about 10-15 percent. For those who have a mental illness or co-occurring disorder that impairs cognition, the application process is even more difficult, yet accessing these benefits is often a critical first step in recovery.",
            " The SOAR Online Course is free and features video interviews, medical records, and progress notes to train case managers on completing an SSI/SSDI application for a fictional applicant in a practice case. The SOAR TA Center reviews all application packets and returns them to course participants with valuable feedback and tips. To access the online course, visit the SOAR Technical Assistance Center (see the link in Related Links).",
            " Some communities have local SOAR initiatives. Getting involved locally can be a great way to connect with others doing similar work. For more information on local initiatives, please use the contact information at the upper right of this page to contact the SOAR State Team Lead for Kentucky."
        ]
    },
    {
        format:bodyHelperTypes.title,
        order: 130,
        content: "Homeless Prevention Project"
    },
    {
        format:bodyHelperTypes.paragraphs,
        order: 140,
        content: [
            "This project originated as an attempt to address institutional discharge into homelessness. A survey of Louisville shelters showed that 77.5 percent of the residents had an institutional background.",
            "The project offers institutional discharge planning to persons with mental illness who are exiting state-operated or -supervised institutions, including the prison system and foster care programs.",
            "The project has both an urban and a rural component. Seven Counties Services in Louisville, New Vista in Lexington, and LifeSkills in Bowling Green serve the urban component. The Adanta Group serves the rural component in south-central Kentucky. Coalition for the Homeless Louisville and the Homeless and Housing Coalition of Kentucky coordinate the program.",
            "The project's goal is to assist potentially homeless persons exiting state institutions for return or reentry into the community and to offer information about any necessary linkage to needed community services and supports. These include employment, medical and mental health services, housing, education, social supports, and other community-based services, decreasing the number of persons discharged from state-operated institutions into homelessness."
        ]
    }
];

export default housingResources_Content;