import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const tRIS_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "What is DBHDID-TRIS?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                The Department for Behavioral Health, Developmental and Intellectual Disabilities Training Records Information System (DBHDID-TRIS) is a web-based system for maintaining training information for DBHDID. All information transmitted on this site is secured via a digital secure socket layer (SSL).
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "How do DBHDID Approved Training Providers utilize DBHDID-TRIS?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                After the Training Provider becomes approved to teach a curriculum, they will be contacted by a DBHDID staff member regarding their access to DBHDID-TRIS. Training Providers will use DBHDID-TRIS to upload sign-in sheets and other required documentation for each training conducted. In addition, the training provider will assign credit to each individual that attended and passed the required
                examination to become certified. This important step will create a DBHDID TRIS account for each of those individuals.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "How do PSS, CSA, and TCM utilize DBHDID-TRIS?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            <>
                Peer Support Specialists (PSS), Community Support Associates (CSA), and Targeted Case Managers (TCM) certified in Kentucky will use their unique DBHDID-TRIS account to submit proof of their required yearly 6.0 hours of continuing education, as outlined in the appropriate Kentucky Administrative Regulation (KAR), to the DBHDID.
            </>,
            <>
                Following the PSS, CSA, or TCM certification training through an approved DBHDID Training Provider, an individual account is created for each person that became certified. The individual shouldwill then:
            </>,
            <>
                <ol>
                    <li>Receive an email from csa@ky.gov.</li>
                    <li>Check their spam/junk folder for the email if they didn’t receive it.</li>
                    <li>Click the link in the email to confirm their account.</li>
                    <li>Set up their password per the instructions in the email.</li>
                    <li>Receive another email that will contain their username.</li>
                </ol>
            </>,
            <>
                All trainings (certification trainings and CE trainings) entered into an account, will go onto the individual’s DBHDID TRIS Training Record. DBHDID-TRIS accounts will follow the individual no matter where they are employed and employers do not have access to these accounts.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Additional Questions?"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "For additional questions regarding DBHDID-TRIS, please refer to the DBHDID-TRIS FAQs",
            "Contact the Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) for any other questions or comments:",
            <>
                DBHDID <br />
                275 E. Main Street 4CD <br />
                Frankfort, KY 40621<br />
            </>
        ]
    }
];

export default tRIS_Content;