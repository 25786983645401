import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import PESLogo from "../../../assets/img/sud/PESLogo.png"
import Picture from "../../../layouts/bodyLayouts/helpers/Picture";

const YGEContent = [
    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Youth Empowerment Grant is a funding opportunity for Kentucky youth groups (with an adult advisor) to plan and implement substance use/misuse prevention activities in their local communities.",
            "This funding opportunity allows youth groups, while being supported by their adult advisors, to take the lead in preventing substance use/misuse among their peers and in their local community. In addition to being supported by adult advisors, the group will also be provided technical assistance from the Regional Prevention Center (RPC) throughout the life of the grant.", 
            <>To gauge interest, as it relates to this funding opportunity, we have a short survey. Please find the survey link here: <a href="https://ky.readyop.com/fs/4oro/cde4efa8">https://ky.readyop.com/fs/4oro/cde4efa8 .</a>
            <br/>
            <br/>
            </>
    ]

        

    },
                ];

 export default YGEContent;