import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";

const guidePrinciplesContent = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Anti-Discrimination and Anti-Stigma"
    },
    
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: <><Links linkTypeID={"135"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Self-Advocacy"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>Self-Advocacy is "a lifelong personal pursuit of control over one's own circumstances. It is the act of advocating for what one wants, for how one desires to live, for how one wants to be treated, within one's family, community and services, and it does not end with one concession or one victory." <br/>
            <br/>
            <i>Bonnie Shoultz, Center on Human Policy</i>
            </>,
            "Please access the links below to learn more about self-advocacy.",
            <><Links linkTypeID={"133"} /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Self-Determination"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,  
        content: [
            "Self-determination is an international concept for all people everywhere. Relationships and interdependence in our communities is essential. All people can be self-determined even those perceived to have the most severe disabilities, who may or may not communicate with traditional speech. The first step is building those connections and relationships. Please access the links below to learn more.",
            <><Links linkTypeID={"131"} /></>
        ]
    },
    



];

export default guidePrinciplesContent;