import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import PESLogo from "../../../assets/img/sud/PESLogo.png"
import Picture from "../../../layouts/bodyLayouts/helpers/Picture";
import Links from "../../../components/Links";
import OrderedList from "../../../layouts/bodyLayouts/helpers/OrderedList";

const eBPINiatx = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "NIATx"



    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "NIATx, formerly The Network for the Improvement of Addiction Treatment, is a partnership between the Robert Wood Johnson Foundation's Paths to Recovery program, the Center for Substance Abuse Treatment's Strengthening Treatment Access and Retention (STAR) program, the National Institute on Drug Abuse, and a number of independent addiction treatment organizations.",
            "NIATx works with addiction treatment providers to make more efficient use of their capacity and shares strategies for improving treatment access and retention.",
            "The Division of Behavioral Health (DBH) is working collaboratively with the University of Wisconsin–Madison's Center for Health Enhancement Systems Studies, where the national NIATx technical assistance center is located, to promote the use of the NIATx model as a performance improvement strategy within Kentucky's public substance abuse and mental health service system.",
            "The model is based on evidence-based practices in process improvement gleaned from a variety of fields, including engineering, business and social services. It is understood that improving access to and retention in treatment can lead to better client outcomes.",
            "DBH is promoting the use of NIATx as a practice improvement tool because it has been shown to lead to increased client satisfaction and substantial change. The four aims of NIATx are to:",

            <><UnorderedList items={[
                "Reduce waiting time.",
                "Reduce no-shows",
                "Increase admissions.",
                "Increase continuation."
            ]} /></>, 
            "The NIATx model is based on five key principles:",

            <><OrderedList items={[
                "Understand and involve the customer.",
                "Fix key problems that keep the CEO awake at night.",
                "Pick a powerful change leader.",
                "Get ideas from outside the organization or field.",
                "Use rapid-cycle testing to establish effective changes."
            ]} /></>,

            "Use the Contact Information to obtain materials and further information."
        ]



    },

    {
        format: bodyHelperTypes.title,
        order:30,
        content: <><Links linkTypeID="169"/></>
    }
    


];

export default eBPINiatx;