import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";




const pASSRR_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [

            "The Omnibus Reconciliation Act of 1987 (OBRA) and P.L. 100-203, Section 4211(c)(7), and OBRA 1990 contain provisions with major implications for persons with mental illness or intellectual disabilities who are applying to or residing in a nursing facility.The provisions were designed to eliminate the practice of inappropriately placing persons with mental illness, intellectual disabilities and related conditions in Medicaid- certified nursing facilities.",
            "This Act mandates the department, as the state mental health and intellectual disability authority, to establish preadmission screening and resident review (PASRR) for all persons applying for admission to or residing in a nursing facility. Through the PASRR evaluation, the department determines whether (1) the person meets PASRR criteria (2)the person requires a nursing facility level of care, if so, (3) whether the person also requires specialized services (active treatment).",
            <>

                Specifically, the PASRR program must ensure that the following conditions are met.
                <br/>
                <br/>

                <ul>

                    <li>
                        As a result of this preadmission screening, referred to as the Level I, persons who appear to have a mental illness, intellectual disability or related condition will undergo a comprehensive evaluation, referred to as the Level II, to determine the need for nursing facility placement and specialized services.

                    </li>

                    <li>

                        As of January 1, 1989, no person may be admitted to a Medicaid-certified nursing facility without first being screened for mental illness and intellectual disabilities. This provision applies to the source of nursing facility placement.

                    </li>

                </ul>



            </>

        ]
    }










];

export default pASSRR_Content;