import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const cMTraining_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                Case management training will provide information about person-centered processes and relationship building to waiver program case managers.
                The training will empower case managers to facilitate effective team meetings that help people in a waiver program to move from a service life to a community life.
            </>,
            <>
                Use the links below for information about enrollment.
                <br /> <br />
                <Links linkTypeID="410" />
            </>
        ]
    },
];

export default cMTraining_Content;