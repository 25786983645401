//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
import useApi from "../utils/UseAPI";
import SanitizeHref from "../utils/SanitizeHref";


function DocumentsDropdown({ typeID, ...props }) {

    const { error, processing, getRequest } = useApi();
    const [documentsDocs, setDocumentDocs] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "document");
            if (status == 200) {
                // console.log(data.data);
                FormFilter(data.data);               
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);

    function FormFilter(formsData) {

        const generalFilter = [];

        formsData.forEach((form) => {
            if (form.typeId.toString() === typeID) {
                generalFilter.push(form);
            }
        })
        setDocumentDocs(generalFilter);      
    }
    return (
        <>
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    window.location = e.target.value;
                }}>
                    <option>Select a meeting date</option>
                    {
                        documentsDocs.map((data, index) => {
                            console.log(data)
                            return (
                                <option key={index} value={SanitizeHref(data.url)} className="form-select"
                                >{data.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        </>
    );
}

export default DocumentsDropdown;