import React, { useState, useEffect } from 'react';
import { Table, Form, Pagination } from 'react-bootstrap';
import useApi from "../../utils/UseAPI";

// React loading spinner
import Spinner from 'react-bootstrap/Spinner';


const IBITableTwo = ({ endpointKey, columns, dataProcessingAction, searchPredicate }) => {

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15);
    const { processing, error, getRequest } = useApi();
    let tempLink = '';
    

    const get = async () => {
        if (endpointKey != null) {
            const [data, status] = await getRequest(null, endpointKey);
            if (status == 200) {

                if (dataProcessingAction != null && dataProcessingAction != undefined && typeof dataProcessingAction == "function") {
                    dataProcessingAction(data.data);
                }
                setData(data.data);
                setFilteredData(data.data);
            }
        }
    };

    // Fetch data asynchronously
    // Separate effect to account not require a refresh prop
    useEffect(() => {
        get();
    }, []);




    // Apply filtering based on user input
    const handleFilter = (value) => {

        const validSearch = value != null && value.replace(/\s/g, "").length > 0;

        if (searchPredicate != null && searchPredicate != undefined && typeof searchPredicate == "function" && validSearch) {
            const trimmed = value.trim();
            const filtered = data.filter((item) =>
                searchPredicate(item, trimmed)
            );
            setFilteredData(filtered);
        }


    };

    // Apply sorting based on column header click
    const handleSort = (column) => {
        if (column === sortBy) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortDirection('asc');
        }
    };

    // Paginate data
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableSort = (a, b, sortBy, direction) => {

        // console.log(`a: ${a[sortBy]}`);
        // console.log(`b: ${b[sortBy]}`);
        // console.log(`sortBy: ${sortBy}`);
        // console.log(`direction: ${direction}`);

        const options = {
            numeric: true,
            ignorePunctuation: true
        };

        if (a[sortBy] == null && b[sortBy] == null) {
            return 0;
        }

        const switchKey = a[sortBy] == null ? typeof b[sortBy] : typeof a[sortBy];

        try {
            switch (switchKey) {
                case "string":
                    const valueA = a[sortBy] == null ? "\0" : a[sortBy];
                    const valueB = b[sortBy] == null ? "\0" : b[sortBy];
                    const dateA = Date.parse(valueA);
                    const dateB = Date.parse(valueB);
                    if (!isNaN(dateA) && !isNaN(dateB)) {

                        // console.log(`Date A: ${dateA}`);
                        // console.log(`Date B: ${dateB}`);
                        return direction == "asc" ? dateA - dateB : dateB - dateA;
                    } else {
                        return direction == "asc" ? valueA.localeCompare(valueB, "en", options) : valueB.localeCompare(valueA, "en", options);
                    }
                case "number":
                    return direction == "asc" ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
                default:
                    return 0;
            }
        } catch (error) {
            return 0;
        }
    };
    const arrayFilter = (linkURL) => {
        // console.log(linkURL);
        const  linkURLArray = linkURL.split(" ");
        // console.log(linkURLArray);
        for (let index = 0; index < linkURLArray.length; index++) {
            if (linkURLArray[index].includes('-')){   
                tempLink = "tel:"+ linkURLArray[index];           
            }        
            else if(linkURLArray[index].includes('http://') || linkURLArray[index].includes('https://')){
                tempLink = linkURLArray[index];
            }    
        } 
    }
    // Get paginated items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const sortedItems = filteredData.sort((a, b) => tableSort(a, b, sortBy, sortDirection));
    const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

    return processing ? <Spinner animation="border" variant="success" /> :
        <>

            <div>
                <Form.Group controlId="searchForm">
                    <Form.Control
                        type="text"
                        placeholder="Search for the nearest location by entering your county name "
                        onChange={(e) => handleFilter(e.target.value)}
                    />
                </Form.Group>

                <Table responsive size="sm">
                    <thead>
                        <tr>
                            {
                                columns.sort((a, b) => a.order - b.order).map((column) => {
                                    const handleSortWrapper = () => {
                                        handleSort(column.property);
                                    };
                                    return (
                                        <th key={column.order} onClick={handleSortWrapper}>
                                            <div >{column.display} {sortBy === column.property && (sortDirection === 'asc' ? '▲' : '▼')}</div>
                                        </th>
                                    );
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className='table-group-divider'>
                        {currentItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {
                                        columns.sort((a, b) => a.order - b.order).map((column) => {
                                        arrayFilter(item[column.property]);
                                            return (
                                            <td key={column.order}><div><a href={tempLink}>{item[column.property]}</a></div></td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                {/* Pagination */}
                <Pagination>
                    {data.length > itemsPerPage &&
                        Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }).map((_, index) => (
                            <Pagination.Item
                                key={index}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                </Pagination>
            </div>
        </>
        ;
};

export default IBITableTwo;
