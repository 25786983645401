import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import JCICList from "../../../components/JCICList";



const jCIC_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Purpose"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                <ol>
                    <li>The primary purpose of this committee is to serve Kentuckians who need public mental health, intellectual disability or substance abuse services.</li>
                    <li>Access, continuity and effectiveness must be continuously improved, to provide the most valuable public mental health, intellectual disability and substance abuse services.</li>
                    <li>Timely, accurate and actionable information is essential for appropriate management.</li>
                </ol>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Mission"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "Through regular collaborative discussions, the Joint Committee makes recommendations that ensure the appropriateness and improve the value of information available to:",
            <>
                <ul>
                    <li>Agencies serving consumers of public mental health, intellectual disability or substance abuse services in Kentucky.</li>
                    <li>Consumers and family members.</li>
                    <li>State and federal agencies.</li>
                    <li>Accrediting organizations.</li>
                    <li>Citizens of the commonwealth.</li>
                </ul>
            </>,
            "The committee gives the highest regard to the confidential and sensitive nature of the information with which it is concerned."
        ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            "The committee makes policy recommendations concerning information management to the department's Executive Management Team. These recommendations are related to issues affecting the operation of both the department and the Community Mental Health Centers (CMHCs).",
            "The committee facilitates the development of data-related contract items between the department and CMHCs. A central function of the committee is to provide direction and assistance in the development of an information system to manage a public behavioral health system."
            
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Membership"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 70,
        content: <><b>Zara Bullock</b>, Chair/Facilitator, DBHDID, Division of Behavioral Health
        Director and Representatives, Division of Administration and Financial Management
        Director and Representatives, Division of Behavioral Health
        Director and Representatives, Division of Developmental and Intellectual Disabilities
        A representative from each Community Mental Health Center
        Representatives from UK Research and Data Management Center</>
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "JCIC Meeting Minutes"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 90,
        content: <><JCICList /></>
    },






];

export default jCIC_Content;