import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import CenterList from "../../../components/CenterList";

const centers_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                Select a regional center from the dropdown list to view information for that region.
                <br />
                <br />
                <CenterList />
                <br />
            </>
        ]
    },







];

export default centers_Content;