import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const wDYE_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Kentucky’s Division of Substance Use Disorder Prevention and Promotion Branch supports the development of a strong prevention workforce in Kentucky through our Intern and Youth Empowerment Programs.",
        ]
    }, 
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Intern Program"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "The Prevention and Promotion Branch offers paid internships to undergraduate and graduate students interested in learning about substance use/misuse prevention. The goal of the internship is to cultivate prevention professionals who may seek to become Certified Prevention Specialists in the future. Interns participate in prevention activities and work with a Site Placement Supervisor to develop a tangible project related to primary prevention of substance use/misuse.  Applicants with yearlong or academic year availability will receive preference over single-semester applicants. No internship/practicum course is required to participate. Some overnight travel may be required and interns must have reliable transportation.  If you would like more information about the internship, please reach out to Laura Edwards (laura.edwards@ky.gov).",
        ]
    }, 
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Youth Empowerment Program"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            "The Youth Empowerment (YE) Program has three primary goals: to engage young people in the science of prevention through school and community-based activities, build capacity among youth groups to plan, implement, and evaluate substance use and misuse prevention programs in their communities, and educate groups on substance use/misuse and suicide prevention using the Youth Substance Use Prevention Skills (YSUPS) training.",
            "The YSUPS training helps to equip young people with the knowledge, skills, and confidence to be effective advocates and leaders in their communities. Through this training, youth learn about prevention strategies and gain hands-on experience working on prevention initiatives.",
            "Activities of the youth groups may include, but are not limited to, educating peers on substance use/misuse, advocating for smoke/vape-free schools and sporting arenas, raising community awareness through media, educating key leaders and policymakers, advocating for social host ordinances to combat underage alcohol use, and working to change the culture and social norms of substance use/misuse in their schools and communities.",
            "We also recognize the importance of youth empowerment and youth engagement in local community prevention efforts. Prevention specialists at each of Kentucky’s 14 Regional Prevention Centers work closely with local youth and community coalitions to increase youth involvement and representation.",
            "By involving young people in prevention efforts, we are ensuring that prevention initiatives are informed by the perspectives and experiences of those they aim to serve.",
            <>The Youth Empowerment Program offers mini-grants of up to $1,500/year for youth groups to support their prevention activities. For more information, contact your local Regional Prevention Center. <a href='https://dbhdidtest.ky.gov/documents/dbh/KYRPCContactInfo.pdf'>(link to pdf document KY RPC Contact Info)</a></>
        ]
    }, 
];

export default wDYE_Content;