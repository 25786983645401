import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import Links from "../../../components/Links";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";



const adultSA_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "About Us"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Adult Substance Use Treatment and Recovery Services Branch oversees state and federal funds that support community-based providers, such as the 14 Community Mental Health Centers (CMHCs) to deliver substance use disorder (SUD) treatment and recovery support services statewide.",
            "In addition, the branch supports several specialty programs that focus on key populations, coordinates efforts to build a recovery-oriented system of care across the lifespan, and provides guidance and technical assistance on the implementation of evidence-based practices."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Program Areas"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <><Links linkTypeID="217"/></>
    },
    


];

export default adultSA_Content;