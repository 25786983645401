import IsNullOrWhiteSpace from "./IsNullOrWhiteSpace";

function ContactInfoFax({fax}) {

    const li = <><i className="fa-li fa fa-fax" aria-hidden="true"></i><a className="fax" href={"tel:" + fax}>{fax}</a><br />

    </>;

    return <IsNullOrWhiteSpace arg={fax} notNullComponent={li} />
}

export default ContactInfoFax;