import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DocumentsDropdown from "../../../components/DocumentsDropdown";

const BHPACSummariesContent = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Meeting Summaries"
    },
    {
        format: bodyHelperTypes.paragraphs, 
        order: 20,
        content: [
            "The council generally meets at least four times a year, with numerous committee meetings held throughout the year.",
            "Select a meeting date from the dropdown list below to view a summary of any meeting held from 2014 forward.",
            <></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Planning Council"
    },

    {
        format: bodyHelperTypes.paragraph, 
        order: 40,
        content: <><DocumentsDropdown typeID="1" /><br/></>
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Advocacy and Policy Committee"
    },

    {
        format: bodyHelperTypes.paragraph, 
        order: 60,
        content: <><DocumentsDropdown typeID="18" /><br/></>
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Bylaws Committee"
    },

    {
        format: bodyHelperTypes.paragraph, 
        order: 80,
        content: <><DocumentsDropdown typeID="10" /><br/></>
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Executive Committee"
    },

    {
        format: bodyHelperTypes.paragraph, 
        order: 100,
        content: <><DocumentsDropdown typeID="11" /><br/></>
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Finance and Data Committee"
    },

    {
        format: bodyHelperTypes.paragraph, 
        order: 120,
        content: <><DocumentsDropdown typeID="12" /><br/></>
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Membership Committee"
    },

    {
        format: bodyHelperTypes.paragraph, 
        order: 140,
        content: <><DocumentsDropdown typeID="13" /><br/></>
    }
];

export default BHPACSummariesContent;