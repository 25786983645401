import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import DataGuideList from "../../../components/DataGuideList";


const dataGuide_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "2025 Data Implementation Guide"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: <><Links linkTypeID={"266"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "2024 Data Implementation Guide"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: <><Links linkTypeID={"203"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Reports"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [<><b>Note: </b>These reports pop up in a new tab. If a report does not open, you might have to disable your popup blocker or allow popups from this site.</>,

        <b>Please use the newest browser version (Internet Explorer 10 contemporary or newer).</b>

        ]
    },
    {

        format: bodyHelperTypes.paragraph,
        order: 70,
        content: <><DataGuideList/></>
    },







];

export default dataGuide_Content;