import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import SCLServiceList from "../../../components/SCLServiceList";

const sCLServices_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                For links to regulations regarding SCL services and reimbursement, see "SCL Regulations" in Related Links.
            </>,
            "The Supports for Community Living Waiver Branch offers the following supports and services. These are based on the individual's needs and are identified through an individual plan.",
            "Supports for Community Living (SCL) service can be delivered in the ways described below. Note that certain services are exclusive to the type of delivery chosen.",
            <>
                <ul>
                    <li>
                        <b>Participant-directed.</b> This allows the participant to assist with the design of the participant's programs, and to recruit, hire, train, manage, evaluate and terminate staff, and direct the delivery of services to meet the participant's needs. The services are specifically nonresidential and nonmedical.

                    </li>
                    <li>

                        <b>Traditional.</b> Unlike with participant-directed services, the agency supporting the participant recruits, hires, trains, manages, evaluates and terminates staff.

                    </li>
                    <li>
                        <b>Blended.</b> This is a combination of different traditional and participant-directed services. For example, a person may choose to receive case management and physical therapy under traditional and choose to receive respite and personal care assistance under participant-directed services.

                    </li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "List of Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                <b>Note:</b> "A person" refers to someone who is receiving Supports for Community Living (SCL) Waiver funding. Within the regulations, a person is referred to as a participant.
            </>,
            <>
                If <b>*</b> appears before a service name in the descriptions, it indicates that a potential provider cannot be certified to provide the service. It is a service that is coordinated by, or its funding is secured by, the Case Manager or by a participant's representative.
                {/* <Links linkTypeID="240" /> */}
                <br />
                <br />


                <SCLServiceList />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Service Providers"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "SCL providers include regional comprehensive care centers and public and private agencies across the state. These providers are reviewed and certified by the Department for Behavioral Health, Developmental and Intellectual Disabilities initially and at least every two years thereafter, in accordance with the standards and requirements set forth by the Kentucky Department for Medicaid Services.",
            "SCL providers are continuously monitored to maintain quality of supports."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "How to Obtain Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            <>
                The first step is to complete an application in benefind (see the "benefind" link in Related Links) after creating an account OR ask a provider to help you with the application (see the "Provider Directory" link in Related Links). There is a waiting list for services and supports. An application will allow your name to be included on the list if you meet the criteria to receive SCL services and supports. An application will allow your name to be included on the list if you meet the criteria to receive SCL services.
            </>,
            <>
                Additional individuals are served based on urgency of need and chronological order, as funding becomes available. After you are selected for funding, you must then be determined to meet the level of care provided by an Intermediate Care Facility for Individuals with Intellectual Disabilities (ICF/IID) and to be eligible for the correct type of Medicaid.
            </>
        ]
    },
];

export default sCLServices_Content;

