import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import CountyList from "../../../components/CountyList";


const cMHCHome_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Regional Boards for Mental Health or Individuals With an Intellectual Disability"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Publicly-funded community services are provided for Kentuckians who have problems with mental health, developmental and intellectual disabilities, or substance abuse, through Kentucky's 14 regional Boards for Mental Health or Individuals With an Intellectual Disability (Regional MHID Boards). Regional MHID Boards are private, nonprofit organizations established by KRS Chapter 210 (see Related Links) which serve residents of a designated multi-county region.",
            "For information about mental health, developmental and intellectual disabilities, or substance abuse services, contact the local office of your Regional MHID Board. For contact information for the office for your county, select and click on your county on the dropdown list below.",

            <>

                <b>County List: </b> <br /><br />
                <CountyList />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Consumer Perception of Care Survey"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [

            <><Links linkTypeID="249" /></>

        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Further Information"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [

            "See Related Links for information about regional boards, including links to their websites, if available.",
            "See Related Links for a list of contact information by county for all regional board offices, crisis lines, and corresponding state hospitals.",
            "If you have a problem with or inquiry about mental health, developmental and intellectual disabilities, or substance abuse, that cannot be handled by a local or regional office, get further information and contact information by clicking on one of the links below.",
            <><Links linkTypeID="263" /></>

        ]

    },






];

export default cMHCHome_Content;