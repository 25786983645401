import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const oxfordHouse_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Oxford House is a concept in recovery from drug and alcohol addiction. In its simplest form, an Oxford House describes a democratically run, self-supporting and drug free home. Parallel to this concept lies the organizational structure of Oxford House, Inc. This publicly supported, non-profit 501(c)3 corporation is the umbrella organization that provides the network connecting all Oxford Houses and allocates resources to duplicate the Oxford House concept where needs arise.",
            "Oxford Houses are established to be self-sustaining recovery homes. To be chartered: (1) the house must be democratically self-run, following the policies and procedures of the Oxford House Manual© (see Related Links); (2) the group must be financially self-supporting; and (3) the group must immediately expel any resident who returns to using alcohol or illicit drugs. Evicted residents are encouraged to seek treatment and reapply for admittance into the home following completion of a treatment program.",
            "Kentucky currently has over one-hundred Oxford Houses throughout the Commonwealth. The number of residents in a house may range from six to twelve; there are houses for men, women, women with children, and  men with children.",
            "See Related Links for more information"
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Help and Crisis Lines"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
                <ul>
                    <li>Mental Health Crisis Hotline: (800) 221-0446</li>
                    <li>Substance Abuse Treatment Referral: (888) 729-8028</li>
                    <li>Veterans Administration Benefits: (800) 827-100</li>

                </ul>
            </>
        ]

    }
];

export default oxfordHouse_Content;