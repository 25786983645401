import { useState } from "react";
import Axios from 'axios';
import aPIURLStore from "./stores/aPIURLStore";
// import endpointMap from "../util/api-endpoint-map";
// import useUserStore from "../stores/user-store";

// Server Context for website.
const useApi = () => {

    
    const baseUrl = aPIURLStore( state => state.baseURL);
    //const baseUrl =  "https://localhost:3000/";
    // const baseUrl = "https://dbhdidtest.ky.gov/api/";
    // const baseUrl = "https://dbhdid.ky.gov/api/";
    //const baseUrl = "https://fims.dbhdid.ky.gov/api/";
    // const sleep = (milliseconds=1000) => new Promise(resolve => setTimeout(resolve, milliseconds));

    // const jwt = useUserStore((state) => state.jwt);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    // const mapEndpoints = endpointMap;
    const methods = {
        get: "get",
        post: "post",
        put: "put",
        delete: "delete"
    };

    const procRequest = async (indata, params, method, endpoint) => {
        setError(null);
        setProcessing(true);

        // await sleep();
        // setProcessing(false);
        // return [null, 200];

        return Axios({
            method: method,
            url: baseUrl + endpoint,
            data: indata,
            params: params,
            responseType: "json",
            
        }
        ).then((response) => {
            setProcessing(false);
            //console.log(response);
            return [response.data, response.status];
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError(error.message);
                }

                setProcessing(false);
                return [null, error.response.status];
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
                setError(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                //console.log('Error', error.message);
                setError(error.message);
            }
            setProcessing(false);
            return [null, 999];
        });

    };

    // Process the processes POST request
    const procPostRequest = async (indata, endpointKey) => {
        return await procRequest(indata, null, methods.post, endpointKey);
    };
    // Process the processes GET request
    const procGetRequest = async (params,endpoint) => {
        return await procRequest(null, params, methods.get,endpoint);
    };

    // const procGetRequestNoAuth = async (endpointKey, params) => {
    //     return await procRequest(endpointKey, null, params, methods.get, false);
    // };

    // const procPutRequest = async (endpointKey, indata) => {
    //     return await procRequest(endpointKey, indata, null, methods.put, true);
    // };

    // const procDeleteRequest = async (endpointKey, indata) => {
    //     return await procRequest(endpointKey, indata, null, methods.delete, true);
    // };

    return {
        error: error,
        processing: processing,
        postRequest : procPostRequest,
        getRequest: procGetRequest,
        // getRequestNoAuth: procGetRequestNoAuth,
        // putRequest: procPutRequest,
        // deleteRequest: procDeleteRequest
    };

};

export default useApi;
