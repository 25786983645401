import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import DPRFormsLibrary from "../../components/DPRFormLibrary";

const dPRFormsLibrary_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><DPRFormsLibrary /></>
        ]
    },
];

export default dPRFormsLibrary_Content;