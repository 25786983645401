import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const tGD_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "Too Good for Drugs (TGFD) is an evidence based, Tier 1, K-12 prevention education program designed to mitigate risk and enhance protection related to alcohol, nicotine, and other drug use/misuse.  Too Good for Drugs teaches five essential social & emotional learning skills which research has linked with healthy development and academic success. Students build their skills from lessons infused with games, activities, and skits.",
            "The Too Good for Drugs curriculum is designed for students in kindergarten through high school and ideally implemented in every grade to have the most effect. If consecutive implementation is not possible, schools are encouraged to look at transitional grades for possible implementation, while also considering the age of onset for use of alcohol, tobacco, nicotine, and other drugs when deciding what grade levels in which to implement.  The curriculum consists of 10 lessons that are 30 minutes to one hour in length and should be conducted in a classroom setting with no more than 30 students.",
            "The program is well suited for implementation in Health, Social Studies, Science, or other related classes.",
            "The Division provides materials for schools interested in implementing TGFD, and Regional Prevention Centers staff are available to train school staff members to deliver the curriculum.  The materials and training are free to schools. Participating schools will be expected to work with the Regional Prevention Centers (RPC) to review their current policies related to behavioral health, substance use/misuse and suicide prevention and participate in required evaluation processes conducted by REACH of Louisville.",
            <>
                TGFD has been aligned with:

                <ul>
                    <li>Kentucky and National Health Standards and Performance Indicators 2,3,4,5,6, & 7</li>
                    <li>PBIS (Positive Behavioral Supports and Intervention framework)</li>
                    <li>RTI (Response to Intervention)</li>
                </ul>
            </>,
            <>
                Learn more: <a href='https://toogoodprograms.org/'>https://toogoodprograms.org/</a>
            </>,
            <>
                Questions or information: <a href='mailto: sheila.barnard@ky.gov'>sheila.barnard@ky.gov</a>
            </>
        ]
    },
];

export default tGD_Content;