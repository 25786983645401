import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import DropdownList from "../../../components/DropdownList";

const commissionDocuments_Content = [

   
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Kentucky Commission on Services and Supports for Individuals with an Intellectual Disability and Other Developmental Disabilities meets at least biennially."
            
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Meeting Minutes"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "The list below contains links to minutes for past meetings of the commission. To view minutes, select the meeting date from the dropdown list.",
            <><DropdownList linkTypeID={243} /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Meeting Materials"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            "The list below contains links to meeting materials distributed to commission members, beginning with the December 2015 meeting. To view materials, select the meeting date from the dropdown list.",
            <><DropdownList linkTypeID={533} /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Annual Status Reports"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            "The list below provides links to reports that document past meetings of the commission. To view a report, select its date from the dropdown list.",
            <><DropdownList linkTypeID={244} /></>
        ]
    }
];

export default commissionDocuments_Content;