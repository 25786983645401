import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const headedHome_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "OHH received the National Balderson Public Health Leadership Award"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Representatives from federal, military, state and professional organizations across Kentucky unite to host the annual Operation Headed Home (OHH) Conference",
            "This statewide conference addresses the needs of Service Members, Veterans and Their Families (SMVF), caregivers, and behavioral and medical health professionals, with information, resources, and support regarding Traumatic Brain Injury (TBI), Post-Traumatic Stress Disorder (PTSD) and suicide prevention.",
            <><b><i>I attended the Operation Headed Home Conference at Fort Campbell. I just wanted to let you know how proud I was to see such a "Top Drawer" program offered by my colleagues.</i></b> — Participant</>
        ]
    },
];

export default headedHome_Content;