import React from 'react';

import { useEffect } from 'react';

const GoogleTranslateBar = () => {

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
    
        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        // layout: window.google.translate.TranslateElement.InlineLayout.Vertical,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);


  return (
    <>
        <div  className='container mobile-google-align ' id='google_translate_element'></div>
    </>
  );
};

export default GoogleTranslateBar;