import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const dDIDHome_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Mission Statement"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "It is the mission of the Division of Developmental and Intellectual Disabilities (DDID) to empower each person to realize his or her place in the community as a citizen of the Commonwealth of Kentucky. To accomplish this mission, DDID will partner with and support persons with intellectual or developmental disabilities, families, advocates, stakeholders and government agencies."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Vision"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "People, throughout their lifespan, are active, contributing and respected members of their community. People have the ability to choose from a full array of effective and meaningful natural and paid supports to access what is important for them and to access and maintain what is important to them."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Values"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            "The following principles guide the work of DDID staff as we strive to make a positive difference in the lives of all individuals with whom we come in contact."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Person-Centered Thinking and Communities"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "Person-centered thinking drives our actions by emphasizing the importance of each person. Communities are the first places to seek, identify and nurture supports and opportunities."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Participation in the Community"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [
            "We affirm that all people can direct their own supports and thereby encourage and promote true and meaningful participation in the community as essential to person-centered practices."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Employment"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            "Individuals of working age are employable: employment is life-enriching."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Direct Support Professionals"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 140,
        content: [
            "We recognize and embrace Direct Support Professionals as essential team members whose input is valued and respected at all levels of planning and implementation of services pertinent to individuals they support."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 150,
        content: "Natural Supports"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 160,
        content: [
            "We promote and respect the involvement of family members and natural supports to empower individuals in the pursuit of active meaningful participation within their community."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 170,
        content: "Best Practice"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 180,
        content: [
            "Initiatives and supports are driven by research and data to include the array of promising, evidence-based, and identified best practices."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 190,
        content: "DDID Branches"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 200,
        content: [
            <>
                DDID comprises three branches:

                <br />
                <br />
                <Links linkTypeID="789" />

            <br />

            </>
        ]
    },
];

export default dDIDHome_Content;