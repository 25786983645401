//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
import ContactInfoPhone from "./ContactInfoPhone";
import ContactInfoFax from "./ContactInfoFax";
import ContactInfoAddress from "./ContactInfoAddress";
import ContactInfoPerson from "./ContactInfoPerson";
import ContactInfoTollFree from "./ContactInfoTollFree";

function ContactInfo({ pageID }) {

    const {error, processing, getRequest } = useApi();
    // Store phone/fax info
    const [phoneInfo, setPhoneInfo] = useState([]);
    // Store TollFree info
    const [tollFreeInfo, setTollFreeInfo] = useState([]);
    // Store fax info
    const [faxInfo, setFaxInfo] = useState([]);
    // store Address info
    const [addressInfo, setAddressInfo] = useState([]);
    // Store Staff info
    const [contactInfo, setContactInfo] = useState([]);
    // const [responsibleStaff, setResponsibleStaff] = useState([]);

    useEffect(() => {

        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "contact/" + pageID);      
    
            if (status == 200) {
                setPhoneInfo(data.data.phones);
                setTollFreeInfo(data.data.phones);
                setFaxInfo(data.data.phones);
                setContactInfo(data.data.contacts);
                setAddressInfo(data.data.addresses);
            }
            else {
                // console.log(error);
            }

        };
        fetchLinks();
    }, [pageID]);

    const phoneListItems = phoneInfo.map((data, index) => 
        <li title={data.phone} key={index}>
            <ContactInfoPhone phone={data.phone}/>         
        </li>
    );
    const faxListItem = faxInfo.map((data, index) => 
        <li title={data.fax} key={index}>
            <ContactInfoFax fax={data.fax}/>           
        </li>
   
    );
    const tollFreeListItem = tollFreeInfo.map((data, index) => 
    <li title={data.tollFree} key={index}>      
            <ContactInfoTollFree  tollFree={data.tollFree}/>           
        </li>
    );
 
    const addressListItems = addressInfo.map((data, index) => 
        <ContactInfoAddress
            key={index}
            street={data.address} 
            city={data.city} 
            state={data.state} 
            zip={data.zip}
        />
    );

    const personListItems = contactInfo.map((data, index) => 
        <ContactInfoPerson 
            key={index}
            email={data.staff.phone} 
            name={`${data.staff.firstName} ${data.staff.lastName}`}
            title={data.title}
            phone={data.staff.email}
        />
    );

    return (
        <>
            {/* <div className="col-md-12 grid-cell-first"> */}
                <b><p title="Contact Information" className="sectionTitle bordered">Contact Information</p></b>
                <div className="row grid-row-2 contacts">
                    <div className="col-md-11 grid-cell-first">
                        {
                            processing ? 
                                <Spinner animation="border" variant="success" /> : 
                                <ul className="fa-ul">
                                    {addressListItems}
                                    {phoneListItems}
                                    {faxListItem}
                                    {tollFreeListItem}
                                    {personListItems}
                                </ul>
                        }
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}

export default ContactInfo;
