import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const mHPromotion_Content = [

    {
        format: bodyHelperTypes.paragraph,
        order: 10,
        content: "Mental wellness is more than simply the absence of diagnosable mental health conditions.  Rather, it encompasses overall emotional, psychological, and social wellbeing.  Mental wellness impacts how we think, feel, and act and helps to determine how we handle stress, relate to others, and make choices.The goal for the Mental Health Promotion program is to build resilience and life skills and promote connectedness to support mental health wellness of all Kentuckians."
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Mental Health Promotion Resource Kits"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [

            

            <>
            
            <>Browse selected Mental Health Promotion Resources below. To view the full collection, visit <a href="https://drive.google.com/drive/folders/15oWTOSbsdMBM3XPw5uYtSQrZTNsUOjqp">tinyurl.com/MHPResources</a>.</> <br/><br/>
            <a href="https://dbhdid.ky.gov/documents/dbh/UnderstandingMentalWellness.pdf">Wellness Resources Guide</a><br /><br />
                <a href="https://kyprevention.getbynder.com/m/6de9ee69363ae789/original/BIPOC-Mental-Health-Month-July-2024.pdf">BIPOC Mental Health Month Resource Kit</a><br /><br />
                Description: July is BIPOC Mental Health Month, a time to focus on supporting Black, Indigenous, and people of color mental wellness. This mini resource guide provides general information about how to achieve and maintain mental wellness, including how to seek culturally competent mental health care and how culturally-based practices can help to support mental wellness.Visit <a href="https://mhanational.org/bipoc">https://mhanational.org/bipoc</a> for more information and resources.
                <br />
                <br />
                To view the entire collection of Mental Health Promotion and Suicide Prevention resources, visit <a href="https://bit.ly/44bNJOu">https://bit.ly/44bNJOu</a>.
            </>,
            <><a href="https://kyprevention.getbynder.com/m/7804e24912a271c7/original/Supporting-Mental-Health-and-Preventing-Suicide-Mini-Resource-Kit-2023.pdf">Supporting Mental Health and Preventing Suicide Mini Resource Kit</a><br />
                Description: This mini resource kit is flexible in its usage and provides information that can be used to meet the needs of communities to support mental wellness of its residents. It offers guidance in promoting mental wellness among all individuals, supporting connectedness, and developing healthy coping mechanisms and help-seeking behaviors.
            </>,
            <><a href="https://kyprevention.getbynder.com/m/2db6ba7ac2737761/original/2023-Kentucky-Suicide-Prevention-Awareness-Resource-Guide.pdf">2023 Kentucky Suicide Prevention Awareness Resource Guide</a><br />
                Description: The resource guide includes: the history of suicide prevention month, dates within the month that bring awareness, ways to take action, safe messaging, National and State mental wellness and suicide prevention resources and a list of Kentucky events that are happening in September 2023.
            </>,

            <><a href="https://rb.gy/6paxf">Toolkit for Flooding Disaster Anniversary</a><br />
                Description: Toolkit with common reactions and ideas for stress management related to the anniversary of disasters.
            </>,


            <><a href="https://kyprevention.getbynder.com/transfer/bc40509fd5c030fd15da799d3bf3312760fb130685cdc6210ba05de41bead5f8">Toolkit for Tornado Disaster Anniversary</a><br />
                Description: Toolkit with common reactions and ideas for stress management related to the anniversary of disasters.
            </>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Helpful Links & Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <><a href="https://drive.google.com/file/d/14leYXneRepvGsYgTnVmwjtXZ6yvD324g/view?usp=drive_link">Supporting Mental Health for Providers</a><br />
                This resource guide is designed for non-behavioral health providers working with individuals who have behavioral health needs. It provides tips for discussing mental health with patients and handouts to provide in the office setting.
            </>,

            <><a href="https://drive.google.com/file/d/1oK77knznac3B4uEpmLgG2sVUQSFdzFbK/view?usp=drive_link">National Depression and Mental Health Screening Month for Consumers</a><br />
            This resource guide includes resources to learn more about the signs of common mental health issues, how to access screens, and tools to monitor and improve your mental health.
            </>,

            <><a href="https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health">National Institute of Mental Health - Caring for your Mental Health</a><br />
                Description: Learn the basics of mental health self-care.
            </>,
            ,
            <><a href="https://www.nih.gov/health-information/your-healthiest-self-wellness-toolkits">National Institute of Mental Health - Your Healthiest Self</a><br />
                Description: Find tips for improving emotional, social, physical, and environmental wellness.
            </>,
            <><a href="https://mhanational.org/workplace/toolkit">Mental Health America - Workplace Wellness Toolkit </a><br />
                Descriptions: Toolkit to help support employee mental health and well-being.
            </>,
            <><a href="https://www.nimh.nih.gov/health/publications/so-stressed-out-fact-sheet">National Institute of Mental Health - I’m So Stressed Out </a><br />
                Description: Understand the difference between stress and anxiety and how to manage each.
            </>,
            <><a href="https://mhanational.org/youth-tech">Mental Health America - Navigating Virtual Spaces for Youth </a><br />
                Description: Resources to help young people navigate the virtual world.
            </>,
            <><a href="https://screening.mhanational.org/screening-tools/?ref=MHAKY">Mental Health America of Kentucky - Mental Health Screening </a><br />
                Description: Everyone experiences times of anxiety, stress, and sadness. Knowing when it’s time to seek professional mental health assistance is important.
            </>,


        ]
    },
]


export default mHPromotion_Content;