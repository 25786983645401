import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const strength_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                <b>Sources of Strength </b>is an Upstream Prevention Program.  Upstream prevention is an approach to care that examines and addresses root causes rather than symptoms.  Using an upstream prevention program can improve long-term outcomes and decrease healthcare costs.
            </>,
            "The SOURCES middle and high school program is more of a model than a program.  There is no curricula.  Rather, a diverse group of peer leaders come together and use their voice to promote culture change through planned message campaigns, activities, and by personal example.  Sources employs a strength-based approach to suicide prevention by focusing on developing and promoting strengths, aka protective factors, and tapping into the students’ power of social networking through creative and regular message campaigns. Those campaigns utilize visual arts, storytelling, music, competitions, social media, drama, and other forms of expression to encourage healthy norms. The campaigns increase all students’ awareness of their strengths and how to use them as a resource throughout their lifetime.",
            "In the same way that a cold spreads through a classroom, attitudes, behaviors, and beliefs spread through a social network. SOURCES utilizes the power of peer social networks to spread messages of hope, help, and strength throughout communities. A diverse group of Peer Leaders, who ideally represent every social group within the school, develop and push out positive message campaigns school-wide to promote strength-building, help-seeking, and connectedness to school and community.",
            "SOURCES Elementary utilizes a universal curriculum-based approach and keeps an upstream focus to increase health and wellness and decrease negative downstream outcomes by using active learning, talking circles, guided reflection and celebrating growth.   The program not only incorporates solid Social Emotional Learning content but includes a robust focus on mental health and proactive prevention for elementary schools, grades K-6.",
            "The Division provides both training and materials for schools who are interested in Sources of Strength at no charge; however, there are requirements to participation. Participating schools will be expected to sign a Letter of Agreement and complete a safety policy check list. In addition, schools will work with the Regional Prevention Centers (RPC) to review their current policies related to behavioral health, substance use/misuse and suicide prevention and participate in the Sources of Strength evaluation process conducted by our evaluation partner.",
            <>Learn More: <a href='www.sourcesofstrength.org'>www.sourcesofstrength.org</a></>,
            <>Questions or information: <a href='mailto: sheila.barnard@ky.gov'>sheila.barnard@ky.gov</a></>
        ]
    },
    
];

export default strength_Content;