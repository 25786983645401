import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DataReportList from "../../../components/DataReportList";


const dataReports_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>Click on "Display by Region" or "Display by Fiscal Year" below to choose a method for grouping the report's results, then select a report from one of the dropdown lists. The header above the list of reports indicates by which method the reports will be grouped.</>,
            <><b>Note: </b>
            These reports open in a separate tab, so you may have to disable your popup blocker or allow popups from this site.The reports will take a minute to load. </>,
            <><DataReportList/></>
        ]
    },
    






];

export default dataReports_Content;