import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";


const qMOTSurveys_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Consumer Perception of Care Survey Outcomes"
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: <><br/>Survey Results</>
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: <><Links linkTypeID="251" /></>
    },
];

export default qMOTSurveys_Content;