import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import DocumentsDropdown from "../../../components/DocumentsDropdown";
import Links from "../../../components/Links";


const qMOTDocs_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Meetings Agendas"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Select an agenda date from the dropdown list below to view the agenda.


                <DocumentsDropdown typeId="8" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Meetings Summaries"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                Select a meeting date from the dropdown list below to view the summary.

                <DocumentsDropdown typeId="1" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Presentations"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            <>
                Select a presentation title from the list below to view the presentation.
                <br />
                <br />
                <Links linkTypeID="44" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Other Documents"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
                <>
                    Select a document from the list below to view the document.
                    <br />
                    <br />
                    <Links linkTypeID="45" />

                </>
            ]

    }
 ];


export default qMOTDocs_Content; 