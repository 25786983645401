import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import SharedRisk from '../../../assets/img/sud/Bhpp_Shared_Risk.png';



const bHPP_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                The Prevention and Promotion Branch of the Division of Substance Use Disorder (DSUD) provides training and technical assistance on evidence-based substance use/misuse prevention to the 14 Regional Prevention Centers (RPCs) across the state as well as other state and community partners. The Branch focuses on the following:
                <br />
                <ul>
                    <li><b><Link to={"/sud/sa-pes"}>Prevention Enhancement Specialists</Link> </b>
                        provide training and technical assistance on alcohol, cannabis, nicotine, and stimulant use/misuse prevention including addressing the shared risk and protective factors for using those substances, suicide, and mental health
                    </li>
                    <li><b><Link to={"/sud/yeg"}>Youth Empowerment</Link> </b>to enable youth to be leaders in substance use/misuse prevention in their communities</li>
                    <li><b><Link to={"/sud/wdye"}>Workforce Development </Link></b>which includes training for current prevention workforce members as well as a paid internship program for undergraduate and graduate students

                    </li>
                    <li><b>Evidence-based programming</b> for schools such as <Link to='/sud/strength'><b>Sources of Strength</b></Link> (Sources) and <b><Link to={"/sud/tgd"}>Too Good for Drugs</Link></b> as well as toolkits, like the <b><a href={"https://dbhdidtest.ky.gov/documents/dbh/bhpp/6EBProgrammingGuideforSchools.docx"}>Evidence-Based Programming Guide for Schools </a></b></li>
                    <li>Statewide projects such as <b><Link to={"/sud/dtp"}>The Dinner Table Project</Link></b> to promote family bonding and resilience and <b><Link to={"/sud/pip"}>Partners in Prevention</Link></b> to provide information on ways different segments of the community can help prevent substance use/misuse</li>
                    <li><b><Link to={"/sud/kpsap"}>The Purple Star Program </Link></b>to recognize schools who provide extra support for military-connected youth</li>
                    <li>The <b><Link to={"/sud/synar"}>Synar</Link></b> program to help prevent sales of nicotine products to those under 21</li>
                    <li>The <b><Link to={"/sud/zero"}>Zero Tolerance</Link></b> program for youth who are charged with an under 21 alcohol-related driving  </li>
                    <li>Efforts to reduce opioid misuse and promote harm reduction measures</li>
                    <li>Emerging issues in substance use and related consequences</li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Why Prevention"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>DBHDID supports prevention strategies that focus on increasing awareness of the issues related to substance use/misuse and mental health. This work is guided by the six Center for Substance Abuse and Prevention strategies: educating community members on ways to avoid risky behaviors, changing environmental contexts that encourage substance use or increase mental health issues, creating alternatives to engaging in substance use, building capacity of community partners to engage in prevention, and identifying those at greatest risk and connecting them with trained care and resources. Interventions are selected based on the individual needs of the community as identified in a multi-pronged needs assessment built from local data surveillance efforts.</>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "What works"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                Evidence-Based Prevention <br />
                <br />

                When selecting prevention programs and strategies its best to select program that research has proven to be effective at changing behaviors and improving public health. These programs and strategies are often referred to and known as “evidence-based practices". Evidence-based practices include “programs, policies, or other strategies that have been evaluated and demonstrated to be effective in preventing health problems based upon the best-available research evidence".</>,
            <>
                <b>Types of prevention programs that have been shown to boost protective factors and eliminate or reduce risk factors for drug use/misuse include:</b>
                <br />
                <br />
                <ul>
                    <li>Universal: address risk and protective factors common to all individuals in a given setting, such as a school or community.</li>
                    <li>Selected: are for groups of individuals who have specific factors that put them at increased risk of drug use.</li>
                    <li>Indicated: Designed for individuals who have already started using drugs or who have already experienced consequences to their substance use. </li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "What won’t work"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <>
                While research has identified important elements of effective drug prevention strategies, research has also identified strategies that have not been shown to reduce alcohol, tobacco, and other drug use. These strategies include:
                <br />
                <br />
                <ul>
                    <li>scare tactics and moralistic appeals;</li>
                    <li>curricula that rely solely on information about drugs and their dangers; </li>
                    <li>curricula that only work to promote self-esteem and emotional well-being, without providing training in resistance skills and normative education;</li>
                    <li>single shot assemblies and testimonials by former addicts may reinforce a negative norm that “everyone uses drugs” at some point in their lives. </li>
                </ul>
            </>,
            <>
                It is also important to avoid these types of strategies that have been shown to be ineffective in reducing substance use/misuse:
                <br />
                <br />

                <ul>
                    <li>Counseling students, particularly in peer-group context. This does not reduce substance use or delinquency. </li>
                    <li>Offering alternative activities such as recreation and community service activities without additional comprehensive strategies. </li>
                    <li>Programs focusing on information dissemination, fear arousal, and moral appeal as they are also not effective prevention strategies.</li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Intersection of Substance Use/Misuse and Mental Health"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            <>
                <img src={SharedRisk} style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block' alt="" />
            </>,
            <>
                Effective prevention focuses on reducing the factors that put people at risk of substance use/misuse and mental health disorders and strengthening those factors that protect people from the problems.
            </>,
            <>
                <ul>
                    <li>
                        Risk factors are certain biological, psychological, family, community, or cultural characteristics that precede and are associated with a higher likelihood of behavioral health problems.

                    </li>

                    <li>

                        Protective factors are characteristics at the individual, family or community level that are associated with lower likelihood of problem outcomes.

                    </li>
                </ul>
            </>,
            <>
                Some risk and protective factors are the same for different problems.  While there are unique risk and protective factors for each, there is also the intersection.

            </>,
            <>
                <b>
                    Existing research and data suggest that there are several common or shared risk and protective factors that impact both substance use/misuse and mental health outcomes.
                </b>
            </>,
            <>
                One coalition, agency or individual cannot adequately impact one or more problems alone, so it makes sense to look for opportunities to work with other sectors/disciplines to address shared risk factors to avoid duplicating efforts, share data, and grow the victories we achieve in promoting health and wellness.
            </>,
            <>
                The Division of Substance Use Disorder is committed to addressing substance use/misuse and mental health through shared risk and protective factors.
            </>,
            <>
                <i>
                    Citation <br />
                    <br />
                    Drug Strategies (1999). Making the grade: A guide to school drug prevention programs. Washington, D.C.: Levine and Associates, Inc.<br /><br />
                    U.S. Department of Education, (2000). Key School-Linked Strategies and Principles for Preventing Substance Abuse and Violence. <a style={{ overflowWrap: 'break-word' }} href='http://notes.edc.org/HHD/MSC/mscres.nsf/0/5044870fadcb56d6852568e800532418/$FILE/Topic3-Strategy.do'>http://notes.edc.org/HHD/MSC/mscres.nsf/0/5044870fadcb56d6852568e800532418/$FILE/Topic3-Strategy.do </a>  <br />
                    <br />
                    National Insititute of Health. (2020, July 10). Preventing drug misuse and addiction: The best strategy. National Institute on Drug Abuse. <a style={{ overflowWrap: 'break-word' }} href='https://nida.nih.gov/publications/drugs-brains-behavior-science-addiction/preventing-drug-misuse-addiction-best-strategy'>https://nida.nih.gov/publications/drugs-brains-behavior-science-addiction/preventing-drug-misuse-addiction-best-strategy </a>
                    Prevention Action Alliance. (2020, November 17). The best kind of prevention: Evidence-based prevention. Prevention Action Alliance - Leading healthy communities in the prevention of substance misuse and the promotion of mental health wellness.
                </i>
            </>
        ]
    }
];

export default bHPP_Content;