import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import SynarImage from '../../../assets/img/sud/Synar.png'


const synar_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                The Synar Program is a federally mandated program enacted into law in 1992 through the Synar amendment, named after its sponsor Congressman Mike Synar of Oklahoma.  The legislation was developed in light of a growing body of evidence about the health problems related to tobacco use by youth, as well as evidence about the ease with which youth could purchase tobacco products through retail sources. The Synar Program requires States, territories, and tribal entities to:
                <br/>
                <br/>

                <ul>
                    <li>Enforce underage tobacco and nicotine access laws to a degree that reasonably can be expected to reduce the illegal sale of tobacco products to individuals under the age of 21.</li>
                    <li>Conduct annual, unannounced inspections that provide a valid sample of tobacco sales outlets that are accessible to minors.</li>
                    <li>Report their sampling methodology and results of the annual Synar survey as a part of the <a href='https://dbhdidtest.ky.gov/documents/dbh/synar/11aKYSynarReport2023.pdf'>Annual Synar Report</a> no later than December 31st.</li>
                    <li>Achieve a noncompliance rate of no more than 20%. </li>
                </ul>
            </>,
            "Synar inspections are conducted by the Department of Alcoholic Beverage Control (ABC). ABC trained youth under the age of 21 accompanied by ABC enforcement officers enter the store and attempt to buy tobacco and nicotine products. Stores that sell to minors are cited and fined.",
            "States that do not maintain violation rates below the 20% threshold could lose ten percent of their total Substance Use Prevention, Treatment, and Recovery Block Grant.",
            "The first Synar inspections were conducted in 1997.  Since that time, Kentucky’s violation rates have averaged 8.2%.",
            <>
                <img style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block' src={SynarImage} alt=""></img>
            </>,
            <>
                In addition to monitoring compliance of tobacco and nicotine retailers, the Kentucky Synar Program provides free online training and educational material to tobacco retailers and vape shops. The Tobacco Retail Underage Sales Training (TRUST) follows the FDA guidelines for effective merchant education.  The training can be accessed here:

                <a href='http://training.chfs.ky.gov/061220_BHDID_TRUST_MOD_01/html/index.html'>http://training.chfs.ky.gov/061220_BHDID_TRUST_MOD_01/html/index.html</a>
            </>,
            <>
                Tobacco 21 Toolkits which include signage and forms required by Kentucky state law, WE ID badges for employees, helpful information on state and federal tobacco laws and how to prevent sales of tobacco and nicotine products are provided to tobacco retailers free of charge. To order a tool kit or to obtain any additional information about the Kentucky Synar program, please contact: <a href='mailto:steve.cambron@ky.gov'>steve.cambron@ky.gov</a>
            </>
        ]
    },
];

export default synar_Content;