import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const dUIForms_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "DUI Training"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Please visit the Calendar of Events to see upcoming training dates (see Related Links).",
            <>
                <Links linkTypeID="469" />
            </>
        ]
    },
];

export default dUIForms_Content;