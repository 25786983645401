import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLNewProvider_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: [
            <>How to Become an SCL Provider

                <br />
                <br />

                
            </>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 11,
        content: "Enrollment Information"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Quality SCL Providers are needed in all areas of community-based supports. Supported Employment, Community Access, Shared Living and Technology Assisted residential providers are currently in the greatest demand throughout Kentucky. We invite you to actively explore the application process.",
            "SCL New Provider Development Specialists offer an orientation at least every quarter. The required orientation is designed to answer your questions about becoming a certified SCL Provider and will provide you with step-by-step directions for assembling an enrollment packet.",
            "For more information about topics covered in orientation, please click the link below.",
            <><Links linkTypeID="196" /></>,
            "After initial contact has been made the next step is to complete the prerequisites for New Provider Orientation and then register for orientation. During the interim, you will find it very helpful to visit the Related Links to the right and should become familiar with the SCL regulations."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Certification Information"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "The Division of Developmental and Intellectual Disabilities (DDID) certifies provider agencies participating in the Supports for Community Living Waiver. Provider certification is based on regulations established by the Department for Medicaid Services.",
            "Providers may be certified in all or some of the following support and service areas. Click on a service to view its description.",
            <><Links linkTypeID="240" /></>,
            "If DDID processes the enrollment packet and certifies a company, the Department for Medicaid Services will issue a provider number. After a provider number has been issued, a provider can initiate services."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Enrollment Packet"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            <>
                <p>An Enrollment Packet can be submitted after attending New Provider Orientation Level I. For a full list of what should be included in the Enrollment Packet, please reach out to <a href='mailto:DDID.ProviderEnrollment@ky.gov'>DDID.ProviderEnrollment@ky.gov</a>. Items that are required as part of the Enrollment Packet include: </p>
            </>,
            <>
                <ul>
                    <li>The credentials of the Executive Director </li>
                    <li>Background checks: Criminal Record from the Administrative Office of the Courts, Child Abuse/Neglect Central Registry, Caregiver Misconduct Check, and Kentucky's Nurse Aid Registry for the Executive Director. </li>
                    <li>A business plan, including a projected operating budget for the initial six months post-certification. </li>
                    <li>A financial statement showing that the agency has the capacity to cover six months of expenses based on its budget. </li>
                    <li>A copy of the agency's policy and procedure manual. </li>
                    <li>A table of organization, articles of incorporation and bylaws, or articles of organization and operating agreement. </li>
                    <li>A list of the members of the board of directors, if applicable. </li>
                    <li>Verification of nonprofit status, if applicable, and a Tax ID number. </li>
                    <li>Documentation of good standing, if a Medicaid provider in another state. </li>
                    <li>Negative results of a 5 panel drug screening test for the Executive Director </li>
                </ul>
            </>
        ]
    }
];

export default sCLNewProvider_Content;