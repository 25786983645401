import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

import Links from "../../../components/Links";


const BHPACBGContent = [



    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Annual Block Grant Plans and Reports"
    },
    {
        format: bodyHelperTypes.paragraph, 
        order: 20,
        content: <><Links linkTypeID="94" /></>
    }

  

];

export default BHPACBGContent;