//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
import useApi from "../utils/UseAPI";
import SanitizeHref from "../utils/SanitizeHref";



function DropdownList({ linkTypeID }) {


    const [links, setLinks] = useState([]);
    const { error, processing, getRequest } = useApi();

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/" + linkTypeID);
            if (status == 200) {
                // console.log(data.data)
                setLinks(data.data.sort((a, b) => a.sort - b.sort));
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);

    return (
        <>
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    window.location = e.target.value;
                }}>
                    <option>Select a meeting date</option>
                    {
                        links.map((data, index) => {
                            {/* console.log(data) */ }
                            return (
                                <option key={index} value={SanitizeHref(data.url)} className="form-select"
                                >{data.text}</option>
                            )
                        })
                    }
                </select>
            </div>
        </>
    );
}

export default DropdownList;