import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";

const traumaAndTerrorismContent= [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Coping: Trauma and Terrorism Web Resources"
    },
    
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: "The websites below contain information that may be useful to help you and others to cope with terrorism and bioterrorism. Periodic Web updates and changes make it difficult to provide direct links to these issues; however, these sites provide search locations which can guide you to documents and articles regarding trauma and bioterrorism."
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Kentucky Sites"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content:<><Links linkTypeID={"34"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "National Sites"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content:<><Links linkTypeID={"35"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "International Sites"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content:<><Links linkTypeID={"36"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Bioterrorism"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 100,
        content:<><Links linkTypeID={"37"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 110,
        content: "Children and Teens"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 120,
        content:<><Links linkTypeID={"38"} /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Non-Governmental Link Policy"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 140,
        content: [
            "No endorsement is intended or made of any hypertext link, product, service or information, either by its inclusion or exclusion from this page or site. While all attempts are made to insure the correctness and suitability of information under our control and to correct any errors brought to our attention, no representation or guarantee can be made as to the correctness or suitability of that information or any linked information presented, referenced, or implied.",
            "All critical information should be independently verified. The inclusion of links from this site does not imply endorsement by the Commonwealth of Kentucky. The Commonwealth makes no endorsement, express or implied, of any links to or from its site, nor is it responsible for the content or activities of any linked sites. Any questions should be directed to the administrator(s) of this or any other specific sites."
        ]
    }
    



];

export default traumaAndTerrorismContent;