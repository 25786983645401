import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const dUIFAQ_Content= [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Frequently Asked Questions"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
              This page contains links to types of frequently asked questions regarding Kentucky DUI program policy, for the purpose of informing DUI providers and the public.  
            </>,
            <>
               Click on a link below to access that type's questions and answers. 
            </>,
            <>
              For answers to Out-of-State Questions, contact the Department of Vehicle Regulation customer service center at (502) 564‑1257.  
            </>,
            <>
                <Links linkTypeID="221" />
            </>
        ]
    },
];

export default dUIFAQ_Content;