import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";




const coocurring_Content = [
    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "According to the Substance Abuse and Mental Health Services Administration (SAMHSA), the coexistence of both a mental health and a substance use disorder is referred to as co-occurring disorders. Co-occurring disorders exist when an individual has at least one mental disorder accompanied by an substance use disorder.",
            "People with co-occurring disorders are best served through integrated treatment. With integrated treatment, practitioners can address mental health, and substance use disorders at the same time, often lowering costs and creating better outcomes.",
            "Increasing awareness and building capacity in service systems are important in helping identify and treat co-occurring disorders. Early detection and treatment can improve treatment outcomes and the quality of life for those who need these services (SAMHSA).."
        ]
    },
    
   
    
    






];

export default coocurring_Content;