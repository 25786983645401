import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLFormsGeneral_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL General Forms"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Click on the name of a document in the list below to access a copy of the document.
                <br />
                <br />
                <Links linkTypeID="236" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "To Apply for Medicaid and/or Waiver Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                Applications can be completed in the Medicaid Waiver Management Application by anyone with the application initiator role.
                <br />
                <br />
                <Links linkTypeID="634" />
            </>,
            <>
                Applications can also be completed using the link below through the account of the person or family member for whom SCL services are being requested.
                <br />
                <br />
                <Links linkTypeID="635" />
            </>
        ]
    }
];

export default sCLFormsGeneral_Content;

