import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const sP_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Hotlines and Other Resources"
    },
   
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [<><li>988 English/Spanish- text 988, chat online at <a href="https://988.ky.gov/">https://988.ky.gov/</a> or dial 988 then press 2 for Spanish speakers</li>
        <li>Veteran’s – text 988, chat online at <a href="https://988.ky.gov/">https://988.ky.gov/</a> or dial 988 then press 1 for Veterans</li>
        <li>Crisis Text Line- text KY to 741-741</li>
        <li>Trans Lifeline- (877) 565-8860</li>
        <li>Trevor Project- (212) 695-8650 or chat online at <a href="https://www.thetrevorproject.org/get-help/">https://www.thetrevorproject.org/get-help/ </a></li>
        </>]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Prevention Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [<><a href="https://bit.ly/44bNJOu">https://bit.ly/44bNJOu</a>: At this link you can find various resources surrounding mental health promotion and suicide prevention, including but not limited to: Regional Prevention Resources, Social Media Toolkits, Disaster Anniversary Resources, Resources for Addressing and Preventing Suicide in Schools, and more. The resources at this link are continually updated and more are added when available.</>]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Suicide Information and Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [<div className="no-bullet"><li><a className="quick-links-body" href="https://theactionalliance.org/our-strategy/national-strategy-suicide-prevention">National Strategy for Suicide Prevention</a></li>
        <li><a className="quick-links-body" href="https://www.hhs.gov/sites/default/files/sprc-call-to-action.pdf">Surgeon General’s Call to Action</a></li>
        </div>]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "State and Local Suicide Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [<div className="no-bullet" ><li><a className="quick-links-body" href="https://sprc.org/">Suicide Prevention Resource Center State Suicide Prevention</a></li>
        </div>]
    },
    {
        format: bodyHelperTypes.title,
        order: 90,
        content: "Survivors of Suicide Support Groups"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [<div className="no-bullet"><li><a className="quick-links-body" href="https://sprc.org/state/kentucky/">Suicide Prevention Resource Center State Suicide Prevention</a></li>
        </div>]
    },
   
]


    export default sP_Content;