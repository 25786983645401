import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const CBHTContent = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"



    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Behavioral health is everyone's business.",
            "The Kentucky Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) is excited to offer a selection of training opportunities aimed at educating communities about how to assist anyone who may be experiencing a behavioral health crisis."



        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Description"



    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [

            "DBHDID, through legislative action (908 KAR 2:270), has established the Community Behavioral Health Training Program. When more individuals in the community gain knowledge of mental health and substance use disorders and acquire the skills to offer immediate assistance, this increases the likelihood that persons who are experiencing behavioral health challenges will be identified and connected to appropriate services. Access 908 KAR 2:270 through its link in the Related Links box.",
            "In 2019, Kentucky saw overdose deaths increase by 5 percent from the previous year, from 1,247 to 1,316. (Kentucky Office of Drug Control Policy, 2020). During the same year, 756 individuals died by suicide in the Commonwealth (CDC, 2021).",
            "Many more Kentuckians are dealing with mental health or substance use challenges daily and are at risk of overdose and suicide. DBHDID continually seeks to increase access to appropriate behavioral health services, and it takes the help of all citizens in the community to aid in identifying individuals and families who may be struggling with these issues."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Training Opportunities"



    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [

            'In an effort to increase the knowledge and skills of community members to be able to assist others, DBHDID offers a variety of evidence-based behavioral health training opportunities, including "Question, Persuade, and Refer (QPR)," "Mental Health First Aid (MHFA)," and "Applied Suicide Intervention Skills Training (ASIST)."',
            'If you would like to be added to the email list to receive information about training that is offered, please access the "Email List" link in the Related Links box and provide the necessary contact information.',
            "For additional information on these training opportunities, please visit these websites:",
            <><Links linkTypeID="757" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [

            "If you or someone you know is experiencing challenges related to mental health and/or substance use, please reach out using the following resources:",
            <><Links linkTypeID="758" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "DBHDID Commitment"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            "DBHDID is committed to increasing everyone's awareness of how to assist those in need of services and supports to overcome behavioral health challenges. Thank you for being part of the solution!"        
        ]
    },

];

export default CBHTContent;