import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const act_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The ACT model is guided by multiple essential practices, including:",
            "Multidisciplinary team: Providers with various professional training and backgrounds work together to blend their knowledge and skills. A typical ACT team consists of a team leader, psychiatrist/ psychiatric nurse practitioner, registered nurses, a co-occurring disorders specialist, an employment may include additional specialist positions to provide support in areas such as forensics, housing, specialist, a peer specialist, clinicians and case managers, and a program assistant. Some team’s psychiatric rehabilitation, and family support or specific roles to assure culturally responsive and equitable care such as interpreter(s) or formal inclusion of natural community supports, (e.g., faith organizations, resettlement agency advocates).",
            "Small caseload: An ACT team that is practicing with fidelity has a staff-to-client ratio of no more than 1 to 10. A team gives each client individualized care.",
            "Shared caseload: Providers do not have individual caseloads; rather, the team as a whole is responsible for ensuring clients receive needed services.",
            "Fixed point of responsibility: The ACT team is responsible for helping a client develop their person-centered plan and collectively provides the services each client needs and wants to support achieving their expressed goals. If using another provider is optimal (e.g., to provide medical care), the ACT team ensures clients receive those services. The team also ensures effective communication about ongoing care needs and goals.",
            "Flexible service delivery: The ACT team meets daily to discuss how each client is doing. Team members quickly adjust their services to respond to changes in client needs.",
            "Community-based and community-focused services: ACT is not an office-based service. ACT teams provide assertive outreach and deliver services in the places where clients spend their time (e.g., homes, shelters, encampments). Services focus on full community integration. A hallmark of ACT is skills training within the community, such as helping people work, shop, bank, use the library, use recreation centers, and engage in other community-based activities. ACT includes community-based and community-focused services that reflect the needs of diverse communities, specifically culturally appropriate spaces.",
            "Time-unlimited services: The ACT team provides services for as long as needed.",
            "24/7 crisis availability: Services are available 24 hours a day, 7 days a week."
        ]
    },
];

export default act_Content;