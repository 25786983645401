import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const ProviderProfile = ({ profile }) => {
    if (!profile) {
        return <div>Loading profile...</div>;
    }

    const renderProfileDetails = () => {
        if (profile.profileType === 'SCL') {
            return (
                <>
                    <tr>
                        <td>Date of Certification:&nbsp;</td>
                        <td>{profile.certificationStartDate ? new Date(profile.certificationStartDate).toLocaleDateString() : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Certification Length:</td>
                        <td>{profile.certificationLength ? `${profile.certificationLength} ${profile.certificationLengthDesc || ''}` : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Accepting Referrals:</td>
                        <td>{profile.acceptingReferrals === '1' ? 'Yes' : 'No'}</td>
                    </tr>
                </>
            );
        } else if (profile.profileType === 'DUI') {
            return (
                <>
                    {profile.duiProfileDetails.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.label}:</td>
                            <td>&nbsp;{detail.providerValue}</td>
                        </tr>
                    ))}
                </>
            );
        } else if (profile.profileType === 'SA') {
            const treatmentOptions = profile.profileList
                .filter(detail => ['46', '77', '80', '83'].includes(detail.pcode))
                .map(detail => detail.label);
            const paymentOptions = profile.profileList
                .filter(detail => detail.pcode === 'payment')
                .map(detail => detail.label);

            return (
                <>
                    <tr>
                        <td colSpan="2">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Treatment Options:</th>
                                        <th>Payment Options:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ul>
                                                {treatmentOptions.map((option, index) => (
                                                    <li key={index}>{option}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                {paymentOptions.map((option, index) => (
                                                    <li key={index}>{option}</li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"><strong>Notes:</strong> {profile.notes || 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </>
            );
        } else {
            return (
                <tr>
                    <td>No details available for this provider type.</td>
                </tr>
            );
        }
    };

    return (
        <div>
            <h2>{profile.providerDesc}</h2>
            <table>
                <tbody>
                    {renderProfileDetails()}
                </tbody>
            </table>
            <br />
            {profile.profileType === 'SCL' && (
                <>
                    <h3>Mission And Values</h3>
                    <p>{profile.missionAndValues}</p>
                    <h3>Program Description</h3>
                    <p>{profile.programDescription}</p>
                    <p>
                        <a href="https://dbhdid.ky.gov/documents/GettingtoKnowtheAgency.pdf" target="_blank" rel="noopener noreferrer">
                            Click here for a list of questions to ask the agency.
                        </a>
                    </p>
                    <h3>Definitions</h3>
                    <ul>
                        <li>
                            <strong>Certification:</strong> This agency has met state regulatory requirements to provide the listed SCL
                            services for up to two years. On-site certification reviews are conducted at least once every two years by the
                            Division of Developmental and Intellectual Disabilities staff.
                        </li>
                        <li>
                            <strong>Moratorium:</strong> This agency is not allowed to accept new individuals into its program until certain
                            health, safety, and welfare requirements have been met.
                        </li>
                    </ul>
                </>
            )}
        </div>
    );
};

export default ProviderProfile;
