import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const aOTContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [

            <>Assisted Outpatient Treatment (AOT) provides qualifying individuals with Severe Mental Illness (SMI) the full range of behavioral health outpatient services under the direct supervision of a court with mental health jurisdiction.  The AOT petition and services process is outlined at [XXXX link].</>,
            <>
                In 2017, the General Assembly passed Kentucky's first Assisted Outpatient Treatment (AOT) statute. Tim's Law, named for Tim Morton, a Lexington man with schizophrenia who was unable to acknowledge his own diagnosis and illness. During his lifetime, he was committed to inpatient treatment dozens of times, resisted attempts at outpatient treatment, and ultimately died in 2014 at age 56 from neglected health problems. Thanks to the efforts of his parents, advocacy groups, mental health agencies, and many others, Kentucky took a significant step toward stopping "the revolving door of jails, hospitals and homelessness with little benefit for such individuals." (Courier-Journal, 6/24/2016)
            </>,
            <>
                In July 2020, KY Department of Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) received a federal SAMHSA grant to fund a pilot project for 192 Assisted Outpatient Treatment (AOT) cases in Kentucky. The project coordinates the work of Community Mental Health Centers and state psychiatric hospitals in filing Tim's Law petitions and providing community-based treatment to individuals under court order. The project is currently operating in the regions served by Central State Hospital (CSH) and Western State Hospital (WSH).
            </>,
            <>
                The Pilot Project was expanded to the Eastern State Hospital (ESH) and Appalachian Regional Hospital (ARH) areas in FY2023 and FY2024 with state general funds, appropriated to DBHDID by the Kentucky General Assembly. DBHDID was awarded state general funds to continue AOT expansion efforts statewide in the amount of $2.5 million for FY2025 and FY2026.
            </>,
            <>
                With the addition of state general funds, the AOT program now has the resources to serve individuals court ordered to AOT from any petitioner source, including concerned family and community members, local behavioral health providers, physicians, law enforcement, and psychiatric hospitals.
            </>,
            <>
                University of Kentucky's College of Social Work continues to work with DBHDID on evaluation efforts, as part of a larger national review of AOT effectiveness.
            </>,
            
            


        ],
    }











];

export default aOTContent;