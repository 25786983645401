import React from 'react';
//import EmailreCAPTCHA from '../components/EmailreCAPTCHA.js';

function ProviderContact() {
    const sectionStyle = {
        //border: '1px solid #2f4050',
        padding: '15px',
        margin: '5px 0'
    };
    return (
        <div style={sectionStyle}>
            <div>
                <hr />
                <h5>Provider Update Contact <br />(For Provider Use Only)</h5>
                For assistance in updating your Provider information or to be added to this website, please contact us at:
                <br />
                <a href="mailto:Provider.Service@ky.gov?subject=Provider Update Request">Provider.Service@ky.gov</a>
                {/* <EmailreCAPTCHA /> */}
            </div>
        </div>
    );
}

export default ProviderContact;


