import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const sCLFormsEmployment_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "SCL Supported Employment Forms"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            " Supported Employment is intensive, ongoing support for an SCL or SCL2 recipient to maintain  paid employment in an environment in which an individual without a disability is employed. This service is available in both the SCL and SCL2 waiver programs.",
            "Click on the name of a document in the list below to access a copy of the document."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Supported Employment Forms"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <><Links linkTypeID="317" /></>
        ]
    },
];

export default sCLFormsEmployment_Content;