import React from 'react';
import { Link } from "react-router-dom";
import "../assets/styles/pages/style.css";
import Navbar from '../layouts/Navbar';
import Breadcrumbs from '../components/buttons/Breadcrumbs';
import Footer from '../layouts/Footer';

function Error() {
    return (
        <>
        <Navbar />
        {/* <Breadcrumbs /> */}
            <div className='container'>
                <div className="error">
                    <div class="d-flex  justify-content-center ">
                        <div class="text-center row">
                            <div class=" col-md-6">
                                <img src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" alt=""
                                    class="img-fluid"></img>
                            </div>
                            <div class=" col-md-12 mt-5">
                                <p class="fs-3"> <span class="text-danger">Oops!</span> Page not found.</p>
                                <p class="lead">
                                    The page you’re looking for doesn’t exist.
                                </p>
                                <Link className="btn btn-primary" to={"/"}>
                                    <div>Home Page</div>
                                </Link>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <Footer />

        </>
    );
}

export default Error;