import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";
import CSBServiceDropdown from "../../../components/CSBServiceDropdown";

const cSB_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Community Supports Branch provides support, monitoring, and oversight of a variety of functions within the Division of Developmental and Intellectual Disabilities (DDID). It provides staff support, technical assistance, and monitoring of 14 Regional Community Mental Health/Intellectual Disability Centers (CMHCs).",
            "DDID Regional Liaisons work with CMHCs to assist persons with disabilities and their families in locating resources and supports. CMHCs provide services such as case management, community access, supported employment and respite. Services vary by region."

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Services"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 40,
        content: [
            <>
                Click on a service to view its description.
                <br />
                <br />
                {/* <Links  linkTypeID="411" /> */}
                <CSBServiceDropdown />
            </>
        ]
    }
];

export default cSB_Content;