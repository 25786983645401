import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const dataInfo_Content = [

    
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><Links linkTypeID={112} /></>
        ]
    },
];

export default dataInfo_Content;

