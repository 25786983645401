import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const dTP_Content = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "The Dinner Table Project (DTP) is a resilience-building initiative focused on encouraging families to eat together, have fun, and grow closer through conversation.",
            "Research shows that children and youth who connect with their parents and other adults in activities are less likely to use substances and experience mental health issues.",
            "Each month, individuals, families, and organizations receive the DTP monthly newsletter and many of them subsequently share with 10,000+ parents and educators across the state through schools, extension offices, libraries, and churches. Thousands of people in Kentucky view DTP social media posts every month with many of those viewers sharing the information through their own social media channels.",
            <>
                The newsletters include:
                <ul>
                    <li>Recipes</li>
                    <li>Activities </li>
                    <li>Conversation starters</li>
                    <li>Resources and tips for parents and caregivers on promoting healthy mental health and reducing substance use.</li>
                </ul>
            </>,
            <>
                The Dinner Table Project was developed by Four Rivers Behavioral Health in 2015. In the summer of 2019, it was adopted by the Kentucky Regional Prevention Centers as a statewide project. Each Regional Prevention Center (RPC) has the tools to help you implement The Dinner Table Project in your home, school, or community. <a href='/sud/sa-rpc'>Find your RPC here.</a>
            </>,
            "The target population of the Dinner Table Project is elementary-age youth and their families. Table Talks is an extension of The Dinner Table Project targeted toward families with middle and/or high school students.",
            "Through the years, The Dinner Table Project has changed but the core has stayed the same. We encourage families to eat together at least once a week with no electronics!",
            <>
                Website (Subscribe Here!): <a href='https://thedinnertableproject.org/'>https://thedinnertableproject.org/</a> <br />
                Facebook: <a href='https://www.facebook.com/thedinnertableproject/'>https://www.facebook.com/thedinnertableproject/</a><br />
                Instagram: <a href='https://www.instagram.com/kydinnertableproject/'>https://www.instagram.com/kydinnertableproject/</a> <br />
            </>
        ]
    },
];

export default dTP_Content;