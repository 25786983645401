// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";


function Picture( {item} ) {

    return (
        
            <img src={item} style={{ maxWidth: "100%", overflow: "hidden", }} className='rounded mx-auto d-block'  alt="" />
        
    );
};
export default Picture;