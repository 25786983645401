import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const eBPIIntegrated_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "What are co-occurring disorders?"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Many of our clients suffer from a mental health disorder along with an alcohol or drug use disorder. The combination of health disorders is referred to as a dual diagnosis, dual disorders, or co-occurring disorders.",
            "These conditions occur together frequently and are prevalent among persons seeking treatment in medical, mental health, or addictions treatment. To achieve the best possible outcome, both disorders need to be treated simultaneously. Focusing on one does not ensure that the other will go away.",
            "Dual-diagnosis services integrate assistance for both conditions, helping people to get treatment for both in one setting. When a multiple or integrated team approach is used, chances of recovery improve for those suffering from concurrent disorders.",
            <>
                <ul>
                    <li>
                        Roughly 50 percent of individuals with severe mental disorders are affected by substance abuse.
                    </li>,
                    <li>
                        Thirty-seven percent of alcohol abusers and 53 percent of drug abusers also have at least one serious mental illness.
                    </li>
                    <li>
                        Of all people diagnosed with a mental illness, 29 percent abuse either alcohol or drugs.
                    </li>
                    <li>
                        Fifty to 75 percent of people in addiction treatment centers also suffer from a current psychiatric disorder.
                    </li>

                </ul>
            </>,
            <>
                The Division of Mental Health has launched a statewide initiative designed to achieve the goal of becoming a universally co-occurring-capable system of care. Programs within the public community mental health center system will be assessed using the DDCAT and DDCMHT fidelity tools to determine their dual diagnosis capabilities.
            </>,
            <>
                Dual diagnosis-capable programs are those that "address co-occurring mental and substance-related disorders in their policies and procedures, assessment, treatment planning, program content and discharge planning."
            </>
        ]

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "What are DDCAT and DDCMHT?"

    },


    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "DDCAT stands for Dual Diagnosis Capability in Addiction Treatment. DDCMHT stands for Dual Diagnosis Capability in Mental Health Treatment, in reference to the treatment of co-occurring mental health and substance use disorders.",
            <>
                DDCAT is a nationally recognized fidelity instrument for measuring the essential program elements for co-occurring-capable and co-occurring-enhanced systems. In essence, DDCAT consists of program items that can be ranked along a 1-to-5 scale, where 1 represents an "addiction or mental health only" level of intervention, 3 represents co-occurring capability, and 5 represents a co-occurring-enhanced level.
            </>,
            <>
                DDCAT was developed by Mark McGovern, PhD, a faculty member at Dartmouth Medical School. He has worked in conjunction with other well-recognized experts in co-occurring disorders, including Kim Meuser, PhD and Robert Drake, MD.
            </>,
            <>
                Use the Contact Information to obtain further information about the pilot project.
            </>
        ]


    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Links"


    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [

            <><Links linkTypeID={170} /></>,
            <>
                <i>
                    ASAM Patient Placement Criteria for the Treatment of Substance-Related Disorders, 2d ed. rev., American Society of Addictive Medicine, Chevy Chase, MD, 2001, p. 362.
                </i>
            </>
        ]




    },










];

export default eBPIIntegrated_Content;