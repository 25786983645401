import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import ContinuumOfCare from "../../../assets/img/sud/ContinuumOfCare.png"
import { Link } from "react-router-dom";
import Links from "../../../components/Links";

const BestPracticeContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
        <><b>Evidence-based Practices</b> are prevention, treatment and recovery interventions used to support individuals, families and communities impacted by mental health and substance use disorders, shown by evidence to be effective. Such interventions are formally evaluated to be effective and able to be replicated and implemented with appropriate modifications in other settings.</>,
        <><b>Promising Practices</b> are not yet formally evaluated but identified by experts as a program with results suggesting efficacy and worthy of further study in broader pilot implementation efforts.</>,
        <>In April 2018, SAMHSA launched the <a href="https://www.samhsa.gov/resource-search/ebp">Evidence-Based Practices Resource Center (Resource Center)</a> that aims to provide communities, clinicians, policy makers, and others in the field with the information and tools they need to incorporate evidence-based practices into their communities or clinical settings.  The Resource Center contains a collection of science-based resources and is part of SAMHSA's comprehensive approach to identify and disseminate clinically sound and scientifically based policy, practices, and programs.</>
    ]




    },

    
];

export default  BestPracticeContent;