import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";

const eCMHLinks_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Kentucky Links"

    },
    {
        format: bodyHelperTypes.links,
        order: 20,
        content: "5"

    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "National Links"

    },
    {
        format: bodyHelperTypes.links,
        order: 40,
        content: "6"

    },
    



            

           
    


];

export default eCMHLinks_Content;