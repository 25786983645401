import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";
import Links from "../../../components/Links";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";



const dHHS_Content = [
    
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Welcome"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Do you need information on mental health services for individuals who are deaf or hard of hearing? Substance use treatment? Services for a person with a developmental or intellectual disability? We can help!",
            "Three community mental health center (CMHC) regions in Kentucky have specialized Deaf Mental Health Services. Access the links below for more information.",
            <Links linkTypeID={759} />,
            "Below is a list of the CMHCs. To access more specific information, click on the CMHC's name to access its individual website",
            <Links linkTypeID={765} />,
            "Psychiatric care is available through qualified interpreters. Access the 'Psychiatric Care' link in the Related Links box for a video explaining psychiatric care.",
            "Each CMHC also has DHHS contacts. Their job is to help make sure interpreters are provided and services are accessible. Use the 'CMHC Contacts' link the Related Links box for a list of contacts.",
            

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "DHHS Information for Individuals with Developmental and Other Intellectual Disabilities"
    },
    {
        format:bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "Below are links to information for individuals with developmental and other intellectual disabilities. Click on a link for more information.",
            <><Links linkTypeID={764}/></>

        ]
    }
   
    
    






];

export default dHHS_Content;