import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

const sMVF_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The overall goal of the Service Members, Veterans and Their Families Behavioral Health Initiative (SMVF) is to strengthen the statewide behavioral health care systems and services for SMVF through ongoing collaboration at the federal, state and local levels."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "SMVF Priorities"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
                <ol>
                    <li>Increase access to appropriate and effective behavioral health services for the SMVF population.</li>
                    <li>Maximize quality of services and efficiently allocate resources.</li>
                    <li>Sustain a stable and healthy environment focused on awareness, employment and justice.</li>
                    <li>Capture data to support decision-making.</li>
                </ol>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Operation Immersion"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: "Representatives from federal, military, state and professional organizations across Kentucky unite to host the annual Operation: Headed Home (OHH) Conference, a statewide conference that addresses the needs of SMVF, caregivers, and behavioral and medical health professionals with information, resources, and support regarding Traumatic Brain Injury (TBI), Post-Traumatic Stress Disorder (PTSD) and Suicide Prevention."
    }
];

export default sMVF_Content;