import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import { Link } from "react-router-dom";

const mHHome_Content = [

    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "The Division of Mental Health (DMH) supports implementation of prevention, treatment, crisis, and recovery services for Kentuckians impacted by Serious Mental Illness (SMI), Severe Emotional Disabilities (SED) and/or co-occurring mental health and substance use disorders.  DMH also supports behavioral health preparedness, response, and recovery in the time of disasters, either natural or man-made.",
            "Partnerships with community-based organizations, service providers, universities, and state agencies provide access to quality and evidence-based prevention, treatment, recovery, and harm reduction services. Through the provision of federal grants and state funding, these services are publicly available to persons across the Commonwealth.",
            <>
                In addition, DMH aims to expand access to <b><a href='/sud/bestpractice'>evidence-based services</a></b> that are accessible, available, and acceptable for all Kentuckians. <br />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "DMH Branches and Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <>
                <ul className="no-bullet">
                    <li><a className='quick-links-body' href='/mh/adultmh'>Adult Mental Health and Recovery Services Branch</a></li>
                    <li><Link className='quick-links-body' to={"/mh/children"}>Children’s Mental Health and Recovery Services Branch</Link></li>
                    <li><Link className='quick-links-body' to={"/mh/ppp"}>Promotion, Prevention and Preparedness Branch</Link></li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Find Help"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                <ul className="no-bullet">
                    <li><a className='quick-links-body' href='https://988.ky.gov/'>988 Suicide and Crisis Line </a></li>
                    <li><a className='quick-links-body' href='https://findhelpnow.org/ky'>Link to Mental Health Now Resource</a></li>
                    <li><a className='quick-links-body' href='https://kccrt.ky.gov/Pages/index.aspx '>Kentucky Community Crisis Response Team (KCCRT) </a></li>
                    <li><a className='quick-links-body' href='https://findhelpnow.org/ky'>Project Recovery</a></li>
                    <li><a className='quick-links-body' href='https://findhelpnowky.org/ky'>Find Help Now </a></li>
                    <li><a className='quick-links-body' href='https://www.findrecoveryhousingnowky.org/'>FindRecoveryHousing</a></li>
                    <li><a className='quick-links-body' href='/ProviderDirectory'>Treatment Locator Tool</a></li>
                    <li><a className='quick-links-body' href='https://findhelpnow.org/ky'>Naloxone Website (in development)</a></li>
                </ul>
            </>
        ]
    }
];

export default mHHome_Content;