import React from 'react';

function BranchInfo(props) {
    return (
        <div>
        <br/>
                <b>County:</b> {props.county} <br/>
                <b>Region:</b> {props.regionNumber} <br/>
                <br/>
                <b>CMHC Corporate Office:</b> {props.regionName} <br/>
                <b>Corporate Office Phone:</b> {props.phone} <br/>
                <b>24-Hour Crisis Phone:</b> {props.countyPhone} <br/>
                <b>Adult State Psychiatric Hospital:</b> {props.hospital} <br/>
                <b>Hospital Phone:</b> {props.hospitalPhone} <br/> <br/>
           
        </div>
    );
}

export default BranchInfo;